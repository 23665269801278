import * as React from 'react';
import TimekeeperDialogStore from 'store/timekeeper.dialog.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    List,
    ListItem, TextField
} from '@material-ui/core';
import { TimeKeeperAssignment } from 'api/types/types';
import { Search } from '@material-ui/icons';
import AppStore from 'store/app.store';

interface Props {
    store?: TimekeeperDialogStore;
    appStore?: AppStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        store: rootStore.timekeeperDialogStore,
        appStore: rootStore.appStore
    };
})
@observer
export default class TimeKeeperDialog extends React.Component<Props> {
    cancel = () => {
        this.props.store!.cancel();
    };
    
    setEntry = (tkId: number) => () => {
        this.props.store!.selectEntry(tkId);
    };

    handleTkSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.props.appStore!.setTkSearchText(evt.target.value);
    };

    render() {
        const store = this.props.store!;
        const {
            isOpen
        } = store!;
        const {
            tkSearchText,
            filteredTimeKeepers
        } = this.props.appStore!;
        return (
            <Dialog
                open={isOpen}
                onClose={this.cancel}
                maxWidth="md"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle>
                    Select Timekeeper
                </DialogTitle>
                <DialogContent>
                    <List
                        id="timekeeper-selection-list"
                    >
                        <TextField
                            id={'search-with-icon-textfield'}
                            placeholder={'Search with Timekeeper/Office'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position={'start'}>
                                        <Search/>
                                    </InputAdornment>
                                )
                            }}
                            value={tkSearchText}
                            fullWidth={true}
                            onChange={this.handleTkSearch}
                            style={{
                                padding: 5,
                                width: 250
                            }}
                        />
                        {filteredTimeKeepers.map((entry: TimeKeeperAssignment, id) => 
                            <ListItem button={true} key={id} onClick={this.setEntry(entry.timeKeeperId)}>
                                {`${entry.name}(${entry.office})`}
                            </ListItem>
                        )}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.cancel}>Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }
}