import * as React from 'react';
import * as Styled from './styled';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';

const NUM_DAYS_IN_WEEK: number = 7;
const DAYS_IN_WEEK: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export enum Mode {
    MONTH = 'MONTH', 
    WEEK = 'WEEK'
}

export interface Props {
    style?: React.CSSProperties;
    
    /** Current date of the calender */
    currentDate: DateTime;
    /** View calendar as Month or Week */
    mode?: Mode;
    /** Sets the start day of the week. 1 = Monday, 7 = Sunday */
    /** MON TUES WED THURS FRI SAT SUN */
    startOfWeek?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    /**
     *  A component that renders each day cell
     *  @param date luxon DateTime for which the cell will be rendered
     *  @return component to be rendered
     */
    // tslint:disable-next-line:no-any
    cellComponent?: (date: DateTime) => JSX.Element;
    alignDays?: 'default' | 'center';
}

/**
 *  Gets the beginning of month or weekday
 *  @param date luxon DateTime to find the start of
 *  @param type specify the start of either week or month
 *  @param startOfWeek the day that is start of the week
 *  @return luxon DateTime that is start of week or month
 */
export const getStartOf = (date: DateTime, type: 'week' | 'month', startOfWeek?: number) => {
    if (!startOfWeek) {
        startOfWeek = 7;
    }
    let ret = DateTime.utc(date.year, date.month, date.day);
    if (type === 'month') {
        ret = ret.startOf('month');
    }
    let diff = startOfWeek! - ret.weekday;
    const offset = diff > 0 ? diff - 7 : diff;
    ret = ret.plus({ days: offset })
    return ret;
}

export const getEndOfWeek = (date: DateTime, startOfWeek: number) => {
    if (date.weekday === 7 && startOfWeek === 7) {
        // Making Saturday as End of Week when taking in Sunday as the start
        return date.endOf('day').plus({ days: 6})
    } else {
        return date.endOf('week')
    }
}

/**
 *  Get a shifted array of days in the week
 *  @return string[] of days in week
 */
export const getDayNames = (startOfWeek: number) => {
    let ret: string[] = [];
    let idx = startOfWeek! - 1;
    for (let i = 0; i < NUM_DAYS_IN_WEEK; i++) {
        ret[i] = DAYS_IN_WEEK[idx];
        if (++idx === 7) {
            idx = 0;
        }
    }
    return ret;
}
@observer
export default class Calendar extends React.Component<Props> {
    public static defaultProps: Props = {
        style: {},
        currentDate: DateTime.local(),
        mode: Mode.MONTH,
        startOfWeek: 7,
        cellComponent: (date: DateTime) => <Styled.DayBox>{date.day}</Styled.DayBox>,
    }
    
    /**
     *  @return days of week component that is to be rendered above the calendar
     */
    weekHeader = () => {
        const { startOfWeek } = this.props;
        return (
            <Styled.WeekHeaderContainer>
                {
                    getDayNames(startOfWeek!).map((day, idx) => {
                        return (
                            <Styled.Day 
                                key={idx}
                                align={this.props.alignDays}
                            >
                                {day}
                            </Styled.Day>
                        )
                    })
                }
            </Styled.WeekHeaderContainer>
        );
    }
    
    /**
     *  @return JSX.Element[] of cells to be rendered
     */
    buildCalender = () => {
        const { currentDate, cellComponent, startOfWeek, mode } = this.props;
        let ret: JSX.Element[] = [];
        let key = 0;
        if (mode === Mode.MONTH) {
            let curDate = getStartOf(currentDate, 'month', startOfWeek!);
            while (curDate <= currentDate.endOf('month').startOf('day')) {
                for (let i = 0; i < NUM_DAYS_IN_WEEK; i++) {
                    ret.push(
                        <Styled.DayBoxWrapper key={key}>
                            {cellComponent!(curDate)}
                        </Styled.DayBoxWrapper>
                    );
                    curDate = curDate.plus({ days: 1 })
                    key++;
                }
            }
        } else if (mode === Mode.WEEK) {
            let curDate = getStartOf(currentDate, 'week', startOfWeek!);
            for (let i = 0; i < NUM_DAYS_IN_WEEK; i++) {
                ret.push(
                    <Styled.DayBoxWrapper key={key}>
                        {cellComponent!(curDate)}
                    </Styled.DayBoxWrapper>
                );
                curDate = curDate.plus({ days: 1 })
                key++;
            }
        }
        return ret;
    }
    
    render() {
        const { mode } = this.props;
        
        return (
            <Styled.Container style={this.props.style}>
                {this.weekHeader()}
                <Styled.Calendar>
                    {this.buildCalender()}
                </Styled.Calendar>
            </Styled.Container>
        );
    }
}