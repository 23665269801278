import * as React from 'react';
import MatterContainer from './MatterContainer';
import * as Styled from './styled';
import MattersStore from 'store/matters.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features } from '../../api/types/types';

interface Props {
    mattersStore: MattersStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        mattersStore: rootStore.mattersStore
    };
})

@observer
export default class Matters extends React.Component<Props> {
    componentWillMount() {
        // this.props.mattersStore!.loadAvailableMatters('',  false, 0, 50);
        this.props.mattersStore!.setAvailableSearchText('');
        this.props.mattersStore!.setTrackedSearchText('');
        this.props.mattersStore!.loadTrackedMatters();
        this.props.mattersStore!.loadAvailableMatters('', false);
    }
    untrack = (ids: number[]) => {
        const matterLabel = this.props.mattersStore.rootStore.appStore.features.EpochConfigMatterLabel;
        if (
            confirm(`Are you sure you want to Untrack? Any draft time entries ` +
            `or templates for this ${matterLabel} will be discarded.`)
        ) {
            this.props.mattersStore!.untrack(ids);
        } else {
            return;
        }

    }
    searchTrackedMatters = (val: string) => {
        this.props.mattersStore!.setTrackedSearchText(val);
    }

    searchAvailableMatters = (val: string) => {
        this.props.mattersStore!.setAvailableSearchText(val);
        this.props.mattersStore!.loadAvailableMatters(val, false);
    }

    render() {
        const {
            availableClientMattersTuple,
            trackedClientMattersTuple,
            fetchMoreAvailMatters,
            fetchMoreTrackedMatters,
            trackedMatters,
            availableMatters,
            selectedMatters,
            setSelectedMatters,
            track,
            loadingAvailable,
            loadingTracked,
            searchTrackedText,
            searchAvailableText
        } = this.props.mattersStore!;
        return (
            <FeaturesConsumer>
            { (features: Features) =>
            <Styled.MainWrapper container={true}>
                <Styled.WrapperItem xs={12} sm={6}>
                    <MatterContainer
                        title={`Tracked ${features.EpochConfigMatterLabel}s`}
                        matters={trackedClientMattersTuple}
                        fetchMore={fetchMoreTrackedMatters}
                        isTrackedList={true}
                        selected={selectedMatters}
                        onSelect={setSelectedMatters}
                        untrack={this.untrack}
                        loading={loadingTracked}
                        search={searchTrackedText}
                        onInputChange={this.searchTrackedMatters}
                    />
                </Styled.WrapperItem>
                <Styled.WrapperItem xs={12} sm={6}>
                    <MatterContainer
                        title={`Available ${features.EpochConfigMatterLabel}s`}
                        matters={availableClientMattersTuple}
                        fetchMore={fetchMoreAvailMatters}
                        isTrackedList={false}
                        selected={selectedMatters}
                        onSelect={setSelectedMatters}
                        track={track}
                        untrack={this.untrack}
                        loading={loadingAvailable}
                        search={searchAvailableText}
                        onInputChange={this.searchAvailableMatters}
                    />
                </Styled.WrapperItem>
            </Styled.MainWrapper>
             }
             </FeaturesConsumer>
         );
    }

}