import * as React from 'react';
import NarrativeCodesDialogStore from 'store/narrativeCodes.dialog.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    Tooltip
} from '@material-ui/core';
import * as Styled from './styled';
import { Language } from '@material-ui/icons';

interface Props {
    narrativeCodesDialogStore?: NarrativeCodesDialogStore
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        narrativeCodesDialogStore: rootStore.narrativeCodesDialogStore
    };
})
@observer
export default class NarrativeCodesDialog extends React.Component<Props> {
    isFetching = false;
    cancel = () => {
        this.props.narrativeCodesDialogStore!.cancel();
    };

    fetchMoreitems = async ({ scrollHeight, scrollTop, clientHeight }:
                { scrollHeight: number, scrollTop: number, clientHeight: number }) => {
        let bottom = scrollHeight - scrollTop - clientHeight;
        if (bottom < 400 && !this.isFetching) {
            this.isFetching = true;
            await this.props.narrativeCodesDialogStore!.fetchMoreAvailNarratives();
            this.isFetching = false;
        }
    }

    render() {
        const store = this.props.narrativeCodesDialogStore!;
        const {
            isOpen,
            narratives
        } = store!;
        
        const self = this;
        
        return (
            <Dialog
                open={isOpen}
                onClose={this.cancel}
                maxWidth="md"
            >
                <DialogTitle>
                    <div>Narrative Codes</div>
                </DialogTitle>
                <DialogContent onScroll={e => this.fetchMoreitems(e.currentTarget)}>
                    <Styled.NarrativeCodeHeader style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                        <div />
                        <div>Narrative Code</div>
                        <div>Narrative Text</div>
                    </Styled.NarrativeCodeHeader>
                    <List>
                        {narratives.map((narrative, index) =>
                            <Styled.NarrativeCodeRows key={narrative.key}>
                                <div>{narrative.global && (
                                    <Tooltip title={'Global'}><Language /></Tooltip>
                                )}</div>
                                <div>{narrative.key}</div>
                                <div>{narrative.replacement}</div>
                                <div>
                                    <Button
                                        onClick={(_e) => {
                                            this.props.narrativeCodesDialogStore!.selectNarrative(narrative);
                                        }}
                                    >
                                        Insert
                                    </Button>
                                </div>
                            </Styled.NarrativeCodeRows>
                        )}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.cancel}>Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }
}