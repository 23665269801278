import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import ConfirmCollaborateStore from 'store/confirmCollaborate.store';
import * as Styled from 'components/NavBar/styled.desktop';
import TimeKeepersList from 'components/TimeKeepersList/TimeKeepersList';
import { observer } from 'mobx-react';

interface Props {
    store?: ConfirmCollaborateStore;
    rootStore?: RootStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        store: rootStore.confirmCollaborateDialogStore,
        rootStore: rootStore
    };
})
@observer
export default class ConfirmCollaborateDialog extends React.Component<Props> {
    render() {
        let {
            isOpen,
            cancel,
            resolveAndClose
        } = this.props.store!;
        const { workDateNonCollaborated } = this.props.rootStore!.timeEntryStore;
        const collaboratees = this.props.rootStore!.collaboratees.length!;
        const { getAllTimeKeepers, filteredAllTimekeepersList } = this.props.rootStore!.appStore;
        return (
            <Dialog
                open={isOpen}
                onClose={cancel}
                disableRestoreFocus={true}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="collaborate-dialog-title">
                    Confirm 
                </DialogTitle>
                <DialogContent>
                    You have selected some Timekeepers to collaborate entries. Continue?
                </DialogContent>
                <DialogActions>
                    <TimeKeepersList
                        render={(tkMenuAnchor, openTkList) =>
                            <Styled.CollaborateBtn innerRef={tkMenuAnchor}>
                                <Button
                                    onClick={evt =>
                                        getAllTimeKeepers(workDateNonCollaborated).then(() => openTkList(evt))
                                    }
                                    aria-label="Collaborate"
                                >
                                    Change Collaborations ({collaboratees})
                                </Button>
                            </Styled.CollaborateBtn>}
                        collaborate={true}
                        menuWidth={300}
                        timeKeepers={filteredAllTimekeepersList}
                        workDate={workDateNonCollaborated}
                    />
                    <Button onClick={cancel} color="primary">
                        No
                    </Button>
                    <Button onClick={() => resolveAndClose()} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}