import ImmutableTimeEntry, { SapStatus } from '../api/immutables/ImmutableTimeEntry';
import { PdfFormatType, TimeEntryPdfCell, TimeEntryPdfHeader } from '../api/types/types';
import { parseCode } from './utils';
import { Styles } from 'jspdf-autotable';
import { DateTime } from 'luxon';
import rootStore from 'store/root.store';

let jsPDF = require('jspdf');
require('jspdf-autotable');

const constantsJSON = require('../constants.json');

export const COL_WIDTH = constantsJSON.pdfReport.colWidth;
export const TEXT_FONT_SIZE = constantsJSON.pdfReport.textFontSize;
export const REP_FONT_SIZE = constantsJSON.pdfReport.repFontSize;
export const HEADER_COLOR = constantsJSON.pdfReport.headerColor;

interface CustomCell {
    content: string | number;
    key: string
}

// tslint:disable-next-line:no-any
let pageContent = (pageData: any) => {
    // HEADER
    pageData.doc.setFontSize(REP_FONT_SIZE);
    pageData.doc.setTextColor(REP_FONT_SIZE * 4);
    pageData.doc.setFontStyle('normal');
    pageData.doc.text(
        pageData.settings.timeKeeper,
        pageData.table.settings.margin.left,
        17
    )
    
    // FOOTER
    let str = 'Page ' + pageData.pageCount;
    pageData.doc.setFontSize(REP_FONT_SIZE);
    pageData.doc.text(
        str, 
        pageData.table.settings.margin.left, 
        pageData.doc.internal.pageSize.height - 5
    );
};

// tslint:disable-next-line:no-any
let drawCustomCell = (dataR: any) => {
    if (dataR.row.raw.key === 'grpheader' || dataR.row.raw.key === 'narrative') {
        dataR.row.cells = { content: dataR.row.raw.content };
        let txt = dataR.doc.splitTextToSize(dataR.row.raw.content, dataR.table.width - 3);

        if (dataR.row.raw.key === 'grpheader') {
            dataR.doc.setFillColor(134, 128, 128);
            dataR.doc.setTextColor(255, 255, 255);
            dataR.doc.setFontSize(REP_FONT_SIZE);
            dataR.doc.rect(
                dataR.row.x,
                dataR.row.y,
                dataR.table.width,
                REP_FONT_SIZE * txt.length,
                'F'
            );
            dataR.doc.autoTableText(
                dataR.row.raw.content,
                dataR.table.settings.margin.left + dataR.table.width / 2,
                dataR.row.y + 2,
                { halign: 'center', valign: 'center' }
            );
        }

        if (dataR.row.raw.key === 'narrative') {
            dataR.doc.setFillColor(220, 220, 220);
            dataR.doc.rect(
                dataR.row.x,
                dataR.row.y,
                dataR.table.width,
                REP_FONT_SIZE * txt.length,
                'F'
            );
            dataR.doc.text(
                txt,
                dataR.table.settings.margin.left,
                dataR.row.y + 4
            ); 
        }
    }
}

// tslint:disable-next-line:no-any
let drawCellsForTotals = (dateCell: any) => {
    if (dateCell.row.raw.key === 'daytotal' || dateCell.row.raw.key === 'finaltotal' || dateCell.row.raw.key === 'mattertotal' || dateCell.row.raw.key === 'clienttotal') {
        if (dateCell.row.raw.key === 'daytotal') {
            if (dateCell.column.dataKey === 'activity') {
                dateCell.cell.text = 'Daily Total';
                dateCell.doc.setFontStyle('bold');
            }
        }
        if (dateCell.row.raw.key === 'mattertotal') {
            if (dateCell.column.dataKey === 'activity') {
                dateCell.cell.text = `${rootStore.appStore.features.EpochConfigMatterLabel} Total`;
                dateCell.doc.setFontStyle('bold');
            }
        }
        if (dateCell.row.raw.key === 'clienttotal') {
            if (dateCell.column.dataKey === 'activity') {
                dateCell.cell.text = 'Client Total';
                dateCell.doc.setFontStyle('bold');
            }
        }
        if (dateCell.row.raw.key === 'finaltotal') {
            if (dateCell.column.dataKey === 'activity') {
                dateCell.cell.text = 'Final Total';
                dateCell.doc.setFontStyle('bold');
            }
        }
        if (dateCell.column.dataKey === 'duration') {
            dateCell.cell.text = dateCell.row.raw.content;
            dateCell.doc.setFontStyle('bold');
        }
    }
}

export function buildPdf(timeEntries: Map<string, ImmutableTimeEntry[]>, 
                         ffConfigEnabled: boolean, actCodeEnabled: boolean,
                         formatType: PdfFormatType, timeKeeper: string) {
    const doc = new jsPDF('l');

    let allColumns = getColumnsForPDf(ffConfigEnabled, actCodeEnabled, formatType);

    doc.setFontSize(REP_FONT_SIZE * 3);
    doc.text('Epoch', 5, 10);
    doc.setFontSize(REP_FONT_SIZE);

    let pdf = exportDataForPdf(timeEntries, ffConfigEnabled, actCodeEnabled, formatType, doc);

    doc.autoTable(
        allColumns, 
        pdf,
        {
            pageBreak: 'auto',
            rowPageBreak: 'auto',
            theme: 'grid',
            didDrawPage: pageContent,
            margin: {right: REP_FONT_SIZE, left: REP_FONT_SIZE, bottom: REP_FONT_SIZE * 2, top: REP_FONT_SIZE * 2},
            didDrawCell: drawCustomCell,
            willDrawCell: drawCellsForTotals,
            headStyles: {
                fillColor: HEADER_COLOR,
                fontSize: TEXT_FONT_SIZE,
                textColor: 0,
                fontStyle: 'bold',
                halign: 'center',
                valign: 'middle',
                minCellHeight: TEXT_FONT_SIZE
            },
            bodyStyles: {
                fontSize: TEXT_FONT_SIZE
            },
            styles: {cellWidth: 'wrap', minCellHeight: REP_FONT_SIZE * 2, overflow: 'linebreak'},
            columnStyles: {
                workdate: columnStyles('auto'),
                client: columnStyles('auto'),
                matter: columnStyles('auto'),
                office: columnStyles(COL_WIDTH * 3),
                phase: columnStyles(COL_WIDTH * 3),
                ffTask: columnStyles(COL_WIDTH * 3),
                ffAct: columnStyles(COL_WIDTH * 3),
                activity: columnStyles(COL_WIDTH * 3),
                task: columnStyles(COL_WIDTH * 3),
                status: columnStyles(COL_WIDTH * 3),
                action: columnStyles(COL_WIDTH * 3),
                duration: columnStyles(COL_WIDTH * 3)
            },
            timeKeeper: timeKeeper
    }
    );
    return doc;
}

function toPdfFormat (timeEntry: ImmutableTimeEntry, ffCodes: boolean, 
                      actionCode: boolean, formatType: PdfFormatType): TimeEntryPdfCell {
    
    let dur: string = (timeEntry.duration / 3600).toFixed(2).toString();
    let status: string = '';
    switch (timeEntry.sapStatus) {
        case SapStatus.QUEUED:
            status = 'Posted';
            break;
        case SapStatus.SUBMITTED:
            status = 'Posted';
            break;
        case SapStatus.UNSUBMITTED:
            status = 'Draft';
            break;
        default:
            status = '';
    }
    
    let format: TimeEntryPdfCell = {
        office: parseCode(timeEntry.office, timeEntry.officeName),
        phase: parseCode(timeEntry.phaseName, timeEntry.phaseDesc),
        task: parseCode(timeEntry.taskCode, timeEntry.taskCodeDesc),
        activity: parseCode(timeEntry.actCode, timeEntry.actCodeDesc),
        duration: dur,
        status: status
    }
    if (formatType === PdfFormatType.MATTERTYPE) {
        format.workdate = DateTime.fromISO(timeEntry.workDateTime).toFormat('DDDD');
        format.client = parseCode(timeEntry.clientNumber, timeEntry.clientName);
    }
    if (formatType === PdfFormatType.DATETYPE) {
        format.client = parseCode(timeEntry.clientNumber, timeEntry.clientName);
        format.matter = parseCode(timeEntry.matterNumber, timeEntry.matterName)
    }
    if (formatType === PdfFormatType.CLIENTTYPE) {
        format.workdate = DateTime.fromISO(timeEntry.workDateTime).toFormat('DDDD');
        format.matter = parseCode(timeEntry.matterNumber, timeEntry.matterName)
    }
    if (ffCodes) {
        format.ffTask = parseCode(timeEntry.ffTaskCode, timeEntry.ffTaskCodeDesc)
        format.ffAct = parseCode(timeEntry.ffActCode, timeEntry.ffActCodeDesc)
    }
    if (actionCode) {
        format.action = parseCode(timeEntry.actionCode, '')
    }
    
    return format; 
}

function exportDataForPdf(timeEntriesMap: Map<string, ImmutableTimeEntry[]>, 
                          ffCodeEnabled: boolean, actCodeEnabled: boolean, formatType: PdfFormatType,
// tslint:disable-next-line:no-any
                          doc: any) {
    let timeEntries = [...timeEntriesMap.entries()];
    let data: (TimeEntryPdfCell | CustomCell)[] = [];
    let finalTotals: number = 0;
    
    timeEntries.map(([headerStr, entries]) => {
        let headerCell: CustomCell = {
            content: headerStr,
            key: 'grpheader'
        }
        data.push(headerCell);
        entries.map((timeEntry) => {
            data.push(toPdfFormat(timeEntry, ffCodeEnabled, actCodeEnabled, formatType))
            if (timeEntry.narrative) {
                let txt = doc.splitTextToSize(timeEntry.narrative, 330);
                for (let i = 0; i < txt.length; i = i + 2) {
                    let txt1 = txt[i];
                    let txt2 = `${txt[i + 1] ? txt[i + 1] : ''}`;
                    const narrativeCell: CustomCell = {
                        content: `${txt1} ${txt2 ? '\n' + txt2 : ''}`,
                        key: 'narrative'
                    }
                    data.push(narrativeCell);
                } 
            }
        });
        let totalRowKey: string = '';
        
        if (formatType === PdfFormatType.DATETYPE) { 
            totalRowKey = 'daytotal';
        } else if (formatType === PdfFormatType.MATTERTYPE) { 
            totalRowKey = 'mattertotal';
        } else if (formatType === PdfFormatType.CLIENTTYPE) { 
            totalRowKey = 'clienttotal';
        }
        
        let dailyTotalCell: CustomCell = {
            content: getDailyTotals(entries).toFixed(2),
            key: totalRowKey
        };
        finalTotals = getGrandTotals(timeEntriesMap);
        data.push(dailyTotalCell)
    });
    let finalTotalCell: CustomCell = {
        content: finalTotals.toFixed(2),
        key: 'finaltotal'
    }
    data.push(finalTotalCell);
    return data;
}

function getColumnsForPDf(ffConfigEnabled: boolean, actCodeEnabled: boolean, formatType: PdfFormatType) {
    let header: TimeEntryPdfHeader[] = [];
    const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
    
    if (formatType === PdfFormatType.DATETYPE) {
        header.push(new TimeEntryPdfHeader('Client', 'client'));
        header.push(new TimeEntryPdfHeader(matterLabel, 'matter'));
    } else if (formatType === PdfFormatType.CLIENTTYPE) {
        header.push(new TimeEntryPdfHeader('Work Date', 'workdate'));
        header.push(new TimeEntryPdfHeader(matterLabel, 'matter'));
    } else if (formatType === PdfFormatType.MATTERTYPE) {
        header.push(new TimeEntryPdfHeader('Work Date', 'workdate'));
        header.push(new TimeEntryPdfHeader('Client', 'client'));
    }
    header.push(
        new TimeEntryPdfHeader('Office', 'office'),
        new TimeEntryPdfHeader('Phase', 'phase'),
        new TimeEntryPdfHeader('Task', 'task'),
        new TimeEntryPdfHeader('Activity', 'activity'),
        new TimeEntryPdfHeader('Hours', 'duration'),
        new TimeEntryPdfHeader('Status', 'status')
    )

    if (ffConfigEnabled) {
        header.push(
            new TimeEntryPdfHeader('FF Task', 'ffTask'),
            new TimeEntryPdfHeader('FF Activity', 'ffAct')
        )
    }
    if (actCodeEnabled) {
        header.push(
            new TimeEntryPdfHeader('Action Code', 'action')
        )
    }

    return header;
};

function columnStyles (width: 'auto' | 'wrap' | number): Styles {
    let customStyle: Styles = {
        cellWidth: width,
        overflow: 'linebreak',
        minCellHeight: TEXT_FONT_SIZE
    }
    return customStyle;
}

export function getDailyTotals(entries: ImmutableTimeEntry[]): number {

    let dailyTotal = entries.reduce((prev, cur) => {
        return prev + cur.duration;
    }, 0) / 3600;

    return dailyTotal;
}

export function getGrandTotals(timeEntriesMap: Map<string, ImmutableTimeEntry[]>): number {
    let grandTotal: number = 0;

    [...timeEntriesMap.entries()].map(([header, timeEntries]) => {
        grandTotal = grandTotal + getDailyTotals(timeEntries);
    });

    return grandTotal;
}