import * as React from 'react';
import * as Styled from './styled.desktop';
import { ButtonBase, Fab, IconButton, PropTypes, Tooltip } from '@material-ui/core';
import { Delete, InsertDriveFile, PlayArrow, Stop } from '@material-ui/icons';
import { FabProgress } from './Timers';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import { TKConsumer } from 'common/TKProvider';
import { TimeKeeperAssignment } from '../../api/types/types';
import { getTimerDurationTxt } from 'util/timer';

interface TimerLinkProps {
    timer: ImmutableTimer;
    onClick?: () => void;
    selected?: boolean;
    onStart: () => void;
    onStop: () => void;
    onDelete?: () => void;
    loadingId?: number;
    includeSubmitted: boolean;
    disableDelete?: boolean;
    actionColor?: PropTypes.Color;
    onAddNote?: () => void;
    isActiveToday?: boolean;
    // tslint:disable-next-line:no-any
    style?: any;
}
interface TimerLinkState {
    durTxt: string;
}

export default class TimerLink extends React.Component<TimerLinkProps, TimerLinkState> {
    interval: NodeJS.Timeout;

    constructor(props: TimerLinkProps) {
        super(props);
        this.state = { durTxt: '' };
        if (props.timer.active) {
            this.interval = setInterval(this.timerTick, 250);
        }
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    getSnapshotBeforeUpdate(prevProps: TimerLinkProps) {
        if (prevProps.timer.active === this.props.timer.active) {
            return null;
        }
        clearInterval(this.interval);
        if (this.props.timer.active) {
            this.interval = setInterval(this.timerTick, 250);
        }
        return null;
    }
    timerTick = () => {
        const { timer, includeSubmitted } = this.props;
        this.setState({ durTxt: getTimerDurationTxt(timer, includeSubmitted) });
    }
    render() {
        const { timer,
            onStart,
            onStop,
            loadingId,
            onAddNote,
            disableDelete = false,
            style,
            includeSubmitted
        } = this.props;
        const dtxt = timer.active ? this.state.durTxt : getTimerDurationTxt(timer, includeSubmitted);
        return (
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
            <ButtonBase style={{width: '100%', height: '100%'}}>
                <Styled.CustomListItemContent
                    selected={this.props.selected}
                    style={style ? {height: style.height} : {height: '70px'}}
                >
                    {!timer.active ?
                        <div>
                            <Tooltip title="Start">
                                <Styled.FabIcon
                                    disabled={!!loadingId || !tk.writable}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onStart!()
                                    }}
                                    size="medium"
                                    isLastModifiedToday={this.props.isActiveToday!}
                                    color={this.props.actionColor ? this.props.actionColor : 'primary'}
                                    aria-label="Start"
                                >
                                    <PlayArrow />
                                    {(loadingId === timer.id) && <FabProgress size={55} />}
                                </Styled.FabIcon>
                            </Tooltip>
                        </div> :
                        <div>
                            <Tooltip title="Pause">
                                <Fab
                                    disabled={!!loadingId || !tk.writable}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onStop!()
                                    }}
                                    size="medium"
                                    style={{backgroundColor: 'rgb(255, 87, 34)', color: 'white', minWidth: '48px'}}
                                    aria-label="Stop"
                                >
                                    <Stop />
                                    {(loadingId === timer.id) && <Styled.FabProgress size={55} />}
                                </Fab></Tooltip>
                        </div> }
                    <Styled.ListText
                        title={timer.name}
                        primary={timer.name}
                        secondary={dtxt}
                        primaryTypographyProps={{
                            style: {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'calc(100% - 40px)'
                            }
                        }}
                    />
                    {tk.writable &&
                        <>
                            {!disableDelete ? (
                                <Styled.CustomSecondaryAction>
                                    <Tooltip title="Delete">
                                        <IconButton onClick={this.props.onDelete}>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                </Styled.CustomSecondaryAction>
                            ) : (
                                    <Styled.CustomSecondaryAction>
                                        {timer.active && <Tooltip title="Add a narrative">
                                            <IconButton
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    onAddNote!();
                                                }}
                                            >
                                                <InsertDriveFile />
                                            </IconButton>
                                        </Tooltip>}
                                    </Styled.CustomSecondaryAction>
                                )}
                        </>
                    }
                </Styled.CustomListItemContent>
            </ButtonBase>
            }
            </TKConsumer>
        );
    }
}