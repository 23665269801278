import { Narrative } from 'api/types/types'
import DialogRootStore from './dialog.root.store';
import { observable, action, makeObservable } from 'mobx';
import { RootStore } from 'store/root.store';

export default class CreateNarrativeCodeDialogStore extends DialogRootStore<void, void> {
    @observable narrativeKey: string = '';
    @observable narrativeReplacement: string = '';
    @observable invalid: Record<string, string | boolean> = {};
    @observable loading = false;
    narratives: Narrative[] = [];

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
    }

    @action
    setNarrativeKey = (key: string) => {
        this.narrativeKey = key;
        this.resetInvalid();
    }
    
    @action
    setNarrativeReplacement = (rep: string) => {
        this.narrativeReplacement = rep;
        this.resetInvalid();
    }
    
    @action
    validate() {
        this.invalid.narrativeReplacement = this.narrativeReplacement.trim() === '' ? 'Required field' : false;
        this.invalid.narrativeKey = 
            this.narrativeKey === '' ? 'Required field' :
                this.narrativeKey.length < 2 ? 'Narrative Code cannot be less than 2 characters' :
            ( this.narrativeKey.indexOf(' ') > -1 ? 'No spaces allowed' : 
            this.loading ? 'Narratives not fully loaded. Try again' :
            ( this.narratives.length > 0 && this.isKeyDuplicate(this.narrativeKey) ? 'Duplicate code' : false));
        
        return !this.invalid.narrativeReplacement && !this.invalid.narrativeKey;
    }

    @action validateGlobalDuplicate(key: string): boolean {
        let obj = this.narratives.find(n =>
            n.key.toLowerCase() === key.toLowerCase());
        if (obj) {
            if (!obj.global) {
                return true;
            } else {
                this.invalid.narrativeKey = '\\' + obj.key + ' will override existing global narrative';
                return false;
            }
        } else {
            return false;
        }
    }

    async onOpen() {
        this.resetInvalid();
        this.fetchNarratives();
    }

    @action.bound
    resetInvalid() {
        this.invalid = { 
            narrativeReplacement: false,
            narrativeKey: false
        };
    }

    @action.bound
    fetchNarratives() {
        this.loading = true;
        this.rootStore.api.Narrative.getAllNarratives().then(
            narratives => { 
                this.narratives = narratives;
                this.loading = false;
            }
        );
    }

    isKeyDuplicate(key: string): boolean {
        return this.narratives.findIndex(n =>
            (n.key.toLowerCase() === key.toLowerCase()) && !n.global) > -1;
    }
}