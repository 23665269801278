import * as React from 'react';
import TemplateStore from 'store/template.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import TemplateForm from 'components/TemplateForm';
import * as Styled from './styled.desktop';
import { Cancel, Save } from '@material-ui/icons';
import { Button, CircularProgress } from '@material-ui/core';
import { Features, TimeKeeperAssignment } from '../../api/types/types';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { TKConsumer } from 'common/TKProvider';

interface Props {
    templateStore?: TemplateStore;
    match: {
        params: {
            id: string;
        }
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        templateStore: rootStore.templateStore,
    };
})
@observer
export default class TemplateFormContainer extends React.Component<Props> {
    componentWillMount() {
        this.props.templateStore!.resetValidation();
        if (this.props.match.params.id === 'new') {
            this.props.templateStore!.newTemplate();
        } else {
            this.props.templateStore!.loadTemplate(Number(this.props.match.params.id));
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.templateStore!.selectedTemplate
            && this.props.match.params.id !== prevProps.match.params.id
            && this.props.match.params.id === 'new') {
            this.props.templateStore!.loadTemplate(Number(this.props.match.params.id));
        }
    }

    render() {
        const {
            selectedTemplate,
            onTemplateChange,
            saving,
            wrappedSave,
            restoreTemplate,
            validation
        } = this.props.templateStore!;

        if (!selectedTemplate) {
            return (
                <div style={{textAlign: 'center'}}>
                    <CircularProgress/>
                </div>
            );
        }

        return (
            <FeaturesConsumer>
                { (features: Features) =>
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
            <>
                <TemplateForm 
                    template={selectedTemplate!} 
                    onChange={onTemplateChange}
                    validation={validation}
                    maxNarrativeLength={features.EpochConfigNarrativesMaximumChars}
                    actionCodesRequired={features.EpochConfigActionCodesRequired}
                />
                <Styled.ButtonsContainer>
                <Button
                    size="small"
                    onClick={() => wrappedSave(selectedTemplate)}
                    style={{marginRight: '10px'}}
                    disabled={!saving || !tk.writable}
                >
                    Save
                    <Save style={{marginLeft: '6px', alignItems: 'center'}} />
                </Button>
                <Button
                    size="small"
                    onClick={restoreTemplate}
                    disabled={!saving || !tk.writable}
                >
                    Cancel
                    <Cancel style={{marginLeft: '6px', alignItems: 'center'}} />
                </Button>
                </Styled.ButtonsContainer>
            </>}
            </TKConsumer>
                }
            </FeaturesConsumer>
        );
    }
}