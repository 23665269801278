import styled from 'common/styled-components';
import { Paper, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { FlexDiv } from 'common/flex';

export const Container = styled(Paper)<{posted: boolean; expanded: boolean; }>`
    border-left: 6px solid ${props => props.posted ? 
        props.theme.summary.green : 
        props.theme.summary.orange
    };
    transition: .2s;
    padding-right: 5px;
    ${props => props.expanded ? `
    margin-top: 10px;
    margin-bottom: 10px;
    ` : ``}
`;

export const Form = styled.div`
    padding: 0 4px 4px 4px;
`;

export const CommonGrid = styled<{gridAutoFlow?: string}, 'div'>('div')`
    display: grid;
    justify-content: space-between;
    align-items: stretch;
    padding: 2px 4px;
    cursor: pointer;
    ${props => props.gridAutoFlow ? `grid-auto-flow: ${props.gridAutoFlow};` : ''};
`;
export const Header = styled(CommonGrid)`
    grid-auto-flow: column;
    grid-template-columns: 23px 1fr;
    grid-column-gap: 3px;
`;
export const TEContent = styled(CommonGrid)`
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    padding: 2px 0px;
`
export const TEDetails = styled(CommonGrid)<{gridTemplateColoums: string}>`
    ${props => `grid-template-columns: ${props.gridTemplateColoums};`};
    grid-column-gap: 3px;
`
export const StyledNarrative = styled(CommonGrid)``;

export const DurationHeader = styled(Header)<{billable: boolean, matter: number|null}>`
    color: ${props => props.matter ? ( props.billable ?  props.theme.summary.blue : props.theme.summary.red) : ''};
`;

export const HeaderItem = styled(Typography)`
    align-self: center;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const CheckIcon = styled(CheckCircle)`
    color: ${props => props.theme.summary.green};
`;

export const Actions = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    align-items: center;
`;

export const DirtyIndicator = styled.div`
    width: 7px;
    margin-top: 5px;
    color: ${props => props.theme.primary.main};
`;

export const ListDate = styled(FlexDiv)`
    flex: 3;
    align-self: center;
    justify-content: left;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 6px;
    cursor: pointer;
    padding: 3px;
`;
