import * as React from 'react'
import * as Styled from './styled'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Info, LibraryBooks, SettingsApplications, SignalWifiOff, Timelapse } from '@material-ui/icons';
import CustomDictionaryContainer from '../CustomDictionary/CustomDictionaryContainer'
import AboutEpochContainer from '../AboutEpoch/AboutEPochContainer'
import { Redirect, Route } from 'react-router'
import rootStore from 'store/root.store'
import { TimeCastSettingsContainer } from '../TimeCastSettings/TimeCastSettingsContainer'
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features } from '../../api/types/types';
import OfflineEntries from 'components/OfflineEntries/OfflineEntries';
import { Platform } from '../../util/Platform';
import GeneralSettings from '../GeneralSettings/GeneralSettings';

interface Props {
    location: {
        pathname: string;
    };
    match: {
        url: string;
        path: string;
        params: {
            id: string;
        }
    };
}

enum Tab {
    GENERAL,
    CUSTOM_DICTIONARY,
    TIMECAST,
    ABOUT,
    OFFLINE,
    NONE
}

interface State {
    selectedTab: Tab
}

export default class Settings extends React.Component<Props, State> {
    state: State = {
        selectedTab: Tab.GENERAL
    }
    
    constructor(props: Props) {
        super(props);
        
        this.setState({
            selectedTab: props.location.pathname.startsWith('Offline') ? Tab.OFFLINE : Tab.NONE
        })
    }

    componentWillMount = () => {
        let tabToBeSelected;
        switch (this.props.location.pathname) {
            case '/settings/about':
                tabToBeSelected = Tab.ABOUT;
                break;
            case '/settings/timecast':
                tabToBeSelected = Tab.TIMECAST;
                break;
            case '/settings/customDictionary':
                tabToBeSelected = Tab.CUSTOM_DICTIONARY;
                break;
            case '/settings/offlineEntries':
                tabToBeSelected = Tab.OFFLINE;
                break;
            case '/settings/general':
                tabToBeSelected = Tab.GENERAL;
                break;
            default:
                tabToBeSelected = Tab.GENERAL;
        }

        this.setState({
            selectedTab: tabToBeSelected
        })
    }

    selectTab = async (tab: Tab) => {
        if ( await rootStore.canIChangeScope()) {
            if (this.state.selectedTab === tab) { return }
        
            this.setState({
                selectedTab: tab
            })

            switch (tab) {
                case Tab.ABOUT:
                    rootStore.routerStore.push('/settings/about');
                    break;
                case Tab.TIMECAST:
                    rootStore.routerStore.push('/settings/timecast');
                    break;
                case Tab.CUSTOM_DICTIONARY:
                    rootStore.routerStore.push('/settings/customDictionary');
                    break;
                case Tab.OFFLINE:
                    rootStore.routerStore.push(`/settings/offlineEntries`);
                    break;
                case Tab.GENERAL:
                    rootStore.routerStore.push('/settings/general');
                    break;
                default:
                    rootStore.routerStore.push('/settings/general');
                    break;
            }
        }
    }
    render() {
        const { match } = this.props
        const { offlineEntryCount } = rootStore.appStore!

        return (
            <Styled.MainWrapper>
                <Styled.Header>
                    <Styled.Title variant="h6">
                        Settings
                    </Styled.Title>
                </Styled.Header>
                <Styled.WrapperContent>
                    <Styled.ListContainer>
                        <List>
                            {/* FIXME: Selected list item should be dynamic when more settings pitch in*/}
                            <Styled.ListItemContainer>
                                <ListItem
                                    button={false}
                                    selected={this.state.selectedTab === Tab.GENERAL}
                                    onClick={() => this.selectTab(Tab.GENERAL)}
                                >
                                    <ListItemIcon>
                                        <SettingsApplications/>
                                    </ListItemIcon>
                                    <ListItemText primary="General"/>
                                </ListItem>
                            </Styled.ListItemContainer>
                            <Styled.ListItemContainer>
                                <ListItem
                                    button={false}
                                    selected={this.state.selectedTab === Tab.CUSTOM_DICTIONARY}
                                    onClick={() => this.selectTab(Tab.CUSTOM_DICTIONARY)}
                                >
                                    <ListItemIcon>
                                        <LibraryBooks/>
                                    </ListItemIcon>
                                    <ListItemText primary="Custom Dictionary"/>
                                </ListItem>
                            </Styled.ListItemContainer>
                            <FeaturesConsumer>
                                {(features: Features) => features.EpochConfigTimeCastEnabled &&
                                    <Styled.ListItemContainer>
                                        <ListItem
                                            button={false}
                                            selected={this.state.selectedTab === Tab.TIMECAST}
                                            onClick={() => this.selectTab(Tab.TIMECAST)}
                                        >
                                            <ListItemIcon>
                                                <Timelapse/>
                                            </ListItemIcon>
                                            <ListItemText primary="TimeCast™️"/>
                                        </ListItem>
                                    </Styled.ListItemContainer>
                                }
                            </FeaturesConsumer>
                            {Platform.isElectron() && offlineEntryCount > 0 &&
                            <Styled.ListItemContainer>
                                <ListItem
                                    button={false}
                                    selected={this.state.selectedTab === Tab.OFFLINE}
                                    onClick={() => this.selectTab(Tab.OFFLINE)}
                                >
                                    <ListItemIcon>
                                        <SignalWifiOff/>
                                    </ListItemIcon>
                                    <ListItemText primary="Offline Entries"/>
                                </ListItem>
                            </Styled.ListItemContainer>
                            }
                            <Styled.ListItemContainer>
                                <ListItem
                                    button={false}
                                    selected={this.state.selectedTab === Tab.ABOUT}
                                    onClick={() => this.selectTab(Tab.ABOUT)}
                                >
                                    <ListItemIcon>
                                        <Info/>
                                    </ListItemIcon>
                                    <ListItemText primary="About Epoch"/>
                                </ListItem>
                            </Styled.ListItemContainer>
                        </List>
                    </Styled.ListContainer>
                    <Styled.PageContainer>
                        <Route
                            exact={false}
                            path="/settings/userDictionary"
                            render={() => (
                                <Redirect to={`${match.url}/customDictionary`}/>
                            )}
                        />
                        <Route path={`${match.path}/customDictionary`} component={CustomDictionaryContainer}/>
                        <Route path={`${match.path}/offlineEntries`} component={OfflineEntries}/>
                        <Route path={`${match.path}/about`} component={AboutEpochContainer}/>
                        <Route path={`${match.path}/general`} component={GeneralSettings}/>
                        <FeaturesConsumer>
                            {(features: Features) => 
                                features.EpochConfigTimeCastEnabled &&
                                <Route path={`${match.path}/timecast`} component={TimeCastSettingsContainer} />
                            }
                        </FeaturesConsumer>
                        {/* <CustomDictionaryContainer/> */}
                    </Styled.PageContainer>
                </Styled.WrapperContent>
            </Styled.MainWrapper>
        )
    }
}