import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import ImmutableTimeEntry, { SapStatus } from '../../api/immutables/ImmutableTimeEntry';
import { parseCode } from '../../util/utils';
import { PdfFormatType } from '../../api/types/types';
import { DateTime } from 'luxon';
import { getDailyTotals, getGrandTotals } from '../../util/SaveAsTimeEntryPDF';
import rootStore from 'store/root.store';

interface Props {
    timeEntriesMap: Map<string, ImmutableTimeEntry[]>,
    ffConfigEnabled: boolean,
    actionEnabled: boolean,
    exportType: string,
    timeKeeper: string
}
export default class PrintAsTimeEntryPdfExport extends Component<Props> {
    
    render() {
        const { timeEntriesMap, actionEnabled, ffConfigEnabled, exportType } = this.props;
        let narrativeColSpan = 10;
        if (ffConfigEnabled && actionEnabled) {
            narrativeColSpan = 11;
        }
        if (actionEnabled && !ffConfigEnabled) {
            narrativeColSpan = 9;
        }
        const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;

        return (
            <form id={'printjs-form'}>
                <Typography variant={'h1'}>
                    Epoch
                </Typography>
                <Table id={'printjs-form'} className={'pdfTable'}>
                    <TableHead>
                        <TableCell colSpan={2} className={'timeKeeper'}>
                            {this.props.timeKeeper}
                        </TableCell>
                        <TableRow>
                            {exportType !== PdfFormatType.DATETYPE &&
                            <TableCell>
                                Work Date
                            </TableCell>
                            }
                            {exportType !== PdfFormatType.CLIENTTYPE &&
                            <TableCell>
                                Client
                            </TableCell>
                            }
                            {exportType !== PdfFormatType.MATTERTYPE &&
                            <TableCell>
                                {matterLabel}
                            </TableCell>
                            }
                            <TableCell>
                                Office
                            </TableCell>
                            <TableCell>
                                Phase
                            </TableCell>
                            <TableCell>
                                Task
                            </TableCell>
                            <TableCell>
                                Activity
                            </TableCell>
                            <TableCell>
                                Hours
                            </TableCell>
                            <TableCell>
                                Status
                            </TableCell>
                            {ffConfigEnabled &&
                            <TableCell>
                                FF Task
                            </TableCell>
                            }
                            {ffConfigEnabled &&
                            <TableCell>
                                FF Activity
                            </TableCell>
                            }
                            {actionEnabled &&
                            <TableCell>
                                Action Code
                            </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...timeEntriesMap.entries()].map(([grpHeader, entries]) => (
                            <>
                                <TableRow className={'tableRow'}>
                                    <TableCell colSpan={narrativeColSpan} className={'grpHeader'}>
                                        {grpHeader}
                                    </TableCell>
                                </TableRow>
                                {entries.map((te) => (
                                    <>
                                        <TableRow className={'tableRow'}>
                                            {exportType !== PdfFormatType.DATETYPE &&
                                            <TableCell>
                                                {DateTime.fromISO(te.workDateTime).toFormat('DDDD')}
                                            </TableCell>
                                            }
                                            {exportType !== PdfFormatType.CLIENTTYPE &&
                                            <TableCell>
                                                {parseCode(te.clientNumber, te.clientName)}
                                            </TableCell>
                                            }
                                            {exportType !== PdfFormatType.MATTERTYPE &&
                                            <TableCell>
                                                {parseCode(te.matterNumber, te.matterName)}
                                            </TableCell>
                                            }
                                            <TableCell>
                                                {parseCode(te.office, te.officeName)}
                                            </TableCell>
                                            <TableCell>
                                                {parseCode(te.phaseName, te.phaseDesc)}
                                            </TableCell>
                                            <TableCell>
                                                {parseCode(te.taskCode, te.taskCodeDesc)}
                                            </TableCell>
                                            <TableCell>
                                                {parseCode(te.actCode, te.actCodeDesc)}
                                            </TableCell>
                                            <TableCell>
                                                {(te.duration / 3600).toFixed(2).toString()}
                                            </TableCell>
                                            <TableCell>
                                                {this.status(te.sapStatus)}
                                            </TableCell>
                                            {ffConfigEnabled &&
                                            <TableCell>
                                                {parseCode(te.ffTaskCode, te.ffTaskCodeDesc)}
                                            </TableCell>}
                                            {ffConfigEnabled &&
                                            <TableCell>
                                                {parseCode(te.ffActCode, te.ffActCodeDesc)}
                                            </TableCell>}
                                            {actionEnabled && 
                                            <TableCell>
                                                {te.actionCode}
                                            </TableCell>}
                                        </TableRow>
                                        {te.narrative &&
                                        <TableRow className={'tableRow'} style={{textAlign: 'left'}}>
                                            <TableCell colSpan={narrativeColSpan}>
                                                {te.narrative}
                                            </TableCell>
                                        </TableRow>
                                        }
                                    </>
                                ))}
                                <TableRow className={'tableRow'}>
                                    <TableCell colSpan={4} />
                                    <TableCell colSpan={2} className={'totals'}>
                                        {exportType === PdfFormatType.DATETYPE ? 'Daily Total' :
                                            exportType === PdfFormatType.MATTERTYPE ? `${matterLabel} Total` :
                                                exportType === PdfFormatType.CLIENTTYPE && 'Client Total'
                                        }
                                    </TableCell>
                                    <TableCell colSpan={1} className={'totals'}>
                                        {getDailyTotals(entries).toFixed(2)}
                                    </TableCell>
                                    <TableCell colSpan={3}/>
                                </TableRow>
                            </>
                        ))}
                        <TableRow className={'tableRow'}>
                            <TableCell colSpan={4} />
                            <TableCell colSpan={2} className={'totals'}>Final Total </TableCell>
                            <TableCell colSpan={1} className={'totals'}>
                                {getGrandTotals(timeEntriesMap).toFixed(2)}
                            </TableCell>
                            <TableCell colSpan={3}/>
                        </TableRow>
                    </TableBody>
                </Table>
            </form>
        );
    }

    private status(sapStatus: SapStatus) {
        switch (sapStatus) {
            case SapStatus.QUEUED:
                return 'Posted';
                break;
            case SapStatus.SUBMITTED:
                return  'Posted';
                break;
            case SapStatus.UNSUBMITTED:
                return  'Draft';
                break;
            default:
                return '';
        }
    }
    
}