import { Button } from '@material-ui/core';
import { CallMerge, Check, Delete, Save, Send, SwapHoriz, Undo } from '@material-ui/icons';
import * as Styled from '../../containers/Home/styled.desktop';
import * as React from 'react';
import { TimeKeeperAssignment } from '../../api/types/types';
import { DateTime } from 'luxon';

interface Props {
    dirty: boolean;
    saveDirtyEntries: () => void;
    clearSelectedEntries: () => void;
    postSelectedEntries: () => void;
    moveSelectedEntries: () => void;
    mergeEntries: () => void;
    transferEntries: () => void;
    deleteSelectedEntries: () => void;
    selectedEntryIds: number[];
    disableSavePost: boolean;
}

export const FabContainerView = (props: Props) => {
    const {
        dirty,
        saveDirtyEntries,
        clearSelectedEntries,
        postSelectedEntries,
        moveSelectedEntries,
        mergeEntries,
        transferEntries,
        deleteSelectedEntries,
        selectedEntryIds,
        disableSavePost
    } = props;

    return (
        <Styled.FabContainer onScreen={selectedEntryIds.length > 0 || dirty}>
            {dirty &&
            <Button
                onClick={saveDirtyEntries}
                variant="extendedFab"
                aria-label="Save"
                disabled={disableSavePost}
            >
                <Save/>
                Save Changes
            </Button>}
            {(selectedEntryIds.length > 0) && <>
                <Button
                    onClick={clearSelectedEntries}
                    variant="extendedFab"
                    color="secondary"
                    aria-label="Clear"
                >
                    <Undo/>
                    Clear ({selectedEntryIds.length})
                </Button>
                <Button
                    onClick={postSelectedEntries}
                    variant="extendedFab"
                    aria-label="Post"
                    disabled={disableSavePost}
                >
                    <Check/>
                    Post ({selectedEntryIds.length})
                </Button>
                <Button
                    onClick={moveSelectedEntries}
                    variant="extendedFab"
                    aria-label="move"
                >
                    <Send/>
                    Move ({selectedEntryIds.length})
                </Button>
                {selectedEntryIds.length > 1 &&
                <Button
                    onClick={mergeEntries}
                    variant="extendedFab"
                    aria-label="merge"
                >
                    <CallMerge/>
                    Merge ({selectedEntryIds.length})
                </Button>
                }
                {selectedEntryIds.length > 1 &&
                <Button
                    onClick={transferEntries}
                    variant="extendedFab"
                    aria-label="transfer"
                >
                    <SwapHoriz/>
                    Transfer ({selectedEntryIds.length})
                </Button>
                }
                <Button
                    onClick={deleteSelectedEntries}
                    variant="extendedFab"
                    aria-label="Delete"
                >
                    <Delete/>
                    Delete ({selectedEntryIds.length})
                </Button>
            </>}
        </Styled.FabContainer>
    )
}