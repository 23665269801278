import * as React from 'react';
import * as Styled from './styled';
import { Add, Delete, Save, Search, Undo } from '@material-ui/icons';
import { Button, IconButton, Input, Tooltip, FormHelperText } from '@material-ui/core';
import CustomDictionaryStore from 'store/customDictionary.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { CustomDictionary, TimeKeeperAssignment } from 'api/types/types';
import { FabContainer } from '../Home/styled.desktop';
import { DirtyIndicator } from 'components/TimeEntryPanel/styled';
import { TKConsumer } from 'common/TKProvider';

interface Props {
    customDictionaryStore?: CustomDictionaryStore
}

const WORD_VALIDATION_TEXT = {
    spaceErrorText: 'No spaces allowed',
    dupErrorText: 'Duplicate Word',
    requiredFieldText: 'Required Field'
}

class CustomDictionaryForm extends React.Component<{
    onEdit: (d: CustomDictionary) => void,
    dictionary: CustomDictionary,
    onDelete: () => void
}> {
    changeDictionary(text: string) {
        let newWord: CustomDictionary = this.props.dictionary.clone();
        newWord.dictionary = text;
        this.props.onEdit(newWord);
    }
    render() {
        const errorTexts = WORD_VALIDATION_TEXT;
        return(
            <div>
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) =>
                <Styled.FormMainContent>
                    <Input
                        value={this.props.dictionary.dictionary}
                        onChange={e => this.changeDictionary(e.target.value)}
                        style={{
                            width: '95%'
                        }}
                        disabled={false || !tk.writable}
                    />
                    <DirtyIndicator> {this.props.dictionary.dirty ? '*' : ''} </DirtyIndicator>
                    { tk.writable &&
                    <Tooltip title="Delete">
                    <IconButton onClick={this.props.onDelete}>
                        <Delete/>
                    </IconButton>
                    </Tooltip>
                    }
                </Styled.FormMainContent>}
                </TKConsumer>
                {(this.props.dictionary.errors.spaceError || this.props.dictionary.errors.dupError || 
                    this.props.dictionary.errors.blankError) &&
                <FormHelperText error={true}>
                    {this.props.dictionary.errors.spaceError ? errorTexts.spaceErrorText :
                        this.props.dictionary.errors.dupError ? errorTexts.dupErrorText : 
                            this.props.dictionary.errors.blankError ? errorTexts.requiredFieldText : ''
                    }
                </FormHelperText>}
            </div>
        );
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        customDictionaryStore: rootStore.customDictionaryStore
    };
})

@observer
export default class CustomDictionaryContainer extends React.Component<Props> {
    searchFieldRef = React.createRef<HTMLInputElement>();
    
    componentDidMount() {
        this.props.customDictionaryStore!.setSearchText('');
    }

    addNewWord = async () => {
        if (this.props.customDictionaryStore) {
            await this.props.customDictionaryStore.addNewWord(
                new CustomDictionary(this.props.customDictionaryStore.searchDictionaryText));
        }
        if (this.searchFieldRef.current) {
            this.searchFieldRef.current.focus();
        } 
    }
    render() {
        const {
            filteredDictionaries,
            searchDictionaryText,
            setSearchText,
            dictionaryChange,
            deleteWord,
            dirty,
            saveDirtyEntries,
            resetWords,
            noSpace,
            noDup
        } = this.props.customDictionaryStore!
        const errorTexts = WORD_VALIDATION_TEXT;
        return(
            <Styled.Container>
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) =>
                <div>
                    <Search
                        style={{ marginRight: '6px' }}
                    />
                    <Input
                        placeholder={tk.writable ? 'Add/Search Word' : 'Search Word'}
                        fullWidth={true}
                        style={{ maxWidth: '50%' }}
                        value={searchDictionaryText}
                        onChange={e => setSearchText(e.target.value)}
                        autoFocus={true}
                        inputRef={this.searchFieldRef}
                    />
                    {tk.writable && searchDictionaryText.length > 0 &&
                    <>
                        <Tooltip title="Add to Dictionary">
                        <IconButton onClick={this.addNewWord}>
                            <Add/>
                        </IconButton>
                        </Tooltip>
                    </>
                    }
                </div>}
                </TKConsumer>
                {(noSpace || noDup) &&
                <FormHelperText error={true}>
                    {noSpace ? errorTexts.spaceErrorText : ''}
                    {noDup ? errorTexts.dupErrorText : ''}
                </FormHelperText>
                }
                {/* FIXME: Use a virtual scroller */}
                <Styled.MainContent> 
                    {
                        filteredDictionaries.map((d: CustomDictionary) => 
                            <CustomDictionaryForm 
                                key={d.id as number} 
                                onEdit={dictionaryChange}
                                dictionary={d as CustomDictionary}
                                onDelete={() => deleteWord(d.id)}
                            />
                        )
                    }
                </Styled.MainContent>
                <FabContainer onScreen={dirty}>
                    <Button
                        onClick={saveDirtyEntries}
                        variant="extendedFab"
                        aria-label="Save"
                    >
                        <Save/>
                        Save Changes
                    </Button>
                    <Button
                        onClick={resetWords}
                        variant="extendedFab"
                        color="secondary"
                        aria-label="Clear"
                    >
                        <Undo />
                        Clear
                    </Button>
                </FabContainer>
            </Styled.Container>
        )
    }
}