import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import * as React from 'react';
import * as Styled from './styled';
import TimerNewDialogStore from 'store/Timer/timer.new.dialog.store';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    TextField
} from '@material-ui/core';
import { FlexDiv } from 'common/flex';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import Template from '../../api/immutables/ImmutableTemplate';
import AutoCompleteField from 'components/AutoCompleteField';
import { DateTime } from 'luxon';
import { MatterItemFormatter } from 'components/TemplateForm';
import { Features, Matter } from '../../api/types/types';
import TimeEntry from '../../api/immutables/ImmutableTimeEntry';

interface Props {
    dialogStore?: TimerNewDialogStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        dialogStore: rootStore.newTimerDialogStore
    };
})
@observer
export default class NewTimerDialog extends React.Component<Props> {
    
    fetchMatters = (api: RootAPI) => async (searchText: string, features: Features) => {
        let  results: Matter[] = []
        if (features.EpochConfigTrackedMatterClientsEnabled) { 
            return await api.Matter.searchMatters(
                searchText, true
            );
        } else {
            results = await api.Matter.searchMatters(
                searchText, true
            );
            if (results.length === 0) {
                results = await api.Matter.getAvailableMatters(
                    searchText,
                    false
                )
            }
        }
        return results
    }
    render() {
        const {
            isOpen,
            template,
            setTemplate,
            setMatter,
            matter,
            name,
            setName,
            cancel,
            doSave,
            start,
            toggleStart,
            validation,
            saving
        } = this.props.dialogStore!;

        const { mainWinLoading } = this.props.dialogStore!.rootStore.timerStore;
        
        const fetchTemplates = this.props.dialogStore!.rootStore.templateStore.fetchTemplatesInAutoComplate;
        
        return (
            <Dialog
                PaperProps={{style: {overflow: 'visible', maxWidth: '500px'}}}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={isOpen}
                fullWidth={true}
                maxWidth={false}
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="timer-dialog-title"
                    style={{paddingBottom: 0}}
                >
                    New Timer
                </DialogTitle>
                <DialogContent style={{overflow: 'visible', paddingBottom: 0}}>
                    <FlexDiv direction="column">
                        <Styled.PaddedFormItem>
                            <TextField
                                value={name}
                                label="Name"
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setName(evt.target.value)}
                                fullWidth={true}
                                error={!!validation.name}
                                helperText={validation.name}
                                disabled={mainWinLoading}
                            />
                        </Styled.PaddedFormItem>
                        <FeaturesConsumer>
                             { (features: Features) =>
                            <ApiConsumer>
                                { (api: RootAPI) =>
                                    <>
                                    <Styled.PaddedFormItem>
                                        <AutoCompleteField
                                            label="Template"
                                            fetch={fetchTemplates}
                                            currentItem={template}
                                            clearable={true}
                                            disabled={mainWinLoading}
                                            onClear={() => setTemplate(undefined)}
                                            getItemText={(m: Template) => `${m.name}`}
                                            onSelect={setTemplate}
                                        />
                                    </Styled.PaddedFormItem>
                                    <Styled.PaddedFormItem>
                                        <AutoCompleteField
                                            label={features.EpochConfigMatterLabel}
                                            fetch={(searchText: string) =>
                                                this.fetchMatters(api)(searchText, features)
                                            }
                                            currentItem={matter}
                                            clearable={true}
                                            onClear={() => setMatter()}
                                            formatItem={MatterItemFormatter}
                                            getItemText={(m: Matter) => `${m.number} - ${m.name}`}
                                            onSelect={setMatter}
                                            tooltip={(m: Matter) => m.description}
                                            disabled={mainWinLoading}
                                        />
                                    </Styled.PaddedFormItem>
                                    </>
                                }
                                
                            </ApiConsumer>
                            }
                         </FeaturesConsumer>    
                        <Styled.PaddedFormItem>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={mainWinLoading}
                                        checked={start}
                                        onChange={toggleStart}
                                    />
                                }
                                label="Start Timer"
                            />
                        </Styled.PaddedFormItem>
                    </FlexDiv>
                </DialogContent>
                <DialogActions>
                    <Button disabled={saving || mainWinLoading} onClick={doSave} >Save</Button>
                    <Button disabled={mainWinLoading} onClick={cancel} >Cancel</Button>
                </DialogActions>

            </Dialog>
        );
    }
}
    