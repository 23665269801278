import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    LinearProgress
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import NarrativeStore from 'store/narrative.store'
import { Features, Narrative } from 'api/types/types'
import CreateNarrativeCodeDialogStore from 'store/createNarrativeCodeDialog.store';
import { NarrativeField } from 'components/NarrativeField';
import { FeaturesConsumer } from 'common/FeaturesProvider';

interface Props {
    createNarrativeCodeDialogStore?: CreateNarrativeCodeDialogStore;
    narrativeStore?: NarrativeStore
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        narrativeStore: rootStore.narrativeStore,
        createNarrativeCodeDialogStore: rootStore.createNarrativeCodeDialogStore
    };
})
@observer
export default class CreateNarrativeCodeDialog extends React.Component<Props> {
    onSave = () => {
        const store = this.props.createNarrativeCodeDialogStore!;
        
        if (store.validate()) {
            this.props.narrativeStore!.addNarrative(
                new Narrative(store.narrativeKey, store.narrativeReplacement), false
            );
            
            store.resolveAndClose();
        }
    }
    
    onBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const store = this.props.createNarrativeCodeDialogStore!;
        if (e.target.value) {
            store.validateGlobalDuplicate(e.target.value);
        }
    }

    render() {
        const store = this.props.createNarrativeCodeDialogStore!;
        const {
            isOpen,
            cancel,
            loading,
            setNarrativeKey,
            narrativeKey,
            invalid,
            setNarrativeReplacement,
            narrativeReplacement
        } = this.props.createNarrativeCodeDialogStore!;

        let narrativeKeyValidator = invalid.narrativeKey;
        let narrativeReplacementValidator = invalid.narrativeReplacement;
        
        return (
            <FeaturesConsumer>
                { (features: Features) =>
                <>
                    <Dialog
                        open={isOpen}
                        onClose={cancel}
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                    >
                        <DialogTitle>
                            <div>New Narrative Code</div>
                            {loading && <LinearProgress/>}
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth={true}
                                label="Narrative Code"
                                onChange={e => setNarrativeKey(e.target.value)}
                                value={narrativeKey}
                                error={!!narrativeKeyValidator}
                                helperText={narrativeKeyValidator}
                                onBlur={this.onBlur}
                                inputProps={{
                                    maxLength: 10
                                }}
                            />
                            <NarrativeField
                                disableNarrativeContextMenuActions={true}
                                onChange={setNarrativeReplacement}
                                placeholder="Narrative Text"
                                value={narrativeReplacement}
                                error={!!narrativeReplacementValidator}
                                helperText={narrativeReplacementValidator}
                                maxLength={features.EpochConfigNarrativesMaximumChars}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onSave} color="primary">
                                Create
                            </Button>
                            <Button onClick={cancel} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
                }      
            </FeaturesConsumer>    
        );
    }
}