import { RouterStore } from 'mobx-react-router';
import { RootStore } from 'store/root.store';
const createHistory = require('history').createHashHistory;
import { action, computed, makeObservable } from 'mobx';
import BaseStore from 'store/base.store';

/** Provides wrappers for navigating to specific pages within application */
export default class NavigationStore extends RouterStore {
    private bypassPrompt: boolean = false;
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    createHistory() {
        return createHistory({
            getUserConfirmation: async (message: string, callback: (result: boolean) => void) => {
                const canIRoute = await this.canPush();
                
                callback(canIRoute);
            }
         });
    }
    
    async canPush(): Promise<boolean> {
        if (!this.requiresPrompt) {
            this.rootStore.resetForms();
            
            return true
        }
        
        const res = await this.rootStore.confirmStore.confirm('You have unsaved changes. Do you want to continue without saving?');

        if (res) {
            this.rootStore.resetForms();
            return true
        } else {
            return false
        }
    }
    
    @action
    async attemptPush(url: string): Promise<boolean> {
        if (!this.requiresPrompt) {
            this.rootStore.resetForms();
            this.push(url);
            
            return true;
        }
        
        const res = await this.rootStore.confirmStore.confirm('You have unsaved changes. Do you want to continue without saving?');

        if (res) {
            this.rootStore.resetForms();
            this.forcePush(url);
            return true
        } else {
            return false
        }
    }
    
    @action
    private forcePush(url: string) {
        this.bypassPrompt = true;
        this.push(url);
        this.bypassPrompt = false;
    }

    private get requiresPrompt(): boolean {
        return !this.bypassPrompt && this.rootStore.dirty;
    }

}