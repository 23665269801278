import * as React from 'react';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import { DateTime } from 'luxon';
import * as Styled from './styled';
import { Add } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { ValidationState } from 'api/immutables/validators';
import TimeEntryDropTarget from '../../containers/Home/TimeEntryDropTarget';
import { getDateFormat } from '../../util/date';
import { AggregateTotals } from 'store/home.store';
import { TKConsumer } from 'common/TKProvider';
import { TimeKeeperAssignment } from 'api/types/types';
interface Props {
    entries: TimeEntry[];
    date: DateTime;
    onChange: (t: TimeEntry, newVState?: ValidationState, durVState?: boolean) => void;
    selected: number[];
    onSelect: (ids: number[]) => void;
    expanded: number[];
    validationMap?: Map<number, ValidationState>;
    durValidationMap?: Map<number, boolean | undefined>;
    onExpand: (ids: number[]) => void;
    onCreateEntry: (date: DateTime) => void;
    onPost: (t: TimeEntry) => void;
    onSave: (t: TimeEntry) => void;
    onDelete: (t: TimeEntry) => void;
    onCancel: (t: TimeEntry) => void;
    onCopy: (t: TimeEntry) => void;
    onEdit: (t: TimeEntry) => void;
    onSplit: (t: TimeEntry) => void;
    onMerge: (t: TimeEntry) => void;
    onTransfer: (t: TimeEntry) => void;
    aggregateTotals?: (date: DateTime[]) => AggregateTotals;
    onDrop?: () => void;
    buildCodeSets?: (id: number) => void;
    timeCastEnabledInDayView?: boolean;
    onSetFieldLoader?: (value: boolean) => void;
}

export default class TimeEntryList extends React.Component<Props> {
    
    select = (id: number) => () => {
        let selected = this.props.selected.slice();
        if (selected.includes(id)) {
            selected = selected.filter((s) => s !== id)
        } else {
            selected.push(id);
        }
        this.props.onSelect(selected);
    }
    
    expand = (id: number) => () => {
        let expanded = this.props.expanded.slice();
        if (expanded.includes(id)) {
            expanded = expanded.filter((s) => s !== id)
        } else {
            expanded.push(id);
        }
        this.props.onExpand(expanded);
    }
    
    selectAll = () => {
        let entries = this.props.entries.filter((e) => !e.isPosted());
        let rest = this.props.selected.filter(s => 
            entries.every((e) => e.id !== s)
        );
        if (entries.every((e) => this.props.selected.includes(e.id as number))) {
            this.props.onSelect(rest);
        } else {
            this.props.onSelect(entries.map(e => e.id as number).concat(rest));
        }
    }
    
    onCreateEntry = () => {
        this.props.onCreateEntry(this.props.date);
    }
    
    render() {
        const {
            date,
            entries,
            expanded, 
            selected,
            onChange,
            onPost,
            onSave,
            onDelete,
            onCancel,
            onCopy,
            onEdit,
            onSplit,
            onMerge,
            onTransfer,
            durValidationMap,
            validationMap,
            onDrop,
            buildCodeSets,
            aggregateTotals,
            timeCastEnabledInDayView,
            onSetFieldLoader
        } = this.props;
        let allSelected = entries.every((e) => selected.includes(e.id as number));
        const totals = aggregateTotals! ? aggregateTotals!([date]) : undefined;
        let moreThanOneSelected = selected.length > 1;
        return (
            <>
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) => 
                <Styled.TimeEntryListHeader>
                    {/* <Checkbox
                        checked={allSelected}
                        indeterminate={anySelected && !allSelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={this.selectAll}
                        disableRipple={true}
                        style={{padding: 0}}
                    /> */}
                    <Styled.ListDate selected={allSelected} writable={!tk.writable} onClick={this.selectAll}>
                        {date.toFormat('EEE') + ', ' + date.toFormat(getDateFormat())}
                    </Styled.ListDate>
                    {totals &&
                        <>
                            <Styled.Posted justifyContent="flex-end">
                                {'Posted' + ': ' + (totals.posted).toFixed(2)}
                            </Styled.Posted>
                            <Styled.Draft justifyContent="center">
                                {'Draft' + ': ' + (totals.draft).toFixed(2)}
                            </Styled.Draft>
                            <Styled.TotalDuration justifyContent="left">
                                {'Total' + ': ' + (totals.posted + totals.draft).toFixed(2)}
                            </Styled.TotalDuration>
                        </>
                    }
                    {
                        tk.writable &&
                        <Styled.StyledIconButton
                            disableRipple={true}
                            onClick={this.onCreateEntry}
                        >
                            <Tooltip title="New Time Entry">
                                <Add />
                            </Tooltip>
                        </Styled.StyledIconButton>
                    }                    
                </Styled.TimeEntryListHeader>}
                </TKConsumer>
                {entries.map((t) =>
                    <Styled.TimeEntryItem key={t.id as number}>
                        <TimeEntryDropTarget
                            validationState={validationMap ? validationMap.get(t.id!) : undefined}
                            durValidationState={durValidationMap ? durValidationMap.get(t.id!) : undefined}
                            timeEntry={t}
                            onChange={onChange}
                            onExpand={this.expand(t.id as number)}
                            expanded={expanded.includes(t.id as number)}
                            onSelect={this.select(t.id as number)}
                            selected={selected.includes(t.id as number)}
                            onPost={onPost}
                            onSave={onSave}
                            onDelete={onDelete}
                            onCancel={onCancel}
                            onCopy={onCopy}
                            onEdit={onEdit}
                            onSplit={onSplit}
                            onMerge={onMerge}
                            isMergeable={moreThanOneSelected}
                            onTransfer={onTransfer}
                            isTransferable={moreThanOneSelected}
                            onDrop={onDrop}
                            buildCodeSets={buildCodeSets}
                            timeCastEnabledInDayView={timeCastEnabledInDayView}
                            onSetFieldLoader={onSetFieldLoader}
                        />
                    </Styled.TimeEntryItem>
                )}
            </>
        );
    }
}