import * as React from 'react';
import * as Styled from './styled.mobile';
import {
    CalendarToday,
    FileCopy,
} from '@material-ui/icons';

export default class NavBarMobile extends React.Component {
    render() {
        return (
            <Styled.NavBarContainer>
                <Styled.IconList>
                    <Styled.CustomNavLink to="/home">
                        <Styled.IconItem>
                            <CalendarToday fontSize="inherit" />
                            <Styled.IconText>Time Entries</Styled.IconText>
                        </Styled.IconItem>
                    </Styled.CustomNavLink>
                    <Styled.CustomNavLink to="/templates">
                        <Styled.IconItem>
                            <FileCopy fontSize="inherit" />
                            <Styled.IconText>Templates</Styled.IconText>
                        </Styled.IconItem>
                    </Styled.CustomNavLink>
                </Styled.IconList>
            </Styled.NavBarContainer>
        );
    }
}
