import { action, computed, observable, makeObservable } from 'mobx';
import ImmutableTimeEntry, { SapStatus } from 'api/immutables/ImmutableTimeEntry'
import { DateTime } from 'luxon'
import Template from 'api/immutables/ImmutableTemplate'
import ImmutableTemplate from 'api/immutables/ImmutableTemplate'
import {
    ValidatePost,
    ValidateSave,
    ValidateTemplate,
    ValidationState,
    ValidationTemplateState
} from 'api/immutables/validators'
import DialogRootStore from 'store/dialog.root.store'
import { RootStore } from './root.store'
import { debounce } from 'typescript-debounce-decorator'
import { TimeEntry } from '../api/implementations/electron/Dexie';
import { CodeSetFlags, TimeEntryType } from 'api/types/types';
import logger from '../logging/logging';

type SaveResult = {
    total: number;
    entriesSaved: number;
    entriesNotSaved: number;
}

type PostResult = {
    total: number;
    entriesPosted: number;
    entriesNotPosted: number;
}

// tslint:disable-next-line:no-any
export default class MultipleTimeEntriesDialogStore extends DialogRootStore<ImmutableTimeEntry[], void>  {
    @observable entries: ImmutableTimeEntry[] = [];
    @observable editAllLock: boolean = true;
    @observable indexOfEntityBeingEdited: number = 0;
    @observable templateName: string = '';
    @observable selectedTemplate?: Template;
    @observable validationState?: ValidationState;
    @observable durationValidationState?: boolean;
    @observable templateValidationState?: ValidationTemplateState;
    @observable narrativeText: string[] = [];
    @observable saving: boolean = false;
    // new variable to check if entry is valid as validationState is being undefined 
    // while moving to nextEntry
    @observable isEntryValid: boolean = true;
    // new variable to store valid entries and posting them to backend in saveAllEntries
    entriesToPost: ImmutableTimeEntry[] = [];
    
    @computed get minNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMinimumChars;
    }
    
    @computed get maxNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMaximumChars;
    }

    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this);
    }

    @computed get entry(): ImmutableTimeEntry {
        if (this.entries.length > 0) {
            return this.entries[this.indexOfEntityBeingEdited]
        } else { 
            return new ImmutableTimeEntry(); 
        }
    }

    set entry(timeEntry: ImmutableTimeEntry) {
        const currentEntry = this.entry
        currentEntry.timeEntryType = TimeEntryType.NORMAL;
        if (currentEntry) {
            if (this.editAllLock) {
                for (let i = 0; i < this.entries.length; i++) {
                    let workDate = this.entries[i].workDateTime;

                    this.entries[i] = timeEntry.clone();
                    this.entries[i].workDateTime = workDate;
                    this.entries[i].sapStatus = SapStatus.UNSUBMITTED;
                }
            } else {
                const workDate = this.entries[this.indexOfEntityBeingEdited].workDateTime;
                const sapStatus = this.entries[this.indexOfEntityBeingEdited].sapStatus;
                const teId = this.entries[this.indexOfEntityBeingEdited].id;
                const duration = this.entries[this.indexOfEntityBeingEdited].duration;
                currentEntry.id = teId;
                currentEntry.workDateTime = workDate;
                currentEntry.sapStatus = sapStatus;
                currentEntry.duration = duration;
                this.entries[this.indexOfEntityBeingEdited] = currentEntry.clone();
            }
        }
    }
    
    removeEntryFromSelection = (timeEntry: ImmutableTimeEntry) => {
        if (this.entries) {
            this.indexOfEntityBeingEdited = 0;
            this.entries = this.entries.filter(e => e !== timeEntry)
            this.rootStore.homeStore.setSelectedDates(
                this.rootStore.homeStore.selectedDates.filter(
                    d => (d.toISO() !== timeEntry.workDateTime)
                ))
            // If all of them are removed, just close the dialog
            if (this.entries.length === 0) {
                this.resolveAndClose();
            }
        }
    }
    
    updateEntries = () => {
        const newEntries = this.entries.filter((te: ImmutableTimeEntry) => te.sapStatus === SapStatus.NEW);
        if (newEntries.length === 0) {
            this.resolveAndClose();
            return
        } else if (this.indexOfEntityBeingEdited >= this.entries.length) {
            this.indexOfEntityBeingEdited = 0
        }
    }

    @computed get canSelectPreviousTimeEntry() {
        if (!this.entries || !this.entries.length) {
            return false
        }

        return this.indexOfEntityBeingEdited > 0
    }
    
    @computed get canSelectNextTimeEntry() {
        if (!this.entries || !this.entries.length) {
            return false
        }

        return this.indexOfEntityBeingEdited < this.entries.length - 1
    }

    nextTimeEntry = () => {
        if (!this.entries || !this.entries.length) {
            this.resolveAndClose();
            return
        }

        this.indexOfEntityBeingEdited += 1
        this.validationState = undefined;
        if (!this.editAllLock) {
            if (this.indexOfEntityBeingEdited > this.entries.length - 1) {
                this.indexOfEntityBeingEdited = 0
            }
        }
    }

    previousTimeEntry = () => {
        if (!this.entries || !this.entries.length) {
            this.resolveAndClose();
            return
        }
        this.indexOfEntityBeingEdited -= 1;
        this.validationState = undefined;
        // if (this.indexOfEntityBeingEdited < 0) {
        //     this.indexOfEntityBeingEdited = this.entries.length - 1
        // }
    }
    
    @action.bound
    async onOpen(entries: ImmutableTimeEntry[]): Promise<void> {
        this.entries = entries ? entries : [];
        for (let i = 0; i < this.entries.length; i++) {
            this.narrativeText[i] = this.entries[i].narrative ? this.entries[i].narrative! : '';
        }
        this.validationState = undefined
        this.rootStore.setColloaboratees([]);
        this.clear();
    }

    @action toggleLock = () => {
        this.editAllLock = !this.editAllLock
    }

    @action changeEntry = (entry: ImmutableTimeEntry, newVState?: ValidationState, durVstate?: boolean) => {
        if (this.entry) {
            // a valid entry is selected

            this.validationState = newVState
            if (durVstate !== undefined) {
                this.durationValidationState = durVstate;
            }

            this.templateName = (entry.matterId === null) ? '' : this.templateName
            
            for (let i = 0; i < this.entries.length; i++) {
                if (this.entries[i].sapStatus === SapStatus.QUEUED) {
                    continue;
                } else if (this.entries[i].sapStatus === SapStatus.UNSUBMITTED) {
                    if (this.entries[i].id === entry.id) {
                        this.entries[i] = entry.clone();
                    }
                } else {
                    const workDate = this.entries[i].workDateTime
                    const sapStatus = this.entries[i].sapStatus;
                    const localId = (this.entries[i] as TimeEntry).localId
                    const id = this.entries[i].id

                    this.entries[i] = entry.clone()
                    this.entries[i].workDateTime = workDate;
                    this.entries[i].sapStatus = sapStatus;
                    if (id) {
                        this.entries[i].id = id;
                    } else {
                        delete this.entries[i].id
                    }
                    if (id) {
                        (this.entries[i] as TimeEntry).localId = localId;
                    } else {
                        delete (this.entries[i] as TimeEntry).localId
                    }
                }
            }
            this.narrativeText[this.indexOfEntityBeingEdited] = entry.narrative ? entry.narrative : '';
        }
    }

    @debounce(500, { leading: false })
    @action.bound
    async wrappedPost() {
        if (this.saving) {
            return
        }

        this.saving = true

        try {
            let te = Object.assign(new ImmutableTimeEntry(), this.entry);
            await this.postEntry(te);
            this.rootStore.homeStore.dayCountsIfDayOrWeek();
        } finally {
            this.saving = false
        }
    }

    @debounce(500, { leading: false })
    @action.bound
    async wrappedPostAll() {
        if (this.saving || !this.entries.length) {
            return
        }

        this.saving = true

        try {
            const result = await this.postAllEntries()
            if (result.entriesPosted > 0) {
                this.rootStore.homeStore.dayCountsIfDayOrWeek();
            }
            this.rootStore.snackbarStore.triggerSnackbar(
                `${result.entriesPosted} posted.` +
                (result.entriesNotPosted > 0 ? ` ${result.entriesNotPosted} not posted.` : '')
            )
        } finally {
            this.saving = false
        }
    }

    @debounce(500, { leading: false })
    @action.bound
    async wrappedSave() {
        if (this.saving) {
            return
        }

        this.saving = true

        try {
            let te = Object.assign(new ImmutableTimeEntry(), this.entry);
            te.sapStatus = SapStatus.UNSUBMITTED;
            await this.saveEntry(te);
            this.rootStore.homeStore.dayCountsIfDayOrWeek();
        } finally {
            this.saving = false
        }
    }

    @debounce(500, { leading: false })
    @action.bound
    async wrappedSaveAll() {
        if (this.saving) {
            return
        }

        this.saving = true

        try {
            const result = await this.saveAllEntries()
            if (result.entriesSaved > 0) {
                this.rootStore.homeStore.dayCountsIfDayOrWeek();
            }

            this.rootStore.snackbarStore.triggerSnackbar(
                `${result.entriesSaved} saved.` +
                (result.entriesNotSaved > 0 ? ` ${result.entriesNotSaved} not saved.` : '')
            )
        } finally {
            this.saving = false
        }
    }

    /**
     * 1. Posts the time entry which is currently being edited.
     * 2. Removes it from the list of entries that are being edited.
     */
    @action postEntry = async (
        current: ImmutableTimeEntry,
        index: number = this.indexOfEntityBeingEdited
    ): Promise<boolean> => {
        try {
            let matterEntryType: string = '';
            let matterStatusDesc: string = '';
            let narrativeMinLength;
            let narrativeMaxLength;
            if (current.matterId) {
                const matter = await this.rootStore.api.Matter.get(current.matterId);
                if (matter) {
                    matterEntryType = matter.entryType;
                    matterStatusDesc = matter.statusDescription;
                    narrativeMinLength = matter.minLength;
                    narrativeMaxLength = matter.maxLength;
                    current.bannedWords = matter.bannedWords;
                    current.blockBillingWords = matter.blockBillingWords;
                }
            }
            const activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(DateTime.fromISO(current.workDateTime));
            // sapStatus was changing to 'NEW' so had to update the sapStatus of an entry here
            current.sapStatus = SapStatus.QUEUED;
            // todo 24 hour validation
            const validationState = await ValidatePost(
                current,
                await this.getTotalDurationExclusive(current.workDateTime, current.id!),
                matterStatusDesc,
                matterEntryType,
                this.rootStore.appStore.features,
                activeTimeKeeper,
                narrativeMinLength,
                narrativeMaxLength
            );

            let template: ImmutableTemplate | undefined

            if (this.templateName.trim().length > 0) {
                // TODO validate template
                template = current.createTemplate()
                template.name = this.templateName

                let templateValidationState = ValidateTemplate(
                    template,
                    await this.rootStore.api.Template.getAllTemplates(), this.maxNarrativeLength)

                if (!templateValidationState.valid) {
                    this.templateValidationState = templateValidationState
                    return false
                }
            }

            if (validationState.valid && !this.durationValidationState) {
                this.isEntryValid = true;
                await this.saveEntry(current, index);

                return true
            }

            if (!validationState.valid) {
                this.isEntryValid = false;
                this.validationState = validationState
                return false
            }

            return true
        } catch (e) {
            logger.error('Multiple Time Entry Dialog, Posting an Entry failed.\n', e)
            return false;
        }
    }

    @action setWorkDate = (date: DateTime) => {
        try {
        let activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(date)

        this.entry = this.entry
            .setWorkDate(date)
            .setOffice(activeTimeKeeper ? activeTimeKeeper.office : undefined)
        
        this.indexOfEntityBeingEdited = this.entries.findIndex((e) =>
            DateTime.fromISO(e.workDateTime).equals(date)
        );

        if (this.validationState) {
            this.validationState.invalidWorkDate = false
            if (!activeTimeKeeper) {
                this.validationState.invalidWorkDate = true
            }
        }
        } catch (e) {
            logger.error('Time Entries, Setting Work Date for Multiple Time Entry Dialog failed. \n', e)
        }
    }

    @action setTemplateName = (name: string) => {
        this.templateValidationState = undefined
        this.templateName = name
    }

    @action setTemplate = async (template?: Template) => {
        try {
        this.selectedTemplate = template;
        const multipleEntryDuration = this.entry.duration;

        if (template) {
            if (this.editAllLock) {
                if (template.matter) {
                    const codeSetFlags: CodeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                            template.matter.id, 
                        this.entry.workDateTime
                    );
                    template.isPhaseCode = codeSetFlags.isPhaseCode;
                    template.isFfTaskCode = codeSetFlags.isFfTaskCode;
                    template.isActCode = codeSetFlags.isActCode;
                }
                this.entry = new ImmutableTimeEntry().clone().loadFromTemplate(template);
                this.entry.narrative = [this.entry.narrative, this.narrativeText[this.indexOfEntityBeingEdited]].join(' ').trim();
                this.entry = this.entry.setDuration(0)
                for (let i = 0; i < this.entries.length; i++) {
                    this.entries[i].sapStatus = SapStatus.NEW;
                    let activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(
                        DateTime.fromISO(this.entry.workDateTime)
                    );
                    this.entries[i] = this.entries[i].setDuration(multipleEntryDuration);
                    this.entries[i] = this.entries[i]
                        .setOffice(activeTimeKeeper ? activeTimeKeeper.office : undefined)
                        .setOfficeName(activeTimeKeeper ? activeTimeKeeper.officeName : undefined)
                }
            } else {
                for (let i = 0; i < this.entries.length; i++) {
                    let workDate = this.entries[i].workDateTime;
                    if (template.matter) {
                        const codeSetFlags: CodeSetFlags =
                            await this.rootStore.api.Code.determineCodeSetFields(
                                template.matter.id, 
                                this.entry.workDateTime
                            );
                        template.isPhaseCode = codeSetFlags.isPhaseCode;
                        template.isFfTaskCode = codeSetFlags.isFfTaskCode;
                        template.isActCode = codeSetFlags.isActCode;
                    }
                    if (this.entries[i].sapStatus === SapStatus.NEW) {
                        let activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(
                            DateTime.fromISO(workDate)
                        );
                        let sapStatus = this.entries[i].sapStatus;
                        this.entries[i] = new ImmutableTimeEntry().clone().loadFromTemplate(template);
                        this.entries[i].narrative = [this.entries[i].narrative, this.narrativeText[i]].join(' ').trim();
                        this.entries[i].workDateTime = workDate;
                        this.entries[i].sapStatus = sapStatus;
                        if (this.entries[i].sapStatus === undefined || this.entries[i].sapStatus === null) {
                            this.entries[i].sapStatus = SapStatus.NEW;
                        }
                        this.entries[i] = this.entries[i].setDuration(multipleEntryDuration);
                        this.entries[i] = this.entries[i]
                            .setOffice(activeTimeKeeper ? activeTimeKeeper.office : undefined)
                            .setOfficeName(activeTimeKeeper ? activeTimeKeeper.officeName : undefined)
                    } else {
                        let activeTimeKeeper = this.rootStore.appStore.getActiveTimeKeeperForDate(
                            DateTime.fromISO(workDate)
                        );
                        let idx = this.indexOfEntityBeingEdited;
                        let sapStatus = this.entries[idx].sapStatus;
                        let workDateTime = this.entries[idx].workDateTime;
                        let id = this.entries[idx].id;
                        this.entries[idx] = new ImmutableTimeEntry().clone().loadFromTemplate(template);
                        let narrative = [this.entries[idx].narrative, this.narrativeText[idx]].join(' ').trim();
                        this.entries[idx].id = id;
                        this.entries[idx].narrative = narrative;
                        this.entries[idx].sapStatus = sapStatus;
                        this.entries[idx].workDateTime = workDateTime;
                        this.entries[idx] = this.entries[idx].setDuration(multipleEntryDuration);
                        this.entries[i] = this.entries[i]
                            .setOffice(activeTimeKeeper ? activeTimeKeeper.office : undefined)
                            .setOfficeName(activeTimeKeeper ? activeTimeKeeper.officeName : undefined)
                    }
                }
            }
            // this.entries[this.indexOfEntityBeingEdited] = this.entry.loadFromTemplate(template)
            this.validationState = undefined
            this.entry.selectedCodeSetTemplate = null
        } else {
            for (let i = 0; i < this.entries.length; i++) {
                if (this.entries[i].narrative !== this.narrativeText[i] && 
                    (this.entries[i].sapStatus === SapStatus.NEW ||
                    this.entries[i].sapStatus === SapStatus.UNSUBMITTED)) {
                    let narrativeText = this.narrativeText[i].replace(this.entries[i].narrative!, '').trim();
                    this.entries[i].narrative = narrativeText;
                }
            }
        }
        } catch (e) {
            logger.error('Time Entries, Setting Template for Multiple Time Entry Dialog failed.\n', e)
        }
    }

    async getTotalDurationExclusive(workDate: string, id: number) {
        return await this.rootStore.api.TimeEntry.getTotalForDateExclusive(workDate, [id])
    }

    @action
    saveAllEntries = async (fromPost?: boolean): Promise<SaveResult> => {
        try {
        const total = this.entries.length;
        let entriesSaved = 0;
        let entriesToSave: ImmutableTimeEntry[] = []
        const tes = this.entries.slice().map((entity, index) => ({
            entity,
            index
        }));
        let unPostedEntries = tes.filter((i) =>
            i.entity.sapStatus === SapStatus.NEW
        )
        if (fromPost) {
            // while posting all entries we need to post entries that are valid.
            unPostedEntries = this.entriesToPost.slice().map((entity, index) => ({
                entity,
                index
            }));
            // if we dont have any unPostedEntries then just returning entriesSaved as 0 which is
            // already defined and total which is entries length.
            if (unPostedEntries.length === 0) {
                return {
                    entriesSaved,
                    entriesNotSaved: total - entriesSaved,
                    total
                }
            }
        }

        for (let idx = 0; idx < unPostedEntries.length; idx++) {
            let te = unPostedEntries[idx];
            let toSave = Object.assign(new ImmutableTimeEntry(), te.entity)
            if (!fromPost) {
                toSave.sapStatus = SapStatus.UNSUBMITTED;
            }

            let entryAfterValidation = await this.validationsForSave(toSave)

            if (entryAfterValidation) {
                entriesToSave.push(entryAfterValidation)
            }
        }
        let results = await this.rootStore.api.TimeEntry.updateEntries(entriesToSave)
        for (let id = 0; id < results.length; id++) {
            let te = Object.assign(new ImmutableTimeEntry(), JSON.parse(JSON.stringify(results[id].object)))
            let editedEntityObj = tes.filter(t => t.entity.workDateTime === te.workDateTime)
            // updating entries only when status is success and updating the saved entries count.
            if (!results[id].status.failed) {
                this.entries[editedEntityObj[0].index] = te;
                entriesSaved++;
            }
        }
        this.updateEntries();
        return {
            entriesSaved,
            entriesNotSaved: total - entriesSaved,
            total
        }
        } catch (e) {
            logger.error('Time Entries, Multiple Time Entry Dialog Save All Entries failed.\n', e)
            return {
                total: 0,
                entriesSaved: 0,
                entriesNotSaved: 0
            }
        }
    }

    validationsForPost = async (entry: ImmutableTimeEntry) => {
        try {
            let matterEntryType: string = '';
            let matterStatusDesc: string = '';
            let narrativeMinLength;
            let narrativeMaxLength;
            if (entry.matterId) {
                const matter = await this.rootStore.api.Matter.get(entry.matterId);
                if (matter) {
                    matterEntryType = matter.entryType;
                    matterStatusDesc = matter.statusDescription;
                    entry.bannedWords = matter.bannedWords;
                    entry.blockBillingWords = matter.blockBillingWords;
                    narrativeMinLength = matter.minLength;
                    narrativeMaxLength = matter.maxLength;
                }
            }

            // todo 24 hour validation
            const validationState = await ValidatePost(
                entry,
                await this.getTotalDurationExclusive(entry.workDateTime, entry.id!),
                matterStatusDesc,
                matterEntryType,
                this.rootStore.appStore.features,
                this.rootStore.appStore.getActiveTimeKeeperForDate(
                    DateTime.fromISO(entry.workDateTime)),
                    narrativeMinLength,
                    narrativeMaxLength
            );

            let template: ImmutableTemplate | undefined

            if (this.templateName.trim().length > 0) {
                // TODO validate template
                template = entry.createTemplate()
                template.name = this.templateName

                let templateValidationState = ValidateTemplate(
                    template,
                    await this.rootStore.api.Template.getAllTemplates(), 255)

                if (!templateValidationState.valid) {
                    this.templateValidationState = templateValidationState
                    return
                }
            }

            if (validationState.valid && !this.durationValidationState) {
                this.isEntryValid = true;
                return entry
            } else {
                this.validationState = validationState;
                this.isEntryValid = false;
                return
            }
        } catch (e) {
            logger.error('Time Entries, Validations for Post failed.\n', e)
            return undefined
        }
    }

    validationsForSave = async (entry: ImmutableTimeEntry) => {
        try {
        if (entry.matterId) {
            const matter = await this.rootStore.api.Matter.get(entry.matterId);
            if (matter) {
                entry.bannedWords = matter.bannedWords;
                entry.blockBillingWords = matter.blockBillingWords;
            }
        }
        let validationState = ValidateSave(
            entry,
            await this.getTotalDurationExclusive(entry.workDateTime, entry.id!),
            this.rootStore.appStore.features,
            this.rootStore.appStore.getActiveTimeKeeperForDate(
                DateTime.fromISO(entry.workDateTime))
        )
        if (!validationState.valid) {
            this.validationState = validationState
            return
        }
        if (this.durationValidationState) {
            return
        }
        let template: ImmutableTemplate | undefined

        if (this.templateName.trim().length > 0) {
            // TODO validate template
            template = entry.createTemplate()
            template.name = this.templateName

            let templateValidationState = ValidateTemplate(
                template,
                await this.rootStore.api.Template.getAllTemplates(), 255)

            if (!templateValidationState.valid) {
                this.templateValidationState = templateValidationState
                return
            }
        }

        if (template) {
            await this.rootStore.api.Template.saveTemplate(template)
        }

        if (entry.matterId) {
            const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                entry.matterId, entry.workDateTime
            );
            entry.isActCode = codeSetFlags.isActCode;
            entry.isPhaseCode = codeSetFlags.isPhaseCode;
            entry.isFfTaskCode = codeSetFlags.isFfTaskCode;
        }
        return entry
        } catch (e) {
            logger.error('Time Entries, Multiple Time Entry Dialog Validations for Save failed.\n', e)
            return undefined
        }
    }

    @action
    postAllEntries = async (): Promise<PostResult> => {
        try {
        const total = this.entries.length;
        let entriesPosted = 0;
        this.entriesToPost = [];
        const tes = this.entries.slice().map((entity, index) => ({
            entity,
            index
        }));

        const unPostedEntries = tes.filter((i) =>
            i.entity.sapStatus === SapStatus.QUEUED ||
            i.entity.sapStatus === SapStatus.POSTED ||
            i.entity.sapStatus === SapStatus.UNSUBMITTED ||
            i.entity.sapStatus === SapStatus.NEW
        );

        for (let idx = 0; idx < this.entries.length; idx++) {
            let itemIdx = unPostedEntries.findIndex(entry =>
                entry.entity.workDateTime === this.entries[idx].workDateTime
            )
            let item = unPostedEntries[itemIdx];
            let toPost = Object.assign(new ImmutableTimeEntry(), item.entity)
            toPost.sapStatus = SapStatus.QUEUED;
            let entryAfterValidation = await this.validationsForPost(toPost)
            if (entryAfterValidation) {
                // pushing valid entries to array that needs to be posted to backend
                this.entriesToPost.push(entryAfterValidation)
                let editedEntityObj = tes.filter(t => t.entity.workDateTime === entryAfterValidation!.workDateTime)
                this.entries[editedEntityObj[0].index] = entryAfterValidation;
            }
        }
        let responseForSaveAll = await this.saveAllEntries(true)
        entriesPosted = responseForSaveAll.entriesSaved
        return {
            entriesPosted,
            entriesNotPosted: total - entriesPosted,
            total
        }
        } catch (e) {
            logger.error('Time Entries, Multiple Time Entry Dialog Post failed.\n', e)
            return {
                total: 0,
                entriesPosted: 0,
                entriesNotPosted: 0
            }
        }
    }

    @action
    saveEntry = async (
        current: ImmutableTimeEntry,
        entityIndex: number = this.indexOfEntityBeingEdited
    ): Promise<boolean> => {
        try {
        if (current.matterId) {
            const matter = await this.rootStore.api.Matter.get(current.matterId);
            if (matter) {
                current.bannedWords = matter.bannedWords;
                current.blockBillingWords = matter.blockBillingWords;
            }
        }
        let validationState = ValidateSave(
            current,
            await this.getTotalDurationExclusive(current.workDateTime, current.id!),
            this.rootStore.appStore.features,
            this.rootStore.appStore.getActiveTimeKeeperForDate(
                DateTime.fromISO(current.workDateTime))
        )
        if (!validationState.valid) {
            this.validationState = validationState
            return false
        }

        if (this.durationValidationState) {
            return false
        }

        let template: ImmutableTemplate | undefined

        if (this.templateName.trim().length > 0) {
            // TODO validate template
            template = current.createTemplate()
            template.name = this.templateName

            let templateValidationState = ValidateTemplate(
                template,
                await this.rootStore.api.Template.getAllTemplates(), this.maxNarrativeLength)

            if (!templateValidationState.valid) {
                this.templateValidationState = templateValidationState
                return false
            }
        }
        let results = await this.rootStore.api.TimeEntry.updateEntries([current])
        let result = results[0];
        let t = Object.assign(new ImmutableTimeEntry(), JSON.parse(JSON.stringify(result.object)))
        if (t.matterId) {
            const codeSetFlags = await this.rootStore.api.Code.determineCodeSetFields(
                t.matterId, t.workDateTime
            );
            t.isActCode = codeSetFlags.isActCode;
            t.isPhaseCode = codeSetFlags.isPhaseCode;
            t.isFfTaskCode = codeSetFlags.isFfTaskCode;
        }
        
        this.entries[entityIndex] = t;
        
        if (result.status.failed) {
            this.rootStore.snackbarStore.triggerSnackbar(result.status.message)
            this.entries[entityIndex].sapStatus = SapStatus.UNSUBMITTED
            this.entries[entityIndex] = this.entries[entityIndex].clone()

            return false
        }

        if (!result.status.failed) {
            this.rootStore.snackbarStore.triggerSnackbar('Saved Successfully')
        }

        if (template) {
            await this.rootStore.api.Template.saveTemplate(template)
        }
        
        await this.nextTimeEntry();
        this.updateEntries();

        return true
        } catch (e) {
            logger.error('Time Entries, Multiple Time Entry Dialog Save failed.\n', e)
            return false;
        }
    }
    @action.bound
    async clearAndClose() {
        if (!this.editAllLock) {
            if (await this.rootStore.confirmStore.confirm('Are you sure you want to cancel time entry for remaining days?')) {
                return;
            }
        }
        this.clear();
        this.cancel();
    }
    
    @action
    clear() {
        this.editAllLock = true
        this.templateName = ''
        this.selectedTemplate = undefined
        this.durationValidationState = undefined
        this.templateValidationState = undefined
        this.rootStore.homeStore.validationState.clear()
        this.rootStore.timeEntryStore.validationState.clear()
        this.indexOfEntityBeingEdited = 0;
    }

    @action setFieldLoaderFn = (value: boolean) => {
        this.saving = value
    }
}
