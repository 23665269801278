import React, { useRef, useState } from 'react'
import { PhoneCallData } from '../../api/types/types'
import { FileCopy, Phone, PhoneCallback } from '@material-ui/icons';
import { IconButton, Paper, Popper } from '@material-ui/core'
import { copyToClipboard } from '../../util/clipboard'

export const PhoneCallDescription = (props: {data: PhoneCallData}) => {
    const data = props.data;
    
    return (
        <span>
            {data.initiator ? <Phone style={{fontSize: '13px'}}/> : <PhoneCallback style={{fontSize: '13px'}} />}
            &nbsp;
            {data.initiator ? 'Call to' : 'Call from'}
            &nbsp;
            {data.otherParty ? (
                data.otherParty.fullName
            ) : (
                data.initiator ? data.toNumber : data.fromNumber
            )}
        </span>
    )
}