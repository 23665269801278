import * as React from 'react';
import { Menu, Item } from 'react-contexify';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';

interface Props {
    /** id for corresponding provider, must be unique */
    id: string;
    editHandler?: (t: TimeEntry) => void;
    postHandler?: (t: TimeEntry) => void;
    deleteHandler?: (t: TimeEntry) => void;
    copyHandler?: (t: TimeEntry) => void;
    viewHandler?: (t: TimeEntry) => void;
    splitHandler?: (t: TimeEntry) => void;
    mergeHandler?: (t: TimeEntry) => void;
    transferHandler?: (t: TimeEntry) => void;
}

/**
 * context menu for TimeEntryItems
 * displays options only for handlers that are passed in. Blocked actions expect undefined
 */
export default class TEContextMenu extends React.Component<Props> {

    // tslint:disable-next-line:no-any
    onClick = (handler: (t: TimeEntry) => void) => (props: any) => {
        handler(props.entry);
    };

    render() {
        let {
            id,
            editHandler,
            postHandler,
            deleteHandler,
            copyHandler,
            viewHandler,
            splitHandler,
            mergeHandler,
            transferHandler
        } = this.props;
        return (
            <Menu id={id} animation="none">
                {editHandler ? <Item onClick={this.onClick(editHandler)}>Edit</Item> : <React.Fragment/>}
                {viewHandler ? <Item onClick={this.onClick(viewHandler)}>View</Item> : <React.Fragment/>}
                {postHandler ? <Item onClick={this.onClick(postHandler)}>Post</Item> : <React.Fragment/>}
                {deleteHandler ? <Item onClick={this.onClick(deleteHandler)}>Delete</Item> : <React.Fragment/>}
                {copyHandler ? <Item onClick={this.onClick(copyHandler)}>Copy</Item> : <React.Fragment/>}
                {splitHandler ? <Item onClick={this.onClick(splitHandler)}>Split</Item> : <React.Fragment/>}
                {mergeHandler ? <Item onClick={this.onClick(mergeHandler)}>Merge</Item> : <React.Fragment/>}
                {transferHandler ? <Item onClick={this.onClick(transferHandler)}>Transfer</Item> : <React.Fragment/>}
            </Menu>
        );
    }
}
