import React from 'react'
import { useDrop } from 'react-dnd';
import { DragItemTypes } from 'components/DragNDropTimeCastSegment/SegmentDragSource';
import ImmutableTimeEntry, { SapStatus } from '../../api/immutables/ImmutableTimeEntry';
import { Paper } from '@material-ui/core';

const NewTimeEntryDropTarget = () => {
    let entry = new ImmutableTimeEntry();
    entry = entry.setStatus(SapStatus.UNSUBMITTED);
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: DragItemTypes.ELEMENT_TYPE,
        drop: (item, monitor) => (
            entry // This will become available at the dragSource in endDrag method as getDropResult.
        ),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    })

    const isActive = canDrop && isOver
    const style: React.CSSProperties = {
        height: 75,
        backgroundColor: '#fff',
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        opacity: canDrop ? 0.5 : 0.7,
        border: '1px solid #ccc',
        display: canDrop ? 'flex' : 'none',
        transition: 'ease'
    }

    return (
        <div ref={drop} id={'DropContainer'}>
            <Paper style={style}>
            {!isOver ? `Drop TimeCast segments here to create a New TimeEntry` : ''}
            </Paper>
        </div>
    )
}

export default NewTimeEntryDropTarget