import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { parseDuration } from 'util/date';
import { InputAdornment } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
interface State {
    durationText: string;
    isFirstEdited: boolean
}
interface Props {
    error?: boolean;
    errorText?: string;
    duration: number;
    increment: number;
    onChange?: (duration: number, error?: boolean) => void;
    disabled?: boolean;
}
export default class DurationField extends React.Component<Props, State> {
    state: State = {
        durationText: this.props.duration === 0
            ? ''
            : (this.props.duration / 3600).toFixed(2),
        isFirstEdited: false
    }
    componentWillReceiveProps(nextProps: Props) {
        let dtxt = (nextProps.duration === 0) ? '' : (nextProps.duration / 3600).toFixed(2);
        
        if (!nextProps.error) {
            this.setState({ durationText: dtxt });
        }
    }
    
    onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            durationText: event.target.value,
            isFirstEdited: false
        });
        // if duraion is cleared on a draft entry, it should be marked as dirty
        if (event.target.value === '') {
            if (this.props.onChange) {
                this.props.onChange(0, false);
            }
        }
    }
    
    onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            isFirstEdited: false
        })
        if (this.state.durationText === '') {
            if (this.props.onChange) {
                this.props.onChange(0, false);
                return;
            }
        }
        
        try {
            let duration = parseDuration(this.state.durationText.trim());
            // todo: Rounding
            duration = duration * 60;
            
            if (this.props.onChange) {
                this.props.onChange(duration, false);
            }
        } catch (err) {
            if (this.props.onChange) {
                this.props.onChange(this.props.duration, true);
            }
        }
        // todo
    }
    
    updateDuration = () => {
        let duration = parseDuration(this.state.durationText);
        if (this.props.onChange && this.props.duration < (24 * 60 * 60)) {
            this.props.onChange(duration * 60 , false)
        }
    }

    increaseDuration = () => {
        let parsedDurTxt = parseDuration(this.state.durationText)
        if (parsedDurTxt < 24 * 60) {
            this.setState({
                durationText: ((parsedDurTxt * 60 +
                    this.props.increment * 3600) / 3600).toFixed(2).toString()
            })
        }
        // Props are being set only the first time when the up arrow key button is pressed
        // This is for list of localTimeEntries to know that we are editing the time entry
        if (this.props.onChange && this.props.duration < (24 * 60 * 60) && !this.state.isFirstEdited) {
            this.setState({
                isFirstEdited: true
            })
            this.props.onChange(parsedDurTxt * 60 + this.props.increment * 3600, false)
        }
    }

    decreaseDuration = () => {
        let parsedDurTxt = parseDuration(this.state.durationText)
        if (parsedDurTxt !== 0) {
            this.setState({
                durationText: ((Math.floor(parsedDurTxt * 60) -
                    (this.props.increment * 3600)) / 3600).toFixed(2).toString(),
                isFirstEdited: true
            })
        }
        // Props are being set only the first time when the down arrow key button is pressed
        // This is for list of localTimeEntries to know that we are editing the time entry
        if (this.props.onChange && !this.state.isFirstEdited) {
            this.setState({
                isFirstEdited: true
            })
            this.props.onChange(Math.floor(parsedDurTxt * 60) - (this.props.increment * 3600), false)
        }
    }

    render() {
        const etxt = this.props.errorText;
        const error = this.props.error;
        
        return (
            <TextField
                id="duration"
                label="Duration"
                onBlur={this.onBlur}
                helperText={etxt}
                error={error}
                value={this.state.durationText}
                onChange={this.onTextChange}
                inputProps={{
                    step: this.props.increment
                }}
                fullWidth={true}
                disabled={this.props.disabled}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><AccessTime /></InputAdornment>,
                }}
                autoComplete="off"
                onKeyDown={(evt) => {
                    if (evt.key === 'ArrowUp') {
                        this.increaseDuration();
                        evt.preventDefault();
                    }
                    if ( evt.key === 'Control') {
                        this.updateDuration()
                        evt.preventDefault();
                    }
                    if (evt.key === 'ArrowDown') {
                        this.decreaseDuration();
                        evt.preventDefault();
                    }
                }}
            />
        );
    }
}