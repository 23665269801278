import * as React from 'react';
import * as Styled from './styled';
import { FlexDiv } from 'common/flex';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { RootStore } from 'store/root.store';
import { inject, observer } from 'mobx-react';
import Spinner from 'components/LoadingSpinner/Spinner';
import { theme } from 'common/theme';
import { removeUrlParameter } from '../../api/util';
import { Clear, Warning } from '@material-ui/icons';
import { Platform } from '../../util/Platform';
import SessionImpl from '../../api/implementations/electron/Session.impl';
import { LoginMode } from '../../api/types/types';

export const svgLogo = require('images/logodark.svg');

interface State {
    user: string;
    loading: boolean;
    password: string;
    errored: boolean;
    confirmHardLogoutDialog: boolean;
}

interface Props {
    rootStore?: RootStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        rootStore
    };
})
@observer
export default class Login extends React.Component<Props, State> {
    state: State = {
        user: process && process.env && process.env.NODE_ENV === 'development'
            ? 'Time01@f'
            : '',
        password: process && process.env && process.env.NODE_ENV === 'development'
            ? 'Un!xT!me'
            : '',
        loading: false,
        errored: false,
        confirmHardLogoutDialog: false
    };

    setUser = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            user: evt.target.value,
            errored: false
        });
    };

    setPass = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            password: evt.target.value,
            errored: false
        });
    };

    doLogin = async () => {
        if (window.navigator && !window.navigator.onLine) {
            this.props.rootStore!.snackbarStore.triggerSnackbar('Cannot log in when the application is offline');
            return ;
        }
        const setLoginProgress = this.props.rootStore!.appStore.setLoginProgress;

        let error = false;
        const api = this.props.rootStore!.api;
        this.setState({loading: true});
        setLoginProgress('Logging in...', 0);
        try {
            let userName = this.state.user || localStorage.getItem('user') || '';
            await api.Session.logIn(userName, this.state.password);
            this.props.rootStore!.appStore.needsSoftLogin = false;
            await this.props.rootStore!.appStore.initialize(true);
            this.props.rootStore!.routerStore.push('/home');
        } catch (err) {
            error = true;
            setLoginProgress('An error occurred...', 100);
            this.setState({errored: true});
        } finally {
            if (!error) {
                setLoginProgress('Logged in.', 100);
            }
            this.setState({loading: false});
        }
    };

    doSSOLogin = async () => {
        const api = this.props.rootStore!.api;
        const features = await api.Session.getFeatures();
        if (features && features.EpochConfigLoginMode === LoginMode.EAGER_SSO_FALLBACK) {
            try {
                await api.Session.silentSSOLogin(features);
            } catch (e) {
                this.props.rootStore!.snackbarStore.triggerSnackbar('SSO Login failed');
            }
        }
        this.props.rootStore!.routerStore.push('/home');
    };

    onKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
        if (evt.key === 'Enter' && this.state.user.length > 0 && this.state.password.length > 0) {
            evt.preventDefault();
            evt.stopPropagation();
            this.doLogin();
        }
    };

    // componentDidMount = async () => {
    //     const api = this.props.rootStore!.api;
    //     let search = window.location.search;
    //     let parameters: { code?: string } = {};
    //     search
    //         .substr(1)
    //         .split('&')
    //         .forEach((entry: string) => {
    //             var eq = entry.indexOf('=');
    //             if (eq >= 0) {
    //                 parameters[
    //                     decodeURIComponent(entry.slice(0, eq))
    //                     ] = decodeURIComponent(entry.slice(eq + 1));
    //             }
    //         });
    //     let error = false;
    //     if (parameters.code && parameters.code !== 'fail') {
    //         this.setState({loading: true});
    //         this.props.rootStore!.appStore.setLoginProgress('Logging in...', 0);
    //         try {
    //             await api.Session.ssoLogin(parameters.code);
    //             await this.props.rootStore!.appStore.initialize(true);
    //         } catch (err) {
    //             error = true;
    //             this.props.rootStore!.appStore.setLoginProgress('An error occurred.', 100);
    //             this.setState({errored: true});
    //             this.setState({loading: false});
    //         } finally {
    //             if (!error) {
    //                 this.props.rootStore!.appStore.setLoginProgress('Logged in.', 100);
    //             }
    //             window
    //                 .history
    //                 .replaceState({}, document.title, removeUrlParameter(window.location.href, 'code')
    //                     .replace('?', ''));
    //             this.setState({loading: false});
    //         }
    //     }
    //
    // };

    attemptHardLogout = async () => {
        if (Platform.isElectron()) {
            const sessionAPI: SessionImpl = this.props.rootStore!.api.Session as SessionImpl;
            const offlineEntries = await sessionAPI.getAllOfflineEntries();
            if (
                offlineEntries && (
                    offlineEntries.Templates.length > 0 ||
                    offlineEntries.TimeEntries.length > 0 ||
                    offlineEntries.TimerChunks.size > 0 ||
                    offlineEntries.Timers.length > 0
                )
            ) {
                this.setState({ confirmHardLogoutDialog: true })
                return;
            }
        }

        this.doHardLogout()
    };

    doHardLogout = () => {
        localStorage.removeItem('user');
        this.props.rootStore!.appStore.needsSoftLogin = false;
        this.props.rootStore!.appStore.logOut();
    }

    acceptHardLogout = () => {
        this.doHardLogout()
        this.setState({
            confirmHardLogoutDialog: false
        })
    };

    stopHardLogout = () => {
        this.setState({
            confirmHardLogoutDialog: false
        })
    };

    render() {
        const {user, password, loading, errored} = this.state;
        const forcedUser = this.props.rootStore!.appStore.needsSoftLogin && localStorage.getItem('user') || '';
        const allowLogin = ((forcedUser.length > 0 || user.length > 0) && password.length > 0);
        const ssoFallback = this.props.rootStore!.appStore.ssoFallback;

        if (loading) {
            return <Spinner />;
        }

        return (
            <Styled.LoginContainer id="login-container">
                <FlexDiv flex={1}/>

                <Styled.LoginBox elevation={10} square={true}>
                    <Styled.LogoContainer>
                        <img src={svgLogo} height="80px"/>
                    </Styled.LogoContainer>
                    <Styled.FieldCont>
                        <TextField
                            name="username"
                            value={forcedUser || user}
                            onChange={this.setUser}
                            onKeyDown={this.onKeyDown}
                            label="Username"
                            error={errored}
                            helperText={forcedUser ? 'Your session has expired.' : undefined}
                            disabled={!!forcedUser}
                            fullWidth={true}
                            InputProps={forcedUser ? {
                                endAdornment: <InputAdornment position="end">
                                    <Tooltip title="Log out">
                                        <IconButton onClick={this.attemptHardLogout}>
                                            <Clear/>
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            } : undefined}
                        />
                    </Styled.FieldCont>
                    <Styled.FieldCont>
                        <TextField
                            name="password"
                            onChange={this.setPass}
                            onKeyDown={this.onKeyDown}
                            type="password"
                            value={password}
                            label="Password"
                            error={errored}
                            fullWidth={true}
                        />
                    </Styled.FieldCont>

                    {errored && <Styled.InvalidCred>
                        <Typography style={{color: theme.error}}>
                            Invalid username or password
                        </Typography>
                    </Styled.InvalidCred>}

                    {ssoFallback && <Styled.ButtonContainer>
                        <Button
                            name="login"
                            onClick={this.doSSOLogin}
                            color="primary"
                            fullWidth={true}
                            variant="contained"
                        >
                            SSO Login
                        </Button>
                    </Styled.ButtonContainer>
                    }

                    <Styled.ButtonContainer>
                        <Button
                            name="login"
                            onClick={this.doLogin}
                            disabled={!allowLogin}
                            color="primary"
                            fullWidth={true}
                            variant="contained"
                        >
                            Login
                        </Button>
                    </Styled.ButtonContainer>
                </Styled.LoginBox>
                <div style={{height: '100px'}}/>
                <FlexDiv flex={1}/>
                <Dialog open={this.state.confirmHardLogoutDialog}>
                    <DialogTitle>
                        <Warning/> Warning
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to log out? There are pending changes which will be lost if you log out.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.acceptHardLogout}>Yes</Button>
                        <Button onClick={this.stopHardLogout}>No</Button>
                    </DialogActions>
                </Dialog>
            </Styled.LoginContainer>
        );
    }
}
