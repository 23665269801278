import { action, computed, observable, makeObservable } from 'mobx';
import { Narrative } from 'api/types/types';
import BaseStore from 'store/base.store';
import { removeListItem, setListItem } from 'util/array';
import { RootStore } from './root.store';
import { sortByAlphaNumeric } from '../util/utils';
import Mutex from '../api/util';
import { debounce } from 'typescript-debounce-decorator';
import logger from '../logging/logging';

export default class NarrativeStore extends BaseStore {
    @observable narratives: Narrative[] = [];
    @observable selectedNarrative: Narrative;
    @observable searchText: string = '';
    @observable dirty: boolean = false;
    @observable invalid: Record<string, string | boolean> = {};
    originalNarrative: Narrative;
    errGlobalDupTxt: string = '';
    @observable loading: boolean = false;
    @observable currentOffset: number = 0;
    caughtEmAll: boolean = false;
    loadMutex = new Mutex();
    limit: number = 50;
    allNarratives: Narrative[] = [];
    fetchingNarratives: boolean = false;

    handlerDestructor: () => void;
    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this);
        this.initializeHandler();
    }
    initializeHandler = () => {
        this.handlerDestructor = this.rootStore.api.Narrative.registerReciever(this.recieveNarratives)
    }

    @computed get maxNarrativeLength() {
        return this.rootStore!.appStore!.features!.EpochConfigNarrativesMaximumChars;
    }

    @action recieveNarratives = (narratives: Narrative[]) => {
        narratives.forEach(n => {
            if (n.deleted) {
                this.narratives = removeListItem(this.narratives, n.id!);
                this.resetInvalid();
                if (this.selectedNarrative && this.selectedNarrative.id === n.id) {
                    this.selectedNarrative = new Narrative();
                    this.originalNarrative = Object.assign(new Narrative(), this.selectedNarrative);
                    this.dirty = false;
                    this.rootStore.routerStore.push(`/narratives/new`);
                }
                return;
            }
            if (this.searchTextInclude(n.key) || this.searchTextInclude(n.replacement)) {
                // TODO: investigate the root cause problem for duplication entries
                const idx = this.narratives.findIndex(nar => nar.key === n.key && nar.id !== n.id);
                const localNarratives = this.narratives.filter(nar => !nar.global);
                const isAllLocalNarrativesLoaded = localNarratives.length < this.narratives.length;
                const lastLoadedNarrative = localNarratives.length > 0 ? localNarratives[localNarratives.length - 1] : null;
                // check if narrative not in list AND (all local narratives loaded OR  before last narrative in list) "fix for Desktop"
                if (idx === -1 && (isAllLocalNarrativesLoaded || this.caughtEmAll || n.key.localeCompare(lastLoadedNarrative!.key) < 0)) {
                    let newLocal = this.narratives.slice();
                    setListItem(newLocal, n);
                    this.narratives = newLocal;
                    this.sortNarratives();
                }
            } else {
                this.narratives = removeListItem(this.narratives, n.id!);
            }
        })
        if (this.currentOffset === 0) {
            this.narratives = [];
        }
    }

    @action loadNarrative = async (id: number) => {
        this.selectedNarrative = await this.rootStore.api.Narrative.getNarrative(id);
        this.originalNarrative = Object.assign(new Narrative(), this.selectedNarrative);
    };

    @action changeSelectedNarrative = async (id: number) => {
        try {
        if (!this.selectedNarrative || id !== this.selectedNarrative.id) {
            const allowed = await this.rootStore.routerStore.attemptPush(`/narratives/${id}`);
        
            const narrative = this.narratives.find(n => n.id === id);
            if (narrative && allowed) {
                this.resetInvalid();
                this.selectedNarrative = narrative.clone();
                this.originalNarrative = narrative.clone();
            }
        }
        } catch (e) {
            logger.error('Narratives, Error in changing selected narrative', e);
        }
    };

    @action newNarrative = async () => {
        const notDirty = await this.rootStore.routerStore.attemptPush(`/narratives/new`);
        if (notDirty) {
            this.resetInvalid();
            this.selectedNarrative = new Narrative();
            this.originalNarrative = Object.assign(new Narrative(), this.selectedNarrative);
        }
        this.dirty = false;
    };

    @action onChange = (n: Narrative) => {
        this.selectedNarrative = Object.assign(new Narrative(), n);
        this.dirty = true;
    };

    @action deleteNarrative = async (id: number) => {
        try {
        const idx = this.narratives.findIndex(t => t.id === id);
        if (idx > -1) {
            const allowedToNavigate = await this.rootStore.routerStore.attemptPush(`/narratives/new`);
            
            if (allowedToNavigate) {
                const confirmDelete = await this.confirm('Confirm Delete');
                if (!confirmDelete) { return };

                // second part of condition for new add entries without id.
                const index = this.allNarratives.findIndex(t => t.id === id || t.key === this.narratives[idx].key);
                this.narratives[idx].deleted = true;
                await this.rootStore.api.Narrative.saveNarrative(this.narratives[idx]);
                this.narratives.splice(idx, 1);
                if (index > -1) {
                    this.allNarratives.splice(index, 1);
                }
                // decrement offset because items in backend shifted and next api call would miss next top item
                this.currentOffset--;
                this.newNarrative();
            }
        }
        
        this.rootStore.snackbarStore.triggerSnackbar('Deleted Successfully');
        } catch (e) {
            logger.error('Narratives, Error deleting narrative', e);
        }
    };
    
    @action addNarrative = async (n: Narrative, isRedirect: boolean) => {
        this.dirty = false;

        let resp: Narrative;

        try {
            resp = await this.rootStore.api.Narrative.saveNarrative(n);
        } catch (e) {
            return;
        }

        const newNarrative = resp;
        this.allNarratives.unshift(newNarrative.clone());
        if (isRedirect) {
            if (this.searchTextInclude(n.key) || this.searchTextInclude(n.replacement)) {
                const localNarratives = this.narratives.filter(nar => !nar.global);
                const isAllLocalNarrativesLoaded = localNarratives.length < this.narratives.length;
                const lastLoadedNarrative = localNarratives.length > 0 ? localNarratives[localNarratives.length - 1] : null;
                // check if all local narratives loaded OR narrative key is before last local narrative in the list
                if (isAllLocalNarrativesLoaded || this.caughtEmAll || n.key.localeCompare(lastLoadedNarrative!.key) < 0) {
                    this.narratives.unshift(newNarrative.clone());
                    this.sortNarratives();
                    // increment offset because last item is shifted in backend, to prevent duplicate occurance
                    this.currentOffset++;
                }
            }
            this.selectedNarrative = newNarrative.clone();
            this.rootStore.routerStore.push(`/narratives/${newNarrative.id}`);
        }

        this.selectedNarrative = new Narrative();
        this.originalNarrative = new Narrative();
        
        this.rootStore.snackbarStore.triggerSnackbar('Saved Successfully');
    }

    @action saveNarrative = async (n: Narrative) => {
        const idx = this.narratives.findIndex(nar => nar.id === n.id);
        let resp: Narrative;
        n.replacement = n.replacement.trim();
        this.loading = true;
        try {
            resp = await this.rootStore.api.Narrative.saveNarrative(n);
        } catch (e) {
            return;
        }
        const newNarrative = resp;
        // second part of condition for new add entries without id.
        const index = this.allNarratives.findIndex(nar => nar.id === n.id || nar.key === this.narratives[idx].key);
        if (idx > -1) {
            this.narratives.splice(idx, 1);
        } else {
            this.selectedNarrative = newNarrative.clone();
            this.rootStore.routerStore.push(`/narratives/${newNarrative.id}`);
        }
        this.narratives.unshift(newNarrative.clone());
        this.sortNarratives();
        if (index > -1) {
            this.allNarratives.splice(index, 1);
        }
        this.allNarratives.unshift(newNarrative.clone());

        this.selectedNarrative = new Narrative()
        this.originalNarrative = newNarrative.clone();
        this.dirty = false;
        this.loading = false;
        this.rootStore.routerStore.push(`/narratives/new`);
        this.rootStore.snackbarStore.triggerSnackbar('Saved Successfully');
    };

    searchTextInclude = (s: string) => s.toLowerCase().includes(this.searchText.toLowerCase());

    @action restoreNarrative = () => {
        this.selectedNarrative = Object.assign(new Narrative(), this.originalNarrative);
        this.resetInvalid();
        this.dirty = false;
    };

    @action onSearchChange = (input: string) => {
        this.searchText = input;
        this.selectedNarrative = new Narrative();
        this.debounceOnSearchChange(input);
    };

    @debounce(500, {leading: false})
    async debounceOnSearchChange(input: string) {
        this.reset();
        this.fetchMoreAvailNarratives();
    };

    @action isKeyDuplicate = (key: string): boolean => {
        this.errGlobalDupTxt = '';
        let globalAndUserCodes = this.allNarratives
            .filter(g => key.toLowerCase() !== this.originalNarrative.key.toLowerCase() 
                && g.key.toLowerCase() === key.toLowerCase()
            );
        if (globalAndUserCodes.length > 0) {
            const allGlobals: boolean = globalAndUserCodes.every((gl) => gl.global);
            if (allGlobals) {
                const globalIdx = globalAndUserCodes.find((gn) => gn.global);
                if (globalIdx) {
                    this.errGlobalDupTxt = '\\' + globalIdx.key + ' will override existing global narrative';
                }
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    @action isKeyGlobalDuplicate = (key: string) => {
        this.invalid.key = this.isKeyDuplicate(key) ? false : this.errGlobalDupTxt;
    }

    searchAvailableNarratives = async (offset?: number, limit?: number, search?: string) => {
        try {
            return await this.rootStore.api.Narrative.getAllNarratives(offset, limit, search);
        } catch (e) {
            return [];
        }
    }

    fetchMoreAvailNarratives = async () => {
        if (this.caughtEmAll) {
            return;
        }
        await this.loadMutex.execute(async () => {
            const newElems = await this.searchAvailableNarratives(this.currentOffset, this.limit, this.searchText);
            this.currentOffset = this.currentOffset + newElems.length;
            if (newElems.length < this.limit) {
                this.caughtEmAll = true;
            }
            this.narratives = this.narratives.concat(newElems);
            this.sortNarratives();
        })
    }

    fetchAllNarratives = async () => {
        this.fetchingNarratives = true;
        this.allNarratives = await this.searchAvailableNarratives();
        this.fetchingNarratives = false;
    }

    reset = () => {
        this.currentOffset = 0;
        this.narratives = [];
        this.caughtEmAll = false;
    }

    sortNarratives = () => {
        // Local Narratives must be always on top
        const localNarratives = (this.narratives.filter(n => !n.global))
            .sort((a, b) => a.key.localeCompare(b.key));
        const globalNarratives = (this.narratives.filter(n => n.global))
            .sort((a, b) => a.key.localeCompare(b.key));

        this.narratives = [...localNarratives, ...globalNarratives];
    }

    @action resetInvalid() {
        this.invalid = { 'key': false, 'replacement': false };
    }

    @action validateNarrative(n: Narrative) {
        this.invalid.replacement = n.replacement.trim() === '' ? 'Required field' : 
                                   n.replacement.trim().length > this.maxNarrativeLength ? 
                                   `Narrative cannot exceed ${this.maxNarrativeLength} characters` : false;
        this.invalid.key = 
            n.key === '' ? 'Required field' :
                n.key.length < 2 ? 'Narrative Code cannot be less than 2 characters' :
            (n.key.indexOf(' ') > -1 ? 'No spaces allowed' :
            this.fetchingNarratives ? 'Narratives not fully loaded. Try again' :
            (this.isKeyDuplicate(n.key) ? 'Duplicate code' : false))
        return !this.invalid.replacement && !this.invalid.key;
    }
};