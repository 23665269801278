import ImmutableTimeEntry from './ImmutableTimeEntry';
import ImmutableTemplate from './ImmutableTemplate';
import { DateTime } from 'luxon';
import { getStringMatches, getStringMatchesForBBWords } from 'util/utils';
import { Features, TimeKeeperAssignment } from '../types/types';

export class ValidationState {
    missing: {
        matter: boolean;
        phase: boolean;
        task: boolean;
        activity: boolean;
        action: boolean;
        ffTask: boolean;
        ffAct: boolean;
        workLocale: boolean;
    };
    narrativeBannedWords: string[];
    matterStatusInvalid: boolean;
    matterEntryTypeInvalid: boolean;
    narrativeBlockBillingWords: string[];
    zeroDuration: boolean;
    narrativeLength: boolean;
    twentyFourDuration: boolean;
    billingRuleInvalid: boolean;
    maxNarrativeLength: boolean;
    invalidWorkDate: boolean;
    isReferenceEmpty: boolean;
    narrativeMinLength: number;
    narrativeMaxLength: number;
    get valid(): boolean {
        return (this.narrativeBannedWords.length === 0) &&
        (this.narrativeBlockBillingWords.length === 0)  &&
        !this.twentyFourDuration &&
        !this.narrativeLength &&
        !this.maxNarrativeLength &&
        !this.zeroDuration &&
        !this.matterStatusInvalid &&
        !this.matterEntryTypeInvalid &&
        !this.billingRuleInvalid &&
        !this.missing.matter &&
        !this.missing.phase &&
        !this.missing.task &&
        !this.missing.activity &&
        !this.missing.action &&
        !this.missing.ffAct &&
        !this.missing.ffTask &&
        !this.missing.workLocale &&
        !this.invalidWorkDate &&
        !this.isReferenceEmpty
    }
    get duration(): boolean {
        return this.zeroDuration || this.twentyFourDuration;
    }
    clone = (): ValidationState => {
        return Object.assign(new ValidationState(),
            JSON.parse(JSON.stringify(this))
        );
    }
}

export function ValidateSave(
    timeEntry: ImmutableTimeEntry,
    exclusiveTimeTotal: number,
    features: Features,
    actTk: TimeKeeperAssignment | undefined,
    narrativeMinLength?: number | undefined,
    narrativeMaxLength?: number | undefined
): ValidationState {
    let vstate = {
        missing: {
            matter: false,
            phase: false,
            task: false,
            activity: false,
            action: false,
            ffTask: false,
            ffAct: false
        },
        narrativeLength: false,
        maxNarrativeLength: false,
        matterStatusInvalid: false,
        matterEntryTypeInvalid: false,
        twentyFourDuration: false,
        narrativeBannedWords: [] as string[],
        narrativeBlockBillingWords: [] as string[],
        zeroDuration: false,
        invalidWorkDate: false,
        isReferenceEmpty: false,
        narrativeMinLength: (narrativeMinLength || features.EpochConfigNarrativesMinimumChars),
        narrativeMaxLength: (narrativeMaxLength || features.EpochConfigNarrativesMaximumChars)
    // tslint:disable-next-line:no-any
    };
    if (!timeEntry.matterId) {
        if ((timeEntry.narrative || '').trim().length < features.EpochConfigNarrativesMinimumChars) {
            vstate.narrativeLength = true;
        }
    }
    if ((timeEntry.duration + exclusiveTimeTotal) > 24 * 60 * 60 ) {
        vstate.twentyFourDuration = true;
    }
    if (features.EpochConfigNarrativesMaximumChars !== -1 &&
        (timeEntry.narrative || '').length > features.EpochConfigNarrativesMaximumChars) {
        vstate.maxNarrativeLength = true;
    }
    if ((DateTime.fromISO(timeEntry.workDateTime) < DateTime.fromISO(timeEntry.matterStartDate!)) ||
        (!actTk)) {
        vstate.invalidWorkDate = true;
    }
    if (!timeEntry.reference && !timeEntry.matterId &&
        features.EpochConfigReferenceRequired) {
        vstate.isReferenceEmpty = true;
    }
    return Object.assign(new ValidationState(), vstate);
}

export function ValidatePost(
    timeEntry: ImmutableTimeEntry,
    exclusiveTimeTotal: number,
    matterStatusDesc: string,
    matterEntryType: string,
    features: Features,
    actTk: TimeKeeperAssignment | undefined,
    narrativeMinLength: number | undefined,
    narrativeMaxLength: number | undefined
    ): ValidationState {
        const INVALID_MATTER_STATUSES = ['STOP', 'HOLD', 'DCLN', 'HCLS', 'SCLS', 'BUDG'];
        let vstate = {
            missing: {
                matter: false,
                phase: false,
                task: false,
                activity: false,
                action: false,
                ffTask: false,
                ffAct: false,
                workLocale: false
            },
            narrativeLength: false,
            maxNarrativeLength: false,
            matterStatusInvalid: false,
            matterEntryTypeInvalid: false,
            twentyFourDuration: false,
            narrativeBannedWords: [] as string[],
            narrativeBlockBillingWords: [] as string[],
            zeroDuration: false,
            billingRuleInvalid: false,
            invalidWorkDate: false,
            narrativeMinLength: (narrativeMinLength || features.EpochConfigNarrativesMinimumChars),
            narrativeMaxLength: (narrativeMaxLength || features.EpochConfigNarrativesMaximumChars)
        };
        if (!timeEntry.matterId) {
            vstate.missing.matter = true;
        } else {
            // has matter, lets keep going
            // validate matter status
            if (INVALID_MATTER_STATUSES.includes(matterStatusDesc)) {
                vstate.matterStatusInvalid = true;
            }
            if (matterEntryType === '02') { // matter entry type code '02' means Cost only
                vstate.matterEntryTypeInvalid = true;
            }
            if ((DateTime.fromISO(timeEntry.workDateTime) < DateTime.fromISO(timeEntry.matterStartDate!)) ||
                (!actTk)) {
                vstate.invalidWorkDate = true;
            }
            if (timeEntry.bannedWords) {
                vstate.narrativeBannedWords = getStringMatches(timeEntry.narrative || '', timeEntry.bannedWords);
            }
            if (timeEntry.blockBillingWords) {
                vstate.narrativeBlockBillingWords = getStringMatchesForBBWords(
                    timeEntry.narrative || '', timeEntry.blockBillingWords);
            }
            if (timeEntry.isPhaseCode) {
                if (!timeEntry.phaseId) {
                    vstate.missing.phase = true;
                }
                if (!timeEntry.taskCodeId) {
                    vstate.missing.task = true;
                }
            }
            if (timeEntry.isActCode) {
                if (!timeEntry.actCodeId) {
                    vstate.missing.activity = true;
                }
            }
            if (features.EpochConfigFlatFeeCodesEnabled) {
                if (timeEntry.isFfTaskCode) {
                    if (!timeEntry.ffTaskCodeId) {
                        vstate.missing.ffTask = true;
                    }
                    if (!timeEntry.ffActCodeId) {
                        vstate.missing.ffAct = true;
                    }
                }
            }
        }
        if (features.EpochConfigActionCodesRequired) {
            if (!timeEntry.actionCodeId) {
                vstate.missing.action = true;
            }
        }
        if (!timeEntry.duration || timeEntry.duration === 0) {
            vstate.zeroDuration = true;
        }
        if ((timeEntry.duration + exclusiveTimeTotal) > 24 * 60 * 60 ) {
            vstate.twentyFourDuration = true;
        }
        if ((timeEntry.narrative || '').trim().length < vstate.narrativeMinLength) {
            vstate.narrativeLength = true;
        }
        if (features.EpochConfigNarrativesMaximumChars !== -1 &&
            (timeEntry.narrative || '').trim().length > vstate.narrativeMaxLength) {
            vstate.maxNarrativeLength = true;
        }
        if (timeEntry.stopEntry) {
            vstate.billingRuleInvalid = true;
        }
        if (features.EpochConfigWorkLocaleEnabled) {
            if (!timeEntry.workLocaleId) {
                vstate.missing.workLocale = true;
            }
        }
        return Object.assign(new ValidationState(), vstate);
}

export class ValidationTemplateState {
    duplicateName: boolean;
    emptyName: boolean;
    narrativeMaxLength: boolean;
    noMatter: boolean;
    get valid(): boolean {
        return !this.duplicateName &&
        !this.emptyName &&
        !this.narrativeMaxLength &&
        !this.noMatter
    }
}

export function ValidateTemplate(
    template: ImmutableTemplate,
    templates: ImmutableTemplate[],
    narrativeMax: number
): ValidationTemplateState {
    let vtstate = {
        duplicateName: false,
        emptyName: false,
        narrativeMaxLength: false,
        noMatter: false
    };
    if ((template.narrative || '').trim().length > narrativeMax) {
        vtstate.narrativeMaxLength = true;
    }
    if (!template || !template.name || (template.name.trim().length === 0)) {
        vtstate.emptyName = true
    }
    if (!template || !template.matter) {
        vtstate.noMatter = true
    }
    let duplicates = templates.filter((t) => {
        if (t.id === template.id) {
            return false;
        }
        return (t.name || '').trim().toUpperCase() === (template.name || '').trim().toUpperCase()
    })
    if (duplicates.length > 0) {
        vtstate.duplicateName = true;
    }
    return Object.assign(new ValidationTemplateState(), vtstate);
}
