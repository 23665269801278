import React, { useEffect } from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import {
    MergedTimeCastSegmentComponent,
    MergedTimeCastSegmentProps
} from '../../containers/Home/MergedTimeCastSegmentComponent';
import { TimeCastSegmentGap, TimeCastSegmentGroup } from 'store/home.store';
import { getEmptyImage } from 'react-dnd-html5-backend';
import ImmutableTimeEntry from '../../api/immutables/ImmutableTimeEntry';
import { TimerChunk } from '../../api/types/types';

interface Props extends MergedTimeCastSegmentProps {
    selectedSegmentsToDrag: number;
    onDrop: (timeEntry: ImmutableTimeEntry) => Promise<void>;
    style?: React.CSSProperties;
}

export interface DragObjectType extends MergedTimeCastSegmentProps {
    type: DragItemTypes.ELEMENT_TYPE,
    selectedSegmentsToDrag: number
}

export enum DragItemTypes {
    ELEMENT_TYPE = 'TIMECAST'
}

const SegmentDragSource = (props: Props) => {
    const { isSelected } = props;
    
    const [{ isDragging }, drag, connectDragPreview] = useDrag({
        item: { ...props,
            type: DragItemTypes.ELEMENT_TYPE
        },
        end: (item: DragObjectType | undefined,
              monitor: DragSourceMonitor) => {
            
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                const timeEntryObj = Object.assign(new ImmutableTimeEntry(), dropResult);
                props.onDrop(timeEntryObj);
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    })
    useEffect(() => {
        if (connectDragPreview) {
            connectDragPreview(getEmptyImage(), {
                // IE fallback: specify that we'd rather screenshot the node
                // when it already knows it's being dragged so we can hide it with CSS.
                captureDraggingState: true,
            })
        }
    })
    const dragRef = isSelected ? drag : undefined;
    const { style } = props;
    
    return (
        <div style={style} ref={dragRef}>
            <MergedTimeCastSegmentComponent
                {...props}
            />
        </div>
    )
}

export default SegmentDragSource