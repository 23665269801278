import { action, observable, makeObservable } from 'mobx';

export default class SnackbarStore {
    @observable snackbarOpen: boolean = false;
    snackbarMessage: string = '';
    isError: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action triggerSnackbar = (msg: string) => {
        this.snackbarMessage = msg;
        this.snackbarOpen = true;
    }

    @action closeSnackbar = () => {
        this.snackbarMessage = '';
        this.snackbarOpen = false;
        this.isError = false;
    }
}
