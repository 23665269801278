import * as React from 'react';
import * as Styled from './styled';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { 
    Dialog, 
    DialogContent, 
    DialogActions, 
    Button, 
    Checkbox
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EULADialogStore from 'store/eula.dialog.store';

interface Props {
    store?: EULADialogStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        store: rootStore.eulaDialogStore
    };
})
@observer
export default class EULADialog extends React.Component<Props> {

    state = {
        checked: false
    }

    handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ['checked']: evt.target.checked });
    };
    handleEulaVisibility = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.props.store!.setHideEula(evt.target.checked);
    }
    render() {
        const { isOpen, cancel, eulaText, saveEulaAcceptance } = this.props.store!;
        
        return (
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={isOpen}
                onClose={cancel}
                scroll={'paper'}
                maxWidth={'md'}
                aria-labelledby="scroll-dialog-title"
                id="eula-dialog"
            >
                <DialogContent>
                    <Styled.Paragraph 
                        dangerouslySetInnerHTML={{__html: eulaText.replace(/(\r\n|\n|\r)/gm, '<br />')}} 
                    />
                    <div style={{margin: '0 auto', display: 'table'}}>
                        <Styled.CheckboxContainer>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.checked}
                                        onChange={this.handleChange}
                                        value={'checked'}
                                    />
                                }
                                label={'I agree to terms of confidentiality.'}
                            />
                        </Styled.CheckboxContainer>
                        <Styled.CheckboxContainer>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.store!.hideEula}
                                        onChange={this.handleEulaVisibility}
                                        value={'hideEula'}
                                    />
                                }
                                label={'Do not ask again.'}
                            />
                        </Styled.CheckboxContainer>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancel} color="primary">
                        DECLINE
                    </Button>
                    <Button disabled={!this.state.checked} onClick={saveEulaAcceptance} color="primary">
                        AGREE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}