import * as React from 'react';
import * as Styled from './styled.desktop';
import { Paper, List, Input, IconButton, Snackbar, Tooltip } from '@material-ui/core';
import { Add, Delete, Search } from '@material-ui/icons';
import TemplateStore from 'store/template.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { Route } from 'react-router';
import TemplateFormContainer from './TemplateFormContainer';
import Template from 'api/immutables/ImmutableTemplate';
import { TimeKeeperAssignment } from '../../api/types/types';
import { TKConsumer } from 'common/TKProvider';
interface Props {
    rootStore?: RootStore;
    templateStore?: TemplateStore;
    match: {
        url: string;
    };
}

class ListItemLink extends React.Component<{
    primary: string, 
    onClick: () => void,
    selected?: boolean,
    onDelete: () => void,
}> {
    render() {
        const { primary } = this.props;
        return (
            <Styled.CustomListItem 
                button={true}
                onClick={this.props.onClick}
                selected={this.props.selected}
            >
                <Styled.ListItemContent>
                    <Styled.ListText 
                        primary={primary}
                        primaryTypographyProps={{
                            style: {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'calc(100% - 40px)'
                            }
                        }}
                    />
                    <TKConsumer>
                        { (tk: TimeKeeperAssignment) =>
                            tk.writable &&
                            <Styled.CustomSecondaryAction>
                                <Tooltip title="Delete">
                                    <IconButton onClick={this.props.onDelete}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Styled.CustomSecondaryAction>}
                    </TKConsumer>
                </Styled.ListItemContent>
            </Styled.CustomListItem>
        );
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        rootStore: rootStore,
        templateStore: rootStore.templateStore
    };
})
@observer
export default class Templates extends React.Component<Props> {
    
    componentDidMount() {
        this.props.templateStore!.loadTemplates();
        this.props.templateStore!.newTemplate();
    }

    componentWillUnmount() {
        this.props.templateStore!.onSearchChange('');
    }
    
    render() {
        const {
            filteredNames, 
            selectedTemplate, 
            changeSelectedTemplate,
            onSearchChange,
            deleteTemplate,
            newTemplate,
            searchText
        } = this.props.templateStore!;
        const { match } = this.props;
        return (
            <Styled.Container>
                <Styled.Header>
                    <Styled.SearchContainer>
                        <Search
                            style={{ marginRight: '6px' }}
                        />
                        <Input
                            placeholder="Search"
                            fullWidth={true}
                            style={{ maxWidth: '60%' }}
                            value={searchText}
                            onChange={e => onSearchChange(e.target.value)}
                        />
                    </Styled.SearchContainer>

                    <Styled.Title variant="h6">
                        Templates
                    </Styled.Title>
                    <TKConsumer>
                        { (tk: TimeKeeperAssignment) =>
                            <div style={{ flex: '1' }}>
                                {  tk.writable &&
                                <Tooltip title="New Template">
                                    <Styled.New onClick={_ => { newTemplate() }}>
                                        <Add />
                                    </Styled.New>
                                </Tooltip>
                                }
                            </div>}
                    </TKConsumer>
                </Styled.Header>

                <Styled.ContentContainer>
                    <Styled.ListContainer square={true}>
                        <List>
                            {filteredNames.map((tmp: Template) => {
                                return (
                                    <ListItemLink
                                        key={tmp.id!.toString()}
                                        primary={tmp.name}
                                        onClick={() => changeSelectedTemplate(tmp.id)}
                                        selected={selectedTemplate && selectedTemplate.id === tmp.id}
                                        onDelete={() => deleteTemplate(tmp.id)}
                                    />
                                );
                            })}
                        </List>
                    </Styled.ListContainer>
                    <Styled.FormContainer>
                        <Paper square={true} style={{ padding: '10px' }}>
                            <Route 
                                path={`${match.url}/:id`} 
                                component={TemplateFormContainer}
                            />
                        </Paper>
                    </Styled.FormContainer>
                </Styled.ContentContainer>
            </Styled.Container>
        );
    }
}