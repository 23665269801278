import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import AppStore from 'store/app.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import HardLogoutStore from 'store/hardLogout.store';
import NavigationStore from 'store/navigation.store';

interface Props {
    appStore?: AppStore;
    hardLogoutStore?: HardLogoutStore;
    routerStore?: NavigationStore;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        hardLogoutStore: rootStore.hardLogoutStore,
        appStore: rootStore.appStore,
        routerStore: rootStore.routerStore
    };
})
@observer
export default class HardLogoutDialog extends React.Component<Props> {
    
    acceptHardLogout = () => {
        this.props.hardLogoutStore!.cancel();
        this.props.appStore!.doHardLogout();
    };

    stopHardLogout = () => {
        this.props.hardLogoutStore!.cancel();
        this.props.routerStore!.attemptPush(`/settings/offlineEntries`)
    };
    render() {
        const { isOpen, message } = this.props.hardLogoutStore!;
        return(
            <Dialog open={isOpen}>
                <DialogTitle>
                    <Warning/> Warning
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to {message}? There are pending changes which will be lost if you {message}.
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.acceptHardLogout}>{message === 'log out' ? 'Logout' : 'Re-Sync'}</Button>
                    <Button onClick={this.stopHardLogout}>View pending changes</Button>
                </DialogActions>
            </Dialog>
        )
    }
}