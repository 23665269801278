import React from 'react'
import { CalendarEventData } from '../../api/types/types'
import { DateRange } from '@material-ui/icons'

interface Props {
    data: CalendarEventData
}

export const CalendarEventDescription = (props: Props) => {
    return (
        <>
            <DateRange style={{fontSize: '13px'}}/>
            &nbsp;{props.data.subject}&nbsp;
            <span style={{fontSize: '10px'}}>
                ({props.data.myMeetingResponse})
            </span>
        </>
    )
}