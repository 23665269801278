import { ActionCode, Client, Code, CodeSetTemplate, Matter, MatterTypeText, TimeEntryType } from 'api/types/types';
import ImmutableBaseEntry from './ImmutableBaseEntry';
import ImmutableTemplateEntry from './ImmutableTemplate';
import { DateTime } from 'luxon';

export enum SapStatus {
    QUEUED = 'QUEUED',
    UNSUBMITTED = 'UNSUBMITTED',
    SUBMITTED = 'SUBMITTED',
    POSTED = 'POSTED',
    NEW = 'NEW'
}
export default class ImmutableTimeEntry extends ImmutableBaseEntry {
    office?: string;
    officeName?: string
    duration: number; // seconds
    actualDuration: number;
    workDateTime: string;
    sapStatus: SapStatus;
    reference: string | null;
    workLocation?: string | null;
    collaborateTks: string;
    collaborateInfo: string;
    timeEntryType: TimeEntryType;

    static durationToHours(duration: number): number {
        return duration / 3600;
    }
    
    clone = (): ImmutableTimeEntry => {
        return Object.assign(new ImmutableTimeEntry(),
            JSON.parse(JSON.stringify(this))
        );
    };

    setNarrative(narrative: string): ImmutableTimeEntry {
        let newObj = this.clone();
        let newEntry = newObj;
        newEntry.dirty = true
        newEntry.narrative = narrative;
        return newEntry;
    }

    setMatter = (matter?: Matter | null): ImmutableTimeEntry => {
        let entry = ImmutableBaseEntry.applyMatter<ImmutableTimeEntry>(this.clone(), matter)
            .setDuration(this.actualDuration)
            .setReference(null)
            .setCodeSet(null);
        if (matter === null) {
            entry = entry
                .setPhase(null)
                .setFFTask(null)
                .setAct(null)
        }
        return entry;
    };
    
    setReference = (reference: string | null): ImmutableTimeEntry => {
        let result = this.clone();
        result.reference = reference;
        result.dirty = true;
        return result;
    }

    setClient = (client?: Client | null): ImmutableTimeEntry => {
        return ImmutableBaseEntry.applyClient<ImmutableTimeEntry>(this.clone(), client)
            .setMatter(null);
    };

    setWorkLocaleId = (workLocaleId?: number | null): ImmutableTimeEntry => {
        return ImmutableBaseEntry.applyWorkLocaleId<ImmutableTimeEntry>(this.clone(), workLocaleId);
    }

    setPhase = (code?: Code | null): ImmutableTimeEntry => {
        return ImmutableBaseEntry
            .applyPhase<ImmutableTimeEntry>(this.clone(), code)
            .setTask(null);
        // todo 
    };

    setTask = (code?: Code | null): ImmutableTimeEntry => {
        return ImmutableBaseEntry.applyTask<ImmutableTimeEntry>(this.clone(), code);
    };

    setAct = (code?: Code | null): ImmutableTimeEntry => {
        return ImmutableBaseEntry.applyActivity<ImmutableTimeEntry>(this.clone(), code);
    };

    setFFTask = (code?: Code | null): ImmutableTimeEntry => {
        return ImmutableBaseEntry
            .applyFFTask<ImmutableTimeEntry>(this.clone(), code)
            .setFFAct(null);
    };

    setFFAct = (code?: Code | null): ImmutableTimeEntry => {
        return ImmutableBaseEntry.applyFFActivity<ImmutableTimeEntry>(this.clone(), code);
    };

    setActionCode = (actionCode?: ActionCode | null): ImmutableTimeEntry => {
        return ImmutableBaseEntry.applyActionCode<ImmutableTimeEntry>(this.clone(), actionCode);
    }
    
    setCodeSet = (cs: CodeSetTemplate | null | undefined): ImmutableTimeEntry => {
        return ImmutableBaseEntry.applyCodeSetTemplate <ImmutableTimeEntry>(this.clone(), cs);
    }

    setDuration(dur: number) {
        let roundingInterval = this.roundingInterval;
        let entry = this.clone();
        entry.actualDuration = dur;
        if ( dur || dur === 0 ) {
            entry.duration = Math.ceil(dur / roundingInterval) * roundingInterval;
        }
        entry.dirty = true;
        return entry;
    }
    
    setWorkDate(date: DateTime) {
        let entry = this.clone();
        entry.workDateTime = date.toISO();
        entry.dirty = true;
        return entry;
    }
    
    setPosted = (): ImmutableTimeEntry => {
        return this.setStatus(SapStatus.QUEUED);
    }
    
    setStatus = (status: SapStatus): ImmutableTimeEntry => {
        let entry = this.clone();
        entry.sapStatus = status;
        entry.dirty = true;
        return entry;
    }
    setOffice = (office?: string): ImmutableTimeEntry => {
        let entry = this.clone();
        entry.office = office;
        entry.dirty = true;
        return entry;
    }
    setOfficeName = (officeName?: string): ImmutableTimeEntry => {
        let entry = this.clone();
        entry.officeName = officeName;
        entry.dirty = true;
        return entry;
    }
    
    setBillingLanguage = (key: string): ImmutableTimeEntry => {
        let entry = this.clone();
        entry.matterBillingLang = key;
        return entry;
    }
    loadFromTemplate = (template: ImmutableTemplateEntry) => {
        let entry = this.clone();
        entry = entry.setMatter(template.matter);
        entry.timeKeeperId = template.timeKeeperId;
        entry.matterId = template.matterId
        entry.isActCode = template.isActCode;
        entry.isFfTaskCode = template.isFfTaskCode;
        entry.isPhaseCode = template.isPhaseCode;
        if (entry.isPhaseCode) {
            entry.phaseId = template.phaseId;
            entry.taskCodeId = template.taskCodeId;
            entry.phaseName = template.phaseName;
            entry.phaseDesc = template.phaseDesc;
            entry.taskCode = template.taskCode;
            entry.taskCodeDesc = template.taskCodeDesc;
        } else {
            entry.phaseId = null;
            entry.taskCodeId = null;
            entry.phaseName = null;
            entry.phaseDesc = null;
            entry.taskCode = null;
            entry.taskCodeDesc = null;
        }
        if (entry.isFfTaskCode) {
            entry.ffTaskCodeId = template.ffTaskCodeId;
            entry.ffActCodeId = template.ffActCodeId;
            entry.ffTaskCode = template.ffTaskCode;
            entry.ffTaskCodeDesc = template.ffTaskCodeDesc;
            entry.ffActCode = template.ffActCode;
            entry.ffActCodeDesc = template.ffActCodeDesc;
        } else {
            entry.ffTaskCodeId = null;
            entry.ffActCodeId = null;
            entry.ffTaskCode = null;
            entry.ffTaskCodeDesc = null;
            entry.ffActCode = null;
            entry.ffActCodeDesc = null;
        }
        if (entry.isActCode) {
            entry.actCodeId = template.actCodeId;
            entry.actCode = template.actCode;
            entry.actCodeDesc = template.actCodeDesc;
        } else {
            entry.actCodeId = null;
            entry.actCode = null;
            entry.actCodeDesc = null;
        }
        entry.narrative = template.narrative;
        entry.createdOn = template.createdOn;
        entry.deleted = template.deleted;
        entry.lastModified = template.lastModified;
        entry.actionCodeId = template.actionCodeId;
        entry.actionCode = template.actionCode;
        entry.bannedWords = template.bannedWords;
        entry.matterNumber = template.matterNumber;
        entry.matterName = template.matterName;
        entry.matterDescription = template.matterDescription;
        entry.clientId = template.clientId;
        entry.clientNumber = template.clientNumber;
        entry.clientName = template.clientName;
        entry.matterTypeText = template.matterTypeText;
        entry.billingLang = template.billingLang;
        entry.billingLangText = template.billingLangText;
        entry.timeEntryUnit = template.timeEntryUnit;
        entry.blockBillingWords = template.blockBillingWords;
        entry.entryType = template.entryType;
        entry.matterStatus = template.matterStatus;
        entry.matterStatusDesc = template.matterStatusDesc;
        entry.matterStartDate = template.matterStartDate;
        entry.matterEndDate = template.matterEndDate;
        entry.actionResponse = template.actionResponse;
        entry.stopEntry = template.stopEntry;
        return entry;
    }
    
    createTemplate = (): ImmutableTemplateEntry => {
        let template = new ImmutableTemplateEntry();
        template.timeKeeperId = this.timeKeeperId;
        template.matterId = this.matterId
        template.phaseId = this.phaseId;
        template.taskCodeId = this.taskCodeId;
        template.actCodeId = this.actCodeId;
        template.ffTaskCodeId = this.ffTaskCodeId;
        template.ffActCodeId = this.ffActCodeId;
        template.narrative = this.narrative;
        template.createdOn = this.createdOn;
        template.deleted = this.deleted;
        template.lastModified = this.lastModified;
        template.actionCodeId = this.actionCodeId;
        template.actionCode = this.actionCode;
        template.bannedWords = this.bannedWords;
        template.matterNumber = this.matterNumber;
        template.matterName = this.matterName;
        template.matterDescription = this.matterDescription;
        template.clientId = this.clientId;
        template.clientNumber = this.clientNumber;
        template.clientName = this.clientName;
        template.matterTypeText = this.matterTypeText;
        template.billingLang = this.billingLang;
        template.billingLangText = this.billingLangText;
        template.timeEntryUnit = this.timeEntryUnit;
        template.blockBillingWords = this.blockBillingWords;
        template.entryType = this.entryType;
        template.matterStatus = this.matterStatus;
        template.matterStatusDesc = this.matterStatusDesc;
        template.matterStartDate = this.matterStartDate;
        template.matterEndDate = this.matterEndDate;
        template.phaseName = this.phaseName;
        template.phaseDesc = this.phaseDesc;
        template.taskCode = this.taskCode;
        template.taskCodeDesc = this.taskCodeDesc;
        template.actCode = this.actCode;
        template.actCodeDesc = this.actCodeDesc;
        template.ffTaskCode = this.ffTaskCode;
        template.ffTaskCodeDesc = this.ffTaskCodeDesc;
        template.ffActCode = this.ffActCode;
        template.ffActCodeDesc = this.ffActCodeDesc;
        template.isActCode = this.isActCode;
        template.isFfTaskCode = this.isFfTaskCode;
        template.isPhaseCode = this.isPhaseCode;
        return template;
    }

    isPosted(): boolean {
        return !this.isDraft();
    }

    isDraft(): boolean {
        if (this.sapStatus === SapStatus.UNSUBMITTED || 
            this.sapStatus === SapStatus.NEW) {
            return true;
        }
        return false;
    }

    isBillable(): boolean {
        return this.matterTypeText ? (this.matterTypeText.toUpperCase() === MatterTypeText.BILLABLE) : false;
    }

    isNonBillable(): boolean {
        return this.matterTypeText ? (this.matterTypeText.toUpperCase() === MatterTypeText.NON_BILLABLE) : false;
    }
    
    isSplitable(): boolean {
        return !this.isPosted() && 
            this.duration >= 2 * this.roundingInterval &&
            this.matter !== null;
    }
    
    get roundingInterval(): number {
        if (this.timeEntryUnit === 'Z2') {
            return 900;
        } else if (this.timeEntryUnit === 'Z3') {
            return 180;
        }
        return 360;
    }
    toWriteable = () => {
        return {
            office: this.office,
            officeName: this.officeName,
            duration: this.duration,
            actualDuration: this.actualDuration,
            workDateTime: this.workDateTime,
            sapStatus: this.sapStatus,
            reference: this.reference,
            workLocation: this.workLocation,
            id: this.id,
            timeKeeperId: this.timeKeeperId,
            matterId: this.matterId,
            phaseId: this.phaseId,
            taskCodeId: this.taskCodeId,
            actCodeId: this.actCodeId,
            ffTaskCodeId: this.ffTaskCodeId,
            ffActCodeId: this.ffActCodeId,
            narrative: this.narrative,
            createdOn: this.createdOn,
            deleted: this.deleted,
            lastModified: this.lastModified,
            actionCodeId: this.actionCodeId,
            actionCode: this.actionCode,
            matterNumber: this.matterNumber,
            matterName: this.matterName,
            matterDescription: this.matterDescription,
            clientId: this.clientId,
            clientNumber: this.clientNumber,
            clientName: this.clientName,
            matterTypeText: this.matterTypeText,
            billingLang: this.billingLang,
            billingLangText: this.billingLangText,
            timeEntryUnit: this.timeEntryUnit,
            matterStatus: this.matterStatus,
            matterStatusDesc: this.matterStatusDesc,
            matterStartDate: this.matterStartDate,
            matterEndDate: this.matterEndDate,
            phaseName: this.phaseName,
            phaseDesc: this.phaseDesc,
            taskCode: this.taskCode,
            taskCodeDesc: this.taskCodeDesc,
            actCode: this.actCode,
            actCodeDesc: this.actCodeDesc,
            ffTaskCode: this.ffTaskCode,
            ffTaskCodeDesc: this.ffTaskCodeDesc,
            ffActCode: this.ffActCode,
            ffActCodeDesc: this.ffActCodeDesc,
            actionResponse: this.actionResponse,
            isPhaseCode: this.isPhaseCode,
            isActCode: this.isActCode,
            isFfTaskCode: this.isFfTaskCode,
            timeEntryType: this.timeEntryType ? this.timeEntryType : TimeEntryType.NORMAL,
            collaborateTks: this.collaborateTks ? this.collaborateTks : '',
            collaborateInfo: this.collaborateInfo ? this.collaborateInfo : '',
            workLocaleId: this.workLocaleId,
            guid: this.guid
        }
    }
    get matterBillingLangDictionary(): string {
        return this.matterBillingLang ? this.matterBillingLang : this.billingLang ? this.billingLang : 'EN';
    }
}