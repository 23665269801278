import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField, Tooltip
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import NarrativeDialogStore from 'store/narrative.dialog.store';
import { Cancel, Save } from '@material-ui/icons';
import { NarrativeField } from 'components/NarrativeField';
import { Focusable } from '@fulcrumgt/react-fulcrum-editor/types/FulcrumEditor';
import { Features } from '../../api/types/types';
import { FeaturesConsumer } from 'common/FeaturesProvider';

interface Props {
    narrativeDialogStore?: NarrativeDialogStore;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        narrativeDialogStore: rootStore.narrativeDialogStore
    };
})
@observer
export default class NavTimerNarrativeDialog extends React.Component<Props> {
    render() {
        let {
            isOpen,
            setJustOpened,
            justOpened,
            cancel,
            doSave,
            timerNote,
            setNote
        } = this.props.narrativeDialogStore!;
        return (
            <FeaturesConsumer>
                {(features: Features) =>
                    <>
            <Dialog
                open={isOpen}
                onClose={cancel}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="narrative-dialog-title">Narrative for Active Timer</DialogTitle>
                <DialogContent>
                    <NarrativeField
                        style={{minWidth: '312px', maxHeight: '160px', overflow: 'auto'}}
                        value={timerNote}
                        placeholder="Narrative"
                        onChange={setNote}
                        inputRef={(inputRef: Focusable) => {
                            if (justOpened) {
                                requestAnimationFrame(() => inputRef.focusAtEnd());
                                setJustOpened(false);
                            }
                        }}
                        onEnter={() => {
                            doSave();
                            return true;
                        }}
                        maxLength={features.EpochConfigNarrativesMaximumChars}
                    />
                </DialogContent>
                <DialogActions>
                    <Tooltip title={'Click or press ENTER to save'}>
                        <Button onClick={doSave}>
                            Save
                        </Button>
                    </Tooltip>
                    <Button onClick={cancel}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
                    </>
                }
            </FeaturesConsumer>
        );
    }
}