import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import ConfirmStore from 'store/confirm.store';

interface Props {
    message?: string;
    isOpen?: boolean;
    cancel?: () => void;
    verify?: () => void;
    ConfirmationDialogStore?: ConfirmStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        ConfirmationDialogStore: rootStore.confirmStore
    };
})
@observer
export default class ConfirmationDialog extends React.Component<Props> {
    render() {
        let {
            isOpen,
            message,
            cancel,
            verify
        } = this.props.ConfirmationDialogStore!;
        return (
            <Dialog
                open={isOpen}
                onClose={cancel}
                disableRestoreFocus={true}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent id="unsaved-dialog-title">{message}</DialogContent>
                <DialogActions>
                    <Button onClick={cancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={verify} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}