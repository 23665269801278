import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormHelperText,
    TextField
} from '@material-ui/core';
import SplitentryDialogStore from 'store/splitentry.dialog.store';
import * as Styled from './styled';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import DurationField from 'components/DurationField/DurationField';
import { MatterItemFormatter } from 'components/TemplateForm/TemplateForm';
import { Matter, Features } from '../../api/types/types';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import { FeaturesConsumer } from 'common/FeaturesProvider';

interface Props {
    dialogStore?: SplitentryDialogStore;
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        dialogStore: rootStore.splitEntryDialogStore
    };
})
@observer
export default class SplitEntryDialog extends React.Component<Props> {
    
    changeSplitNum = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.dialogStore!.setSplitNumber(Number(event.target.value));
    }
    
    setDuration = (index: number) => (duration: number) => {
        duration = (duration < 0) ? 0 : duration;
        let entries = this.props.dialogStore!.splitEntries.slice();
        let oldEntry = entries[index];
        oldEntry.actualDuration = oldEntry.duration;
        let entry = this.props.dialogStore!.entry.setMatter(oldEntry.matter);
        entry = entry.setDuration(duration);
        entries[index] = {
            duration: entry.duration,
            matter: oldEntry.matter,
            actualDuration: entry.actualDuration,
            timeEntryUnit: entry.timeEntryUnit
        };
        this.props.dialogStore!.setEntries(entries);
    }
    
    setMatter = (index: number) => async(m: Matter | null) => {
        let entries = this.props.dialogStore!.splitEntries.slice();
        let oldEntry = entries[index];
        if (m) {
            if (!m.tracked) {
                await this.props.dialogStore!.rootStore.api.Matter.track([m.id])
            }
            m = await this.props.dialogStore!.rootStore.api.Matter.get(m.id)
        }
        let entry  = this.props.dialogStore!.entry.setMatter(m);
        entry = entry.setDuration(oldEntry.actualDuration);
        entries[index] = {
            duration: entry.duration,
            matter: entry.matter,
            actualDuration: entry.actualDuration,
            timeEntryUnit: entry.timeEntryUnit
        };
        this.props.dialogStore!.setEntries(entries);
    }

    increment = (timeEntryUnit?: string | null) => {
        if (timeEntryUnit === 'Z2') {
            return 0.25;
        } else if (timeEntryUnit === 'Z3') {
            return 0.05;
        }
        return 0.1;
    }
    
    render() {
        let {
            isOpen,
            cancel,
            entry,
            numSplit,
            splitEntries,
            unequalDuration,
            savable,
            saving,
            wrappedSplitSave,
            tooSmallIntervals,
            filledEntries,
            invalidDuration,
            invalidTotal
        } = this.props.dialogStore!;
        return (
            <FeaturesConsumer>
            { (features: Features) =>
                <Dialog
                    open={isOpen}
                    onClose={cancel}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle>Split Entry</DialogTitle>
                    <Styled.DialogContent>
                        <Styled.OriginalEntry>
                            <div>
                                {entry && entry.matter ? `${entry.matter!.number} - ${entry.matter!.name}` : ''}
                            </div>
                            <div>
                                {entry ? `${(entry.duration / 3600).toFixed(2)}` : ''}
                            </div>
                        </Styled.OriginalEntry>
                        <div>
                            <TextField
                                label="Split Into"
                                value={numSplit}
                                type="number"
                                onChange={this.changeSplitNum}
                                inputProps={{
                                    min: 2
                                }}
                            />
                        </div>
                        {tooSmallIntervals && <FormHelperText error={true}>
                            {`Cannot split into fewer than ${entry ? entry.roundingInterval / 60 : 0} minute intervals`}
                        </FormHelperText>}
                        {numSplit === 1 && <FormHelperText error={true}>
                            {`Cannot split into less than 2 entries`}
                        </FormHelperText>}
                        <ApiConsumer>
                            { (api: RootAPI) =>
                        <Styled.Entries>
                            {splitEntries.map(({duration, matter, timeEntryUnit}, index) =>
                                <Styled.EntryInput key={index}>
                                    <AutoCompleteField
                                        label={features.EpochConfigMatterLabel}
                                        fetch={(searchText: string) =>
                                            api.Matter.searchMatters(searchText, true)}
                                        currentItem={matter}
                                        clearable={true}
                                        onClear={() => this.setMatter(index)(null)}
                                        formatItem={MatterItemFormatter}
                                        getItemText={(m: Matter) => `${m.number} - ${m.name}`}
                                        onSelect={this.setMatter(index)}
                                    />
                                    <DurationField
                                        duration={duration}
                                        increment={this.increment(timeEntryUnit)}
                                        onChange={this.setDuration(index)}
                                        error={invalidDuration}
                                        errorText={invalidDuration ? 'Cannot exceed 24 hours.' : ''}
                                    />
                                </Styled.EntryInput>
                            )}
                        </Styled.Entries>}
                        </ApiConsumer>
                        {unequalDuration && !tooSmallIntervals && <FormHelperText error={true}>
                            Warning: Total duration is not equal to actual duration
                        </FormHelperText>}
                        {!filledEntries && <FormHelperText error={true}>
                            {`All new entries must have a valid ${features.EpochConfigMatterLabel}`}
                        </FormHelperText>}
                        {invalidTotal && <FormHelperText error={true}>
                            Total duration cannot exceed 24 hours.
                        </FormHelperText>}
                    </Styled.DialogContent>
                    <DialogActions>
                        <Button
                            disabled={!savable || saving}
                            onClick={wrappedSplitSave}
                        >
                            Save
                        </Button>
                        <Button onClick={cancel}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            }
            </FeaturesConsumer>
        );
    }
}