import React from 'react'
import { TimeCastSegmentGroup } from 'store/home.store';
import { DeprecatedDesktopCaptureData, DesktopCaptureData } from '../../api/types/types';
import {
    IconDefinition,
    faFileWord,
    faFilePowerpoint,
    faFileExcel,
    faEnvelope,
    faDatabase,
    faFileInvoice,
    faUpload,
    faFilePdf,
    faFileAlt,
    faFile,
    faCalendarDay, 
    faPaperPlane, 
    faPhoneAlt
} from '@fortawesome/free-solid-svg-icons';
import {
    faFirefox,
    faChrome, faSkype, faEdge, faInternetExplorer
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface Props {
    group: TimeCastSegmentGroup;
    color?: string
}

const programIcons: Record<string, IconDefinition> = {
    'MS_WORD' : faFileWord,
    'MS_POWERPOINT': faFilePowerpoint,
    'MS_PUBLISH': faFileInvoice,
    'MS_EXCEL': faFileExcel,
    'MS_OUTLOOK': faEnvelope,
    'MS_ACCESS': faDatabase,
    'OFFICE_UPLOADCTR': faUpload,
    'MOZILLA_FIREFOX': faFirefox,
    'GOOGLE_CHROME': faChrome,
    'MS_LYNC': faSkype,
    'MS_LYNC_RECORDER': faSkype,
    'MS_EDGE': faEdge,
    'ADOBE_ACROBAT': faFilePdf,
    'MS_IEXPLORER': faInternetExplorer,
    'WS_DECK_COMPARE': faFileAlt,
    'WS_COMPARE': faFileAlt,
    'WS_CARBON': faFileAlt,
    'WS_PDF_COMBINE': faFileAlt,
    'DOCSCORP_CMPRDOCS': faFile,
    
    // dummy programs
    'PHONE_CALL' : faPhoneAlt,
    'CALENDAR_EVENT': faCalendarDay,
    'SENT_MAIL': faPaperPlane,
    'MS_EDGE_NEW': faChrome
}

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`

export const DesktopCaptureIcon = (props: {color: string, program?: string}) => {
    const icon = programIcons[props.program || '']
    
    return icon
        ? <Icon icon={icon} style={{color: props.color}} />
        : (
            <div
                style={{
                    display: 'inline-block',
                    width: '13px',
                    height: '13px',
                    backgroundColor: `${props.color || '#999'}`,
                    marginRight: '10px',
                    border: '1px solid #999'
                }}
            />
        )
}

export const DesktopCaptureDescription = (props: Props) => {
    const group = props.group;

    return (
        <>
            {props.color && <DesktopCaptureIcon color={props.color} program={props.group.application} />}
            {group.segments.length > 0
                ? ((group.segments[group.segments.length - 1].data as DesktopCaptureData).title 
                || (group.segments[group.segments.length - 1].data as DeprecatedDesktopCaptureData).windowTitle)
                : group.title}
        </>
    )
};