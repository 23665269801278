import styled from 'common/styled-components';
import { Paper, Typography } from '@material-ui/core';

export const MainWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
export const Header = styled.div`
    height: 64px;
    background-color: ${props => props.theme.primary.main};
    display: flex;
    align-items: center;
`;
export const Title = styled(Typography)`
    flex: 1;
    text-align: center;
`;
export const WrapperContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;  
`;
export const ListContainer = styled(Paper)`
    flex: 1;
`;
export const PageContainer = styled.div`
    flex: 3;
    margin: 10px;
    overflow: hidden;
`;
export const ListItemContainer = styled.div`
    &:hover {
        background-color: #D3D3D3;
        cursor: pointer;
    }
`;