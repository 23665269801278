import { SapStatus } from './ImmutableTimeEntry';
import { TimerChunk } from 'api/types/types';

export default class ImmutableTimer {
    id?: number;
    timeKeeperId: number;
    templateId?: number;
    matterId?: number;
    name: string;
    active?: boolean;
    deleted?: boolean;
    startedOn?: string;
    startedTimezone?: string;
    notes?: string;
    favorite: boolean;
    totalDuration: number;
    pendingDuration: number;
    convertedDuration: number;
    dirty?: boolean;
    lastModified: string;
    lastActive: string;
    chunks: TimerChunk[];
    localUid?: string | null;
    toWriteable = () => {
        return {
            id: this.id,
            timeKeeperId: this.timeKeeperId,
            templateId: this.templateId,
            matterId: this.matterId,
            active: this.active,
            startedOn: this.startedOn,
            startedTimezone: this.startedTimezone,
            notes: this.notes,
            name: this.name,
            favorite: this.favorite,
            deleted: this.deleted,
            lastActive: this.lastActive,
            localUid: this.localUid
        }
    }
    
    clone = (): ImmutableTimer => {
        return Object.assign(new ImmutableTimer(), {
            id: this.id,
            timeKeeperId: this.timeKeeperId,
            templateId: this.templateId,
            matterId: this.matterId,
            name: this.name,
            active: this.active,
            deleted: this.deleted,
            startedOn: this.startedOn,
            startedTimezone: this.startedTimezone,
            notes: this.notes,
            favorite: this.favorite,
            totalDuration: this.totalDuration,
            pendingDuration: this.pendingDuration,
            convertedDuration: this.convertedDuration,
            lastModified: this.lastModified,
            chunks: this.chunks,
            lastActive: this.lastActive
        })
    }

    setNote = (note: string): ImmutableTimer => {
        let newt = this.clone();
        newt.notes = note;
        return newt;
    }
}