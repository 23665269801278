import * as React from 'react';
import * as Styled from './styled';
import { FormHelperText, MenuItem, TextField, Select } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from 'api/interfaces/RootAPI';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import { ActionCode, Client, Code, CodeSetFlags, CodeSetTemplate, CodeType, Features, Matter, TimeKeeperAssignment,
    TkOfficeOfficeName, WorkLocale } from 'api/types/types';
import DurationField from 'components/DurationField';
import { NarrativeField } from 'components/NarrativeField';
import { DateTime } from 'luxon';
import { MatterItemFormatter } from 'components/TemplateForm/TemplateForm';
import { InlineDatePicker } from 'material-ui-pickers';
import { FlexDiv } from 'common/flex';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { ValidationState } from 'api/immutables/validators';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { parseCode } from '../../util/utils';
import { isoDate, getDateFormat } from '../../util/date';
import { LinearProgressBar } from 'components/LoadingSpinner/LinearProgressBar';
import { TKConsumer } from 'common/TKProvider';
import rootStore from 'store/root.store';

interface Props {
    timeEntry: TimeEntry;
    onChange: (t: TimeEntry, newVState?: ValidationState, durErrState?: boolean) => void;
    disabled?: boolean;
    showDate?: boolean;
    minHeight?: number;
    durValidationState?: boolean;
    validationState?: ValidationState;
    minNarrativeLength: number;
    maxNarrativeLength: number;
    actionCodesRequired: boolean;
    includeNarrative?: boolean;
    includeDuration?: boolean;
    resetCollaborators?: () => void;
    onSetFieldLoader?: (value: boolean) => void;
    tkOfficesToShow: TkOfficeOfficeName[];
}
interface State {
    setFieldLoader: boolean;
}
export default class TimeEntryForm extends React.Component<Props, State> {
    static defaultProps = {
        includeDuration: true,
        includeNarrative: true
    }
    state = {
        setFieldLoader: false
    }
    setMatter = (api: RootAPI) => async (m?: Matter | null) => {
        if (this.props.resetCollaborators ) { this.props.resetCollaborators(); }
        let newVState,
            entry = this.props.timeEntry;
        if (m) {
            this.props.onSetFieldLoader!(true)
            this.setState({
                setFieldLoader: true
            });
            this.props.onChange(entry.setMatter(m));
            if (!m.tracked) {
               await api.Matter.track([m.id]);
            }
            const codeSetFlags: CodeSetFlags = await api.Code.determineCodeSetFields(m.id, entry.workDateTime);
            m.isPhaseCode = codeSetFlags.isPhaseCode;
            m.isFfTaskCode = codeSetFlags.isFfTaskCode;
            m.isActCode = codeSetFlags.isActCode;
            if (this.props.validationState) {
                newVState = this.props.validationState!.clone();
                newVState.missing.matter = false;
            }
            if (codeSetFlags.phases.length === 1) {
                let phase = codeSetFlags.phases[0];
                entry = entry.setPhase(phase);
                let tasks = await api.Code.getTaskCodes(phase.id, entry.workDateTime, '');
                if (tasks.length === 1) {
                    let task = tasks[0];
                    entry = entry.setTask(task);
                }
                if (newVState) {
                    newVState.missing.phase = false;
                    newVState.missing.task = false;
                }
            }
            
            if (codeSetFlags.ffTasks.length === 1) {
                let ffTask = codeSetFlags.ffTasks[0];
                entry = entry.setFFTask(ffTask);
                let ffActs = await api.Code.getFFActCodes(ffTask.id, entry.workDateTime, '');
                if (ffActs.length === 1) {
                    let ffAct = ffActs[0];
                    entry = entry.setFFAct(ffAct);
                }
                if (newVState) {
                    newVState.missing.ffTask = false;
                    newVState.missing.ffAct = false;
                }
            }
            
            if (codeSetFlags.activities.length === 1) {
                let act = codeSetFlags.activities[0];
                entry = entry.setAct(act);
                if (newVState) {
                    newVState.missing.activity = false;
                }
            }
            // Call API only when Action Code is already selected.
            if (this.props.timeEntry.actionCodeObj) {
                let actionObj = await api.Code.getActionCodes( m!.id, this.props.timeEntry.actionCode);
                entry = entry.setActionCode(actionObj[0]);
            }
        }
        entry = entry.setNarrative(this.props.timeEntry.narrative!)
        entry = entry.setDuration(this.props.timeEntry.duration!)
        
        this.props.onChange(entry.setMatter(m), newVState);
        this.props.onSetFieldLoader!(false)
        this.setState({
            setFieldLoader: false
        });
    }
    
    setNarrative = (text: string) => {
        let newVState;
        let entry = this.props.timeEntry.setNarrative(text);
        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.narrativeBannedWords = [];
            newVState.narrativeBlockBillingWords = [];
            newVState.narrativeLength = false;
            if (text.length >= this.props.minNarrativeLength) {
                newVState.narrativeLength = false;
            }
            if (text.length <= this.props.maxNarrativeLength) {
                newVState.maxNarrativeLength = false;
            }
        }
        this.props.onChange(entry, newVState);
    }
    
    setClient = (c?: Client | null) => {
        let entry = this.props.timeEntry.setClient(c);
        this.props.onChange(entry);
    }

    setWorkLocale = (w?: WorkLocale | null) => {
        let newVState;
        let entry = this.props.timeEntry.setWorkLocaleId(w ? w!.id : null);
        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.missing.workLocale = false;
        }
        this.props.onChange(entry, newVState);
    }

    getWorkLocaleValue = (entry: TimeEntry) => {
        return rootStore.timeEntryStore.allWorkLocales!.find(x => x.id === entry.workLocaleId)
    }
    
    setPhase = (api: RootAPI) => async (c?: Code | null) => {
        let newVState;
        let entry = this.props.timeEntry.setPhase(c);
        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.missing.phase = false;
        }
        
        if (c) {
            let tasks = await api.Code.getTaskCodes(c.id, entry.workDateTime, '');
            if (tasks.length === 1) {
                let task = tasks[0];
                entry = entry.setTask(task);
            }
            if (newVState) {
                newVState.missing.task = false;
            }
        }
        this.props.onChange(entry, newVState);
    }
    
    setTask = (c?: Code | null) => {
        let newVState;
        let entry = this.props.timeEntry.setTask(c);
        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.missing.task = false;
        }
        this.props.onChange(entry, newVState);
    }
    setAct = (c?: Code | null) => {
        let newVState;
        let entry = this.props.timeEntry.setAct(c);
        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.missing.activity = false;
        }
        this.props.onChange(entry, newVState);
    }
    
    setFFTask = (api: RootAPI) => async (c?: Code | null) => {
        let newVState;
        let entry = this.props.timeEntry.setFFTask(c);
        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.missing.ffTask = false;
        }
        if (c) {
            let ffActs = await api.Code.getFFActCodes(c.id, entry.workDateTime, '');
            if (ffActs.length === 1) {
                let ffAct = ffActs[0];
                entry = entry.setFFAct(ffAct);
            }
            if (newVState) {
                newVState.missing.ffAct = false;
            }
        }
        this.props.onChange(entry, newVState);
    }
    
    setFFAct = (c?: Code | null) => {
        let newVState;
        let entry = this.props.timeEntry.setFFAct(c);
        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.missing.ffAct = false;
        }
        this.props.onChange(entry, newVState);
    }
    setActionCode = (c?: ActionCode | null) => {
        let newVState;
        let entry = this.props.timeEntry.setActionCode(c);
        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            if (c) {
                newVState.narrativeBannedWords = [];
                newVState.narrativeBlockBillingWords = [];
                newVState.missing.action = false;
                if (c!.actionText.length >= this.props.minNarrativeLength) {
                    newVState.narrativeLength = false;
                }
                if (c!.actionText.length <= this.props.maxNarrativeLength) {
                    newVState.maxNarrativeLength = false;
                }
            }
        }
        this.props.onChange(entry, newVState);
    }
    setDuration = (dur: number, err?: boolean) => {
        let newVState;
        dur = (dur < 0) ? 0 : dur;
        let entry = this.props.timeEntry.setDuration(dur);

        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.zeroDuration = false;
            newVState.twentyFourDuration = false;
        }
        this.props.onChange(entry, newVState, err);
    }
    
    setWorkDate = (d: Date) => {
        const date: DateTime = DateTime.fromJSDate(d);
        let entry = this.props.timeEntry.setWorkDate(date);
        this.props.onChange(entry);
    }

    setCodeSetTemplate = (api: RootAPI) => async (codeTemp?: CodeSetTemplate | null) => {
        let newVState;
        let entry = this.props.timeEntry.setCodeSet(codeTemp);
        
        if (this.props.validationState) {
            newVState = this.props.validationState!.clone();
            newVState.narrativeBannedWords = [];
            newVState.narrativeBlockBillingWords = [];
            
            if (codeTemp && codeTemp.codeSetNarrative.length >= this.props.minNarrativeLength) {
                newVState.narrativeLength = false;
            }
            if (codeTemp && codeTemp.phaseId) {
                newVState.missing.phase = false;
            }
            if (codeTemp && codeTemp.taskCodeId) {
                newVState.missing.task = false;
            }
            if (codeTemp && codeTemp.actCodeId) {
                newVState.missing.activity = false;
            }
            if (codeTemp && codeTemp.actionCodeId) {
                newVState.missing.action = false;
            }
        }
        if (codeTemp) {
            if (codeTemp.phaseId) {
                let phaseObj: Code = {
                    id: codeTemp.phaseId,
                    name: codeTemp.phaseName,
                    description: codeTemp.phaseDesc,
                    type: CodeType.PHASE
                }
                entry = entry.setPhase(phaseObj);
            }
            if (codeTemp.taskCodeId) {
                let taskObj: Code = {
                    id: codeTemp.taskCodeId,
                    name: codeTemp.taskCode,
                    description: codeTemp.taskCodeDesc,
                    type: CodeType.TASK
                }
                entry = entry.setTask(taskObj);
            }
            if (codeTemp.actCodeId) {
                let actObj: Code = {
                    id: codeTemp.actCodeId,
                    name: codeTemp.actCode,
                    description: codeTemp.actCodeDesc,
                    type: CodeType.ACT
                }
                entry = entry.setAct(actObj);
            }
            if (codeTemp.actionCode) {
                let actionObj = await api.Code.getActionCodes(entry.matterId!, codeTemp.actionCode);
                entry = entry.setActionCode(actionObj[0]);
            }
            if (codeTemp.codeSetNarrative) {
                entry = entry.setNarrative(codeTemp.codeSetNarrative);
            }
        }
        this.props.onChange(entry, newVState);
    }

    changeNarrativeLanguage = (key: string) => {
        let entry = this.props.timeEntry.setBillingLanguage(key);
        this.props.onChange(entry);
    }

    handleOfficeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let tempOfficeName = this.props.tkOfficesToShow.filter(x => x.office === event.target.value)[0].officeName
        // Changing both office and office name here
        let entry = this.props.timeEntry.setOffice(event.target.value).setOfficeName(tempOfficeName)
        this.props.onChange(entry)
    }

    fetchPhaseCodes = (api: RootAPI) => async (entry: TimeEntry, searchText?: string) => {
        let workDateTime = entry.workDateTime;
        let results = await api.Code.getPhaseCodes(entry.matterId!, workDateTime, searchText);
        return results;
    }
    fetchTaskCodes = (api: RootAPI) => async (entry: TimeEntry, searchText?: string) => {
        let workDateTime = entry.workDateTime;
        let results = await api.Code.getTaskCodes(entry.phaseId!, workDateTime, searchText);
        return results;
    }
    fetchActivityCodes = (api: RootAPI) => async (entry: TimeEntry, searchText?: string) => {
        let workDateTime = entry.workDateTime;
        let results = await api.Code.getActivityCodes(entry.matterId!, workDateTime, searchText);
        return results;
    }
    fetchFFTaskCodes = (api: RootAPI) => async (entry: TimeEntry, searchText?: string) => {
        let workDateTime = entry.workDateTime;
        let results = await api.Code.getFFTaskCodes(entry.matterId!, workDateTime, searchText);
        return results;
    }
    fetchFFActCodes = (api: RootAPI) => async (entry: TimeEntry, searchText?: string) => {
        let workDateTime = entry.workDateTime;
        let results = await api.Code.getFFActCodes(entry.ffTaskCodeId!, workDateTime, searchText);
        return results;
    }
    fetchMatters = (api: RootAPI) => async (entry: TimeEntry, features: Features, searchText: string) => {
        let  results: Matter[] = []
        if (features.EpochConfigTrackedMatterClientsEnabled) { 
            return await api.Matter.searchMatters(
                searchText, true, entry.clientId!,
                DateTime.fromISO(entry.workDateTime).toISODate(),
                0,
                200
            );
        } else {
            results = await api.Matter.searchMatters(
                searchText, true, entry.clientId!,
                DateTime.fromISO(entry.workDateTime).toISODate(),
                0,
                200
            );
            if (results.length === 0) {
                results = await api.Matter.getAvailableMatters(
                    searchText,
                    false,
                    entry.clientId!,
                    DateTime.fromISO(entry.workDateTime).toISODate(),
                    0,
                    200
                )
            }
        }
        return results
    }
    getMatterTooltipText = (m: Matter) => {
        const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
        if (matterLabel !== 'Job') {
            return m.description;
        }
        const firstLine = `${matterLabel} MD: ${m.billingPartnerName || ''}`;
        const secondLine = `${matterLabel} Manager: ${m.billingManagerName || ''}`;
        const thirdLine = `${matterLabel} Office: ${m.billingOffice || ''}`;
        const forthLine = m.description;
        return `${firstLine}\n${secondLine}\n${thirdLine}\n${forthLine}`;
    }
    
    render() {
        const {
            timeEntry,
            disabled,
            showDate,
            includeNarrative,
            includeDuration,
            durValidationState,
            validationState,
            tkOfficesToShow
        } = this.props;
        let conflictingBannedWords = [] as string[];
        let conflictingBlockBillingWords = [] as string[];
        let durErrTxt = '';
        let matterStatusErrText: string;
        if (validationState) {
            conflictingBannedWords = validationState.narrativeBannedWords;
            conflictingBlockBillingWords = validationState.narrativeBlockBillingWords;
            if (validationState.twentyFourDuration) {
                durErrTxt = 'Cannot exceed 24 hours.';
            } 
            if (validationState.zeroDuration) {
                durErrTxt = 'Duration must be > 0';
            }
        }
        if (durValidationState) {
            durErrTxt = 'Invalid';
        }
        let bwText = (conflictingBannedWords.length > 0) ? 
        `${conflictingBannedWords.join(', ')}` : '';
        let bbText = (conflictingBlockBillingWords.length > 0) 
        ? `${conflictingBlockBillingWords.join(', ')}` : '';
        let narBwBbWords = bwText ? `${bwText}` + (bbText && ', ') + `${bbText}` : `${bbText}`
        let narHelperText = (bwText || bbText) && 
            `Narrative cannot contain the following: ` + (narBwBbWords);
        let narrativeErrored = (validationState && validationState.narrativeLength) ||
        (validationState && validationState.maxNarrativeLength) ||
        ((conflictingBannedWords.length + conflictingBlockBillingWords.length) > 0);
        let invDur = (validationState ? validationState.duration : false);
        if (validationState && validationState.narrativeLength) {
            narHelperText = `Narrative cannot be less than ${validationState.narrativeMinLength} characters`;
        }
        if (validationState && validationState.matterStatusInvalid) {
            matterStatusErrText = `Invalid ${rootStore.appStore.features.EpochConfigMatterLabel} Status: ${timeEntry.matterStatusDesc}`;
        }
        if (validationState && validationState.matterEntryTypeInvalid) {
            const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
            matterStatusErrText = `Invalid ${matterLabel} Entry Type: Cost only`;
        }
        if (validationState && validationState.maxNarrativeLength) {
            narHelperText = `Narrative cannot exceed ${validationState.narrativeMaxLength} characters`;
        }
        let increment: number = 0.1;
        if (timeEntry!.timeEntryUnit === 'Z2') {
            increment = 0.25;
        } else if (timeEntry!.timeEntryUnit === 'Z3') {
            increment = 0.05;
        }
        const { setFieldLoader } = this.state;
        const officeCode = parseCode(timeEntry.office, timeEntry.officeName);
        return (
            <FeaturesConsumer>
                { (features: Features) =>
                <ApiConsumer>
                    { (api: RootAPI) =>
                <TKConsumer>
                    { (tk: TimeKeeperAssignment) =>
                        <FlexDiv direction="column">
                            <Styled.FieldContainer minHeight={this.props.minHeight}>
                                <Styled.Fields>
                                    {showDate &&
                                    <InlineDatePicker
                                        format={timeEntry ? 
                                            DateTime.fromISO(timeEntry.workDateTime).toFormat(getDateFormat())
                                            : 'MM/dd/yyyy'}
                                        onlyCalendar={true}
                                        value={timeEntry ? isoDate(DateTime.fromISO(timeEntry.workDateTime)) : ''}
                                        disabled={timeEntry && timeEntry.isPosted() || disabled || !tk.writable}
                                        onChange={this.setWorkDate}
                                        leftArrowIcon={<KeyboardArrowLeft/>}
                                        rightArrowIcon={<KeyboardArrowRight/>}
                                        TextFieldComponent={(props: StandardTextFieldProps) =>
                                            <TextField
                                                {...props}
                                                style={{justifyContent: 'flex-end'}}
                                            />
                                        }
                                    />}
                                    <AutoCompleteField
                                        label="Client"
                                        fetch={(search: string) => api.Client.searchClients(search)}
                                        currentItem={timeEntry.client ? timeEntry.client : null}
                                        getItemText={(c: Client) => parseCode(c.number, c.name)}
                                        clearable={true}
                                        onClear={() => this.setClient(null)}
                                        onSelect={this.setClient}
                                        disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                    />
                                    {features.EpochConfigTimeEntriesMattersRequired && <div>
                                        <AutoCompleteField
                                            label={features.EpochConfigMatterLabel}
                                            errorMessage={`Invalid ${features.EpochConfigMatterLabel}`}
                                            errored={validationState ? validationState.missing.matter : false}
                                            fetch={(searchText: string) => 
                                                this.fetchMatters(api)(timeEntry, features, searchText)
                                            }
                                            currentItem={timeEntry.matter}
                                            clearable={true}
                                            onClear={() => this.setMatter(api)(null)}
                                            formatItem={MatterItemFormatter}
                                            getItemText={(m: Matter) => parseCode(m.number, m.name)}
                                            onSelect={this.setMatter(api)}
                                            disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                            tooltip={this.getMatterTooltipText}
                                        />
                                        {setFieldLoader && <LinearProgressBar color={'primary'} progressBar={50}/>}
                                    </div>}
                                            {
                                                tkOfficesToShow.length > 1 ?
                                                    <Select
                                                        title={officeCode}
                                                        displayEmpty={true}
                                                        name={'select-office'}
                                                        value={timeEntry.office}
                                                        disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                                        onChange={this.handleOfficeChange}
                                                        style={{paddingTop: `16px`}}
                                                    >
                                                        {
                                                            tkOfficesToShow.map((tko: TkOfficeOfficeName) => {
                                                                const tkOfficeCode = parseCode(tko.office, tko.officeName);
                                                                return (
                                                                    <MenuItem value={tko.office} key={tko.office} title={tkOfficeCode}>
                                                                        {tkOfficeCode}
                                                                    </MenuItem>
                                                                )
                                                            })

                                                        }
                                                    </Select> : 
                                                    <TextField
                                                        title={officeCode}
                                                        label="Office"
                                                        disabled={true}
                                                        value={officeCode}
                                                    // TODO fetch offices from user, disable iff there is only one
                                                    />
                                            }
                                    {features.EpochConfigWorkLocaleEnabled &&
                                        <AutoCompleteField
                                            label="Work Location"
                                            fetch={(search: string) => api.TimeEntry.searchWorkLocales(search)}
                                            currentItem={this.getWorkLocaleValue(timeEntry)}
                                            getItemText={(w: WorkLocale) => parseCode(w.localeSearch, null)}
                                            clearable={true}
                                            onClear={() => this.setWorkLocale(null)}
                                            onSelect={this.setWorkLocale}
                                            disabled={disabled || timeEntry.isPosted() || !tk.writable || setFieldLoader}
                                            errorMessage="Invalid Work Location"
                                            errored={validationState ? validationState.missing.workLocale : false}
                                        />
                                    }
                                    {/** TODO figure out when to display phase and tasks sets */}
                                    {(timeEntry.matter && !timeEntry.isPosted()) &&
                                    features.EpochConfigCodeSetTemplatesEnabled &&
                                    <AutoCompleteField
                                        label="Code Template"
                                        fetch={(searchText) =>
                                            api.CodeSet.getCodeSets(timeEntry.matterId!, searchText, 0, 200)
                                        }
                                        currentItem={timeEntry.selectedCodeSetTemplate}
                                        clearable={true}
                                        disabled={timeEntry.isPosted() || disabled || !tk.writable}
                                        onClear={() => this.setCodeSetTemplate(api)(null)}
                                        getItemText={(cs: CodeSetTemplate) =>
                                            parseCode(cs.codeSetName, cs.codeSetRef)}
                                        onSelect={this.setCodeSetTemplate(api)}
                                    />
                                    }
                                    {<>
                                        {timeEntry.isPhaseCode && <>
                                            <AutoCompleteField
                                                errorMessage="Invalid Phase"
                                                errored={validationState ? validationState.missing.phase : false}
                                                label="Phase"
                                                currentItem={timeEntry.phase}
                                                clearable={true}
                                                disabled={
                                                    timeEntry.matterId === null ||
                                                    disabled ||
                                                    timeEntry.isPosted() || !tk.writable}
                                                onClear={() => this.setPhase(api)(null)}
                                                onSelect={this.setPhase(api)}
                                                fetch={(searchText ) =>
                                                    this.fetchPhaseCodes(api)(timeEntry, searchText)}
                                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                                            />
                                            <AutoCompleteField
                                                errorMessage="Invalid Task"
                                                errored={validationState ? validationState.missing.task : false}
                                                label="Task"
                                                currentItem={timeEntry.task}
                                                clearable={true}
                                                onSelect={this.setTask}
                                                onClear={() => this.setTask(null)}
                                                fetch={(searchText ) =>
                                                    this.fetchTaskCodes(api)(timeEntry, searchText)}
                                                disabled={timeEntry.phase === null ||
                                                disabled ||
                                                timeEntry.isPosted() || !tk.writable}
                                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                                            />
                                        </>}
                                        {timeEntry.isActCode &&
                                        <AutoCompleteField
                                            errorMessage="Invalid Activity"
                                            errored={validationState ? validationState.missing.activity : false}
                                            label="Activity"
                                            currentItem={timeEntry.activity}
                                            clearable={true}
                                            onClear={() => this.setAct(null)}
                                            onSelect={this.setAct}
                                            fetch={(searchText ) =>
                                                this.fetchActivityCodes(api)(timeEntry, searchText)}
                                            disabled={!timeEntry.matterId || disabled || timeEntry.isPosted() || !tk.writable}
                                            getItemText={(c: Code) => parseCode(c.name, c.description)}
                                        />
                                        }
                                    </>}
                                    { 
                                        features.EpochConfigFlatFeeCodesEnabled &&
                                        timeEntry.isFfTaskCode && 
                                        <>
                                            <AutoCompleteField
                                                errorMessage="Invalid FF Task"
                                                errored={validationState ? validationState.missing.ffTask : false}
                                                label="FF Task"
                                                currentItem={timeEntry.ffTask}
                                                clearable={true}
                                                onClear={() => this.setFFTask(api)(null)}
                                                onSelect={this.setFFTask(api)}
                                                fetch={(searchText ) =>
                                                   this.fetchFFTaskCodes(api)(timeEntry, searchText)}
                                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                                                disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                            />
                                            <AutoCompleteField
                                                errorMessage="Invalid FF Activity"
                                                errored={validationState ? validationState.missing.ffAct : false}
                                                label="FF Activity"
                                                currentItem={timeEntry.ffActivity}
                                                disabled={!timeEntry.ffTaskCodeId ||
                                                disabled ||
                                                    timeEntry.isPosted() || !tk.writable}
                                                clearable={true}
                                                onClear={() => this.setFFAct(null)}
                                                onSelect={this.setFFAct}
                                                fetch={(searchText ) =>
                                                    this.fetchFFActCodes(api)(timeEntry, searchText)}
                                                getItemText={(c: Code) => parseCode(c.name, c.description)}
                                            />
                                        </>}
                                    {
                                        this.props.actionCodesRequired &&
                                        <AutoCompleteField
                                            errorMessage="Invalid Action"
                                            errored={validationState ? validationState.missing.action : false}
                                            label="Action Code"
                                            currentItem={timeEntry.actionCodeObj}
                                            disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                            clearable={true}
                                            onClear={() => this.setActionCode(null)}
                                            onSelect={this.setActionCode}
                                            fetch={(searchText ) =>
                                                api.Code.getActionCodes(
                                                    timeEntry.matterId!,
                                                    searchText,
                                                )}
                                            getItemText={
                                                (code: ActionCode) =>
                                                    `${code.actionCode}${code.actionText ? `
                                                     - ${code.actionText}` : ''}`}
                                        />
                                    }
                                </Styled.Fields>
                            </Styled.FieldContainer>
                            <FlexDiv flex={1} />
                            <Styled.FieldContainer
                                minHeight={this.props.minHeight}
                                style={{display: 'grid', alignItems: 'end'}}
                            >
                                <Styled.BottomFields>
                                {includeDuration && <Styled.DurationFieldContainer>
                                    <DurationField
                                        duration={timeEntry!.duration}
                                        increment={increment}
                                        onChange={this.setDuration}
                                        error={invDur || durValidationState}
                                        errorText={durErrTxt}
                                        disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                    />
                                </Styled.DurationFieldContainer>}
                                {includeNarrative &&
                                    <Styled.NarrativeFieldContainer>
                                        <NarrativeField
                                            helperText={narHelperText.length > 0 ? narHelperText : undefined}
                                            error={narrativeErrored}
                                            value={timeEntry.narrative}
                                            placeholder={timeEntry.billingLangText ?
                                                `Narrative (${timeEntry.billingLangText})` : 'Narrative'}
                                            onChange={this.setNarrative}
                                            disabled={disabled || timeEntry.isPosted() || !tk.writable}
                                            maxLength={features.EpochConfigNarrativesMaximumChars}
                                            dictionaryKey={timeEntry.matterBillingLangDictionary}
                                            changeLanguage={this.changeNarrativeLanguage}
                                        />
                                    </Styled.NarrativeFieldContainer>}
                                </Styled.BottomFields>
                                {timeEntry.actionResponse &&
                                <FormHelperText error={true}>
                                    {timeEntry.actionResponse}
                                </FormHelperText>
                                }
                                {matterStatusErrText &&
                                <FormHelperText error={true}>
                                    {matterStatusErrText}
                                </FormHelperText>
                                }
                            </Styled.FieldContainer>
                            </FlexDiv>}
                </TKConsumer>}
                    </ApiConsumer>
                }
            </FeaturesConsumer>
        );
    }
}