import * as React from 'react';
import * as Styled from './styled.desktop';
import {
    Input,
    IconButton,
    withStyles,
    Tooltip,
    CircularProgress,
    Collapse
} from '@material-ui/core';
import {
    Add, Search, FlipToBack, FlipToFront, 
    KeyboardArrowUp, KeyboardArrowDown, Today, Loop
} from '@material-ui/icons';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { Route } from 'react-router';
import TemplateFormContainer from './TimerContainer';
import TimerStore from 'store/Timer/timer.store';
import styled from 'common/styled-components';
import { Loading } from '../../containers/TimeEntries/styled';
import { parseCode } from 'util/utils';
import { Client, Matter, Features, TimeKeeperAssignment } from '../../api/types/types';
import AutoCompleteField from 'components/AutoCompleteField';
import { ApiConsumer } from 'common/ApiProvider';
import RootAPI from '../../api/interfaces/RootAPI';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import TimerLink from './TimerLink';
import { TKConsumer } from 'common/TKProvider';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';

interface Props {
    rootStore?: RootStore;
    timerStore?: TimerStore;
    match: {
        url: string;
    };
    // tslint:disable-next-line:no-any
    classes: any
}
interface GGProps extends ListRowProps {
    timerStore?: TimerStore;
}
interface State {
    expanded: boolean;
}
export const FabProgress = styled(CircularProgress)`
    position: absolute;
    top: -6;
    left: -6;
    z-index: 1;
`
const styles = () => ({
    button: {
        '&:hover': {
            backgroundColor: 'none'
        },
        backgroundColor: 'white',
        marginRight: 36,
        opacity: 0.9,
        width: 36,
        height: 36,
        padding: 0
    },
    defaultButton: {
        '&:hover': {
            backgroundColor: 'white',
            opacity: 0.9
        },
        backgroundColor: 'none',
        marginRight: 36,
        width: 36,
        height: 36,
        padding: 0
    }
});

class ListRow extends React.Component<GGProps> {
    render() {
        const {
            selectedTimer,
            loadingTimer,
            filteredTimers,
            startTimer,
            stopTimer,
            changeSelectedTimer,
            deleteTimer,
            addTimerNote,
            isActiveToday
        } = this.props.timerStore!;
        const includeSubmitted
            = this.props.timerStore!.rootStore.appStore.features.EpochConfigTimeSegmentsSubmittedVisible;

        const { index, key, style } = this.props;
        const timer = filteredTimers[index];
        return (
            <div
                key={key}
                style={style}
                onClick={() => changeSelectedTimer(timer.id)}
            >
                <TimerLink
                    key={timer.id!.toString()}
                    timer={timer}
                    selected={selectedTimer && selectedTimer.id === timer.id}
                    onDelete={deleteTimer(timer)}
                    onStart={startTimer(timer)}
                    onStop={stopTimer(timer.id!)}
                    loadingId={loadingTimer}
                    includeSubmitted={includeSubmitted}
                    disableDelete={timer.active}
                    onAddNote={addTimerNote(timer!)}
                    isActiveToday={isActiveToday(timer)}
                    style={style}
                />
            </div>
        );
    }
}

@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        rootStore: rootStore,
        timerStore: rootStore.timerStore
    };
})
@observer
class Timers extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            expanded: false
        }
    }
    componentDidMount() {
        this.props.timerStore!.setClientFilterValue();
        this.props.timerStore!.setMatterFilterValue();
        this.props.timerStore!.resetTodayTimerFlag();
        this.props.timerStore!.loadTimers();
    }
    componentWillUnmount() {
        this.props.timerStore!.onSearchChange('');
        this.props.timerStore!.setClientFilterValue();
        this.props.timerStore!.setMatterFilterValue();
    }
    handleExpansion = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }
    render() {
        const {
            selectedTimer,
            onSearchChange,
            loadingTimer,
            // deleteTemplate,
            // newTemplate,
            newTimer,
            filteredTimers,
            searchText,
            openTimerPopOut,
            popOutOpen,
            closePopOut,
            mainWinLoading,
            sortByAlphaNumericToggle,
            toggleAlphaNumericHandler,
            clientFilter,
            setClientFilter,
            matterFilter,
            setMatterFilter,
            updateFilterTodayTimers,
            filterTodayTimersFlag,
            resetSortAndFilterTimers,
            applyingFilter,
            timerLoading,
            playAndStopLoading,
            activeTimerUpdateSteps
        } = this.props.timerStore!;
        const { match } = this.props;
        const { classes} = this.props;
        const { expanded } = this.state;
        const disableTimers = timerLoading || !!loadingTimer || !!playAndStopLoading || !!activeTimerUpdateSteps;
        return (
            <Styled.Container disabled={disableTimers}>
                <div>
                    <Styled.Header>
                        <Styled.SearchContainer>
                            <Search
                                style={{ marginRight: '6px' }}
                            />
                            <Input
                                placeholder="Search"
                                fullWidth={true}
                                style={{ maxWidth: '60%' }}
                                value={searchText}
                                onChange={e => onSearchChange(e.target.value)}
                            />
                        </Styled.SearchContainer>
                        <Styled.Title variant="h6">
                            Timers
                        </Styled.Title>
                        <Styled.NewActions>
                        <TKConsumer>
                                { (tk: TimeKeeperAssignment) =>
                        <>
                        {
                            tk.writable &&
                            <>
                            {popOutOpen ?
                            <Tooltip title="Pop In">
                                <IconButton 
                                    onClick={closePopOut}
                                    className={classes.button}
                                >
                                    <FlipToFront/>
                                </IconButton>
                            </Tooltip> :
                            <Tooltip title="Pop Out">
                                <IconButton 
                                    onClick={openTimerPopOut}
                                    className={classes.defaultButton}
                                >
                                    <FlipToBack/>
                                </IconButton>
                            </Tooltip>
                        }
                            <Tooltip title="New Timer">
                                <IconButton 
                                    onClick={newTimer}
                                    className={classes.defaultButton}
                                >
                                    <Add/>  
                                </IconButton>
                            </Tooltip>
                            </>
                        }
                        {/* {popOutOpen ?
                            <Tooltip title="Pop In">
                                <IconButton 
                                    onClick={closePopOut}
                                    className={classes.button}
                                >
                                    <FlipToFront/>
                                </IconButton>
                            </Tooltip> :
                            <Tooltip title="Pop Out">
                                <IconButton 
                                    onClick={openTimerPopOut}
                                    className={classes.defaultButton}
                                >
                                    <FlipToBack/>
                                </IconButton>
                            </Tooltip>
                        }
                            <Tooltip title="New Timer">
                                <IconButton 
                                    onClick={newTimer}
                                    className={classes.defaultButton}
                                >
                                    <Add/>  
                                </IconButton>
                            </Tooltip> */}
                            </>}
                            </TKConsumer>
                            {
                                expanded ?
                                    <Tooltip title={'Collapse'}>
                                        <IconButton
                                            onClick={this.handleExpansion}
                                            className={classes.button}
                                        >
                                            <KeyboardArrowUp />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title={'Expand'}>
                                        <IconButton
                                            onClick={this.handleExpansion}
                                            className={classes.defaultButton}
                                        >
                                            <KeyboardArrowDown />
                                        </IconButton>
                                    </Tooltip>
                            }
                        </Styled.NewActions>
                    </Styled.Header>
                    <Collapse in={expanded}>
                    <FeaturesConsumer>
                        {(features: Features) =>
                            <ApiConsumer>
                                {(api: RootAPI) =>
                                <Styled.AdvancedHeader>
                                    <Styled.AutoCompleteFilters>
                                        <AutoCompleteField
                                            label="Client"
                                            fetch={(search: string) => api.Timer.getDistinctClientsFromTimers(search)}
                                            currentItem={clientFilter ? clientFilter : null}
                                            getItemText={(c: Client) => parseCode(c.number, c.name)}
                                            clearable={true}
                                            onClear={() => setClientFilter(null, (clientFilter === null) ? true : false)}
                                            onSelect={setClientFilter}
                                        />
                                    </Styled.AutoCompleteFilters>
                                    <Styled.AutoCompleteFilters>
                                        <AutoCompleteField
                                            label={features.EpochConfigMatterLabel}
                                            fetch={(search: string) =>
                                                api.Timer.getDistinctMattersFromTimers(
                                                    search,
                                                    clientFilter ? clientFilter.id : 0
                                                )
                                            }
                                            currentItem={matterFilter ? matterFilter : null}
                                            getItemText={(m: Matter) => parseCode(m.number, m.name)}
                                            clearable={true}
                                            onClear={() => setMatterFilter(null, (matterFilter === null) ? true : false)}
                                            onSelect={setMatterFilter}
                                        />
                                    </Styled.AutoCompleteFilters>
                                    <Tooltip title={sortByAlphaNumericToggle ? 'Sort by descending' : 'Sort by ascending'}>
                                        <Styled.FilterButton
                                            className={sortByAlphaNumericToggle ? classes.button : classes.defaultButton}
                                            onClick={toggleAlphaNumericHandler}
                                        >
                                            <SortByAlphaIcon />
                                        </Styled.FilterButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={filterTodayTimersFlag ? 'Show all timers' : 'View timers for today'}
                                    >

                                    <Styled.FilterButton
                                        className={filterTodayTimersFlag ? classes.button : classes.defaultButton}
                                        onClick={updateFilterTodayTimers}
                                    >
                                        <Today />
                                    </Styled.FilterButton>
                                    </Tooltip>
                                    <Tooltip title={'Reset'}>
                                        <Styled.FilterButton
                                            className={classes.defaultButton}
                                            onClick={resetSortAndFilterTimers}
                                        >
                                            <Loop />
                                        </Styled.FilterButton>
                                    </Tooltip>
                                </Styled.AdvancedHeader>}
                            </ApiConsumer>
                        }
                        </FeaturesConsumer>
                    </Collapse>
                </div>

                <Styled.ContentContainer expanded={expanded}>
                    <Styled.ListContainer square={true}>
                        <AutoSizer>
                            {({ width, height}) => {
                                return (<List
                                    height={height}
                                    width={width}
                                    rowHeight={80}
                                    rowCount={filteredTimers.length}
                                    rowRenderer={props => <ListRow {...props} timerStore={this.props.timerStore!}/>}
                                />);
                            }}
                        </AutoSizer>
                    </Styled.ListContainer>
                    <Styled.FormContainer>
                            <Route 
                                path={`${match.url}/:id`} 
                                component={selectedTimer && TemplateFormContainer}
                            />
                            {(mainWinLoading || applyingFilter) &&
                            <Loading>
                                <div style={{top: '-15%', position: 'relative'}}>
                                    <CircularProgress size={100}/>
                                </div>
                            </Loading>}
                    </Styled.FormContainer>
                </Styled.ContentContainer>
                
            </Styled.Container>
        );
    }
}
// tslint:disable-next-line:no-any
export default withStyles(styles as any)(Timers)