import React, { Component, CSSProperties } from 'react';
import { AutoSizer, List, InfiniteLoader } from 'react-virtualized';
import { Button, Checkbox, IconButton, ListItem, Paper, Typography } from '@material-ui/core';
import { Client, Matter, MatterListType } from '../../api/types/types';
import { RemoveFromQueue } from '@material-ui/icons';
import * as Styled from './styled';
import { observer } from 'mobx-react';
import { FlexDiv } from 'common/flex';
import { TKConsumer } from 'common/TKProvider';
import { TimeKeeperAssignment } from 'api/types/types';
interface Props {
    matters: MatterListType[]; // List to fetch
    fetchMore: () => void;
    isTrackedList: boolean;
    onSelect?: (ids: number[]) => void;
    selected: number[];
    untrack: (ids: number[]) => void;
}

interface PanelProps {
    matter: MatterListType;
    isTrackedList: boolean;
    onSelect?: (selected: boolean) => void;
    selected?: boolean;
    untrack: (ids: number[]) => void;
    style: CSSProperties;
}

class MatterPanel extends Component<PanelProps> {
    select = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.props.onSelect!(checked);
    }

    render() {
        const {
            matter,
            isTrackedList,
            selected,
            untrack,
            style
        } = this.props;
        return (
            <TKConsumer>
            { (tk: TimeKeeperAssignment) => 
            <div style={style}>
                <Styled.MatterListItem key={matter.id as number}>
                    {(!isTrackedList && !matter.isHeader) &&
                    <Checkbox
                        checked={selected}
                        onChange={this.select}
                        disabled={!tk.writable}
                    />}
                    <Styled.MatterLabel 
                        isHeader={matter.isHeader}
                        title={matter.description}
                    >
                        {matter.number + ' - ' + matter.name}
                    </Styled.MatterLabel>
                    {(isTrackedList && !matter.isHeader && tk.writable) &&
                    <IconButton title={'Untrack'} onClick={() => untrack([matter.id])}>
                        <RemoveFromQueue/>
                    </IconButton>}
                </Styled.MatterListItem>
            </div>
            }
            </TKConsumer>
        );
    }
}

@observer
export default class MatterList extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    fetchMoreitems = (cHeight: number, scrollHeight: number, scrollTop: number) => {
        let bottom = scrollHeight - (scrollTop + cHeight);
        if (bottom <= 10 && bottom !== 0) {
            this.props.fetchMore();
        }
    }
    selectMatters = (mat: MatterListType) => () => {
        let selectedArr = this.props.selected.slice();
        if (selectedArr.includes(mat.id)) {
            selectedArr = selectedArr.filter((s) => s !== mat.id)
        } else {
            selectedArr.push(mat.id);
        }
        this.props.onSelect!(selectedArr);
    }

    render() {
        const { matters, isTrackedList, selected, untrack } = this.props!;
        return (
            <FlexDiv direction="column" flex={1}>
                <AutoSizer>
                    {({ width, height }) => {
                        return (
                            <div>
                                <List
                                    height={height}
                                    rowHeight={48}
                                    rowCount={matters.length}
                                    width={width}
                                    onScroll={({ clientHeight, scrollHeight, scrollTop }: 
                                                   {clientHeight: number; scrollHeight: number; scrollTop: number}) =>
                                        this.fetchMoreitems(clientHeight, scrollHeight, scrollTop)}
                                    rowRenderer={({style, key, index}) => {
                                        return (
                                            <MatterPanel
                                                matter={matters[index]}
                                                isTrackedList={isTrackedList}
                                                onSelect={this.selectMatters(matters[index])}
                                                selected={selected.includes(matters[index].id as number)}
                                                untrack={untrack}
                                                style={style} 
                                                key={key}
                                            />
                                           
                                        );
                                    }}
                                />
                            </div>
                        );
                    }}
                </AutoSizer>
            </FlexDiv>
        );
    }
}
